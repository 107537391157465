import './Specification.css';

import { StarOutlined, EditFilled, DeleteFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Navigate, useNavigate,useParams } from 'react-router-dom';

import { Form, Input, InputNumber, Popconfirm, Table, Typography } from 'antd';



const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: false,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};




function Specification(){

    let navigate = useNavigate()
const [headingData,setheadingData] = useState([])
const [NewHeading,setNewHeading] = useState("")
const [CurrentHeading,setCurrentHeading] = useState("")
const [AttributeKey,setAttributeKey] = useState("")
const [AttributeValue,setAttributeValue] = useState("")

const params = useParams();
const {PID} =params;


const [selectedRowKeys, setSelectedRowKeys] = useState([]);
const [loading, setLoading] = useState(false);

const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
};

const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
};
const hasSelected = selectedRowKeys.length > 0;

useEffect(()=>{
    var Url = window.API_PREFIX + 'products/get_attributeHeading'
    fetch(Url, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        // body: JSON.stringify(),
    }).then(resp => resp.json()).then(data => {
        console.log(data)
        if (data['status'] === "1") 
        {
            setheadingData([...data["AllHeading"]])
        }})

        var Url = window.API_PREFIX + 'products/get_Allattribute'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    "Token":localStorage.getItem("Token"),"Product_Id":PID
                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") 
            {
                setData([...data["AllSpecData"]])
            }})


},[])





const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      key:'',
      Heading: '',
      Spec_Key: '',
      Spec_Value: '',

      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      var Url = window.API_PREFIX + 'products/edit_attribute'
      fetch(Url, {
          method: 'POST',
          headers: {
              'Content-type': 'application/json',
          },
          body: JSON.stringify(
              {
                  "Token":localStorage.getItem("Token"),
                  "AttributeId":newData[index].key,
                  "Product_Id":PID,
                  "Heading":row.Heading,
                  "AttributeKey":row.Spec_Key,
                  "AttributeValue":row.Spec_Value,
              }
          ),
      }).then(resp => resp.json()).then(Revdata => {
          console.log(Revdata)
          if (Revdata['status'] === "1") 
          {
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    }})
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'Heading',
      dataIndex: 'Heading',
      width: '25%',
      editable: true,
    },
    {
      title: 'Spec_Key',
      dataIndex: 'Spec_Key',
      width: '15%',
      editable: true,
    },
    {
      title: 'Spec_Value',
      dataIndex: "Spec_Value",
      width: '40%',
      editable: true,
    },
    {
      title: 'AsFilter',
      dataIndex: "AsFilter",
      width: '40%',
      editable: false,
      render: (_, record,index) => {

        return (<input class="form-check-input" type="checkbox" name="inlineCheckOptions" value='something' checked={record['AsFilter']}
        onClick={(e)=>{
          var Url = window.API_PREFIX + 'products/edit_attributeAsFilter'
          console.log(record.key)
          fetch(Url, {
              method: 'POST',
              headers: {
                  'Content-type': 'application/json',
              },
              body: JSON.stringify(
                  {
                      "Token":localStorage.getItem("Token"),
                      "AttributeId":record.key,
                      "AsFilter":!record['AsFilter']
                  }
              ),
          }).then(resp => resp.json()).then(Revdata => {
              console.log(Revdata)
              if (Revdata['status'] === "1") 
              {  
                var tmpAttri = data
                tmpAttri[index]['AsFilter'] = !tmpAttri[index]['AsFilter']
                setData([...tmpAttri])

              }})

        }}
        ></input>)
      }
    },
    {
      title: 'Edit',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => {

                            save(record.key)
       
                




                }}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            <EditFilled style={{color: "#00ab41",fontSize: "18px" ,  display:"flex", justifyContent:"center"}}/>
            {/* Edit */}
          </Typography.Link>
        );
      },
    },
    {
        title: 'Delete',
        dataIndex: '',
        render: (_, record,index) => <DeleteFilled style={{color: "#e11f48",fontSize: "18px" , display:"flex", justifyContent:"center"}}
        onClick={()=>{
            var Url = window.API_PREFIX + 'products/del_attribute'
            fetch(Url, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(
                    {
                        "Token":localStorage.getItem("Token"),
                        "AttributeId":record.key,
                    }
                ),
            }).then(resp => resp.json()).then(Revdata => {
                console.log(Revdata)
                if (Revdata['status'] === "1") 
                {
                    var tmp = data
                    tmp.splice(index,1)
                    setData([...tmp])
                }})
        }}
        />
     }   
    
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex ,
        //  === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });










    return(
        <>
        <div>
            <div className='specification_main_division'>
                <div className='specification_title'>Specification</div>

                <div className='specification_sub_main_division'>

                    <div className='specification_first_main_division'>

                        <div className='specification_label_text'>
                            <div className='specification_Key_Specification_title'>Key Specification</div>
                            <input type="text" class="specificationformControl"  placeholder="Add New Heading" value={NewHeading}
                            onChange={(e)=>{
                                setNewHeading(e.target.value)
                            }}
                            ></input>
                            <button type="button" class="specification-add-button"
                            onClick={()=>{
                                var tmp = headingData
                                tmp.push(NewHeading)
                                setheadingData([...headingData])
                                setNewHeading("")

                            }}
                            >Add</button> 
                        </div>

                        <div>
                            <div className='specification_sub_title'>Detailed Specification</div>

                            <div>
                                <div className='specification_text_title'>Select Heading</div>
                                <select className="specificationdropdownformControl" aria-label=".form-select-sm example"
                                onChange={(e)=>{
                                    console.log(e.target.value)
                                    if(e.target.value==="No Heading")
          
                                   {
                                    setCurrentHeading("")}
                                   else
                                   { setCurrentHeading(e.target.value)}
                                }}
                                >
                                    <option selected value='No Heading'
                              
                                    >No Heading</option>
                                    {headingData.map((eachheading)=>{
                                        if(eachheading !==""){
                                    return(
                                    <option
                          
                                    >{eachheading}</option>

                                        )}
                                    })}
                                </select>
                            </div>
                            
                            <div>
                                <div className='specification_text_title'>Key</div>
                                <input type="text" class="specificationformControl"  placeholder=""
                                onChange={(e)=>{
                                    setAttributeKey(e.target.value)
                                }}
                                ></input>
                            </div>

                            <div>
                                <div className='specification_text_title'>Value</div>
                                <input type="text" class="specificationformControl"  placeholder=""
                                onChange={(e)=>{
                                    setAttributeValue(e.target.value)
                                }}
                                ></input>
                            </div>

                            <div className='specification_button'>
                                <button type="button" class="specification-detailed-add-button"
                     onClick={()=>{

                        var Url = window.API_PREFIX + 'products/add_attribute'
                        fetch(Url, {
                            method: 'POST',
                            headers: {
                                'Content-type': 'application/json',
                            },
                            body: JSON.stringify(
                                {
                                    "Token":localStorage.getItem("Token"),
                                    "Product_Id":PID,
                                    "Heading":CurrentHeading,
                                    "AttributeKey":AttributeKey,
                                    "AttributeValue":AttributeValue,
                                }
                            ),
                        }).then(resp => resp.json()).then(Revdata => {
                            console.log(Revdata)
                            if (Revdata['status'] === "1") 
                            {
                                console.log(Revdata["AttributeId"])
                                var tmpData =data
                                tmpData.push({
                                    key:Revdata["AttributeId"],
                                    Heading:CurrentHeading,
                                    Spec_Key:AttributeKey,
                                    Spec_Value:AttributeValue,
                                    AsFilter:true
                                })
                                setData([...tmpData])

                       
                            }})
                                  

                                    
                                }}
                                
                                >Add</button> 
                                <button type="button" class="specification-submit-button"
                                onClick={()=>{
                                navigate("/administration/allproduct")
                                }}
                                >View All Product</button> 
                            </div>
                        </div>

                        <div> 
                            {/* <div className='specification_sub_title'>Key Specification Table</div> 

                            <div className='specification_table_division'>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" className='specification_table_heading'>Name</th>
                                            <th scope="col" className='specification_table_heading'>Edit</th>
                                            <th scope="col" className='specification_table_heading'>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr >
                                            <td className='specification_table_value'>Apple M1 chip with 8-core CPU</td>
                                            <td className='specification_table_icon_edit'><i class="fa-solid fa-pen"></i></td>
                                            <td className='specification_table_icon'><i class="fa-solid fa-trash"></i></td>
                                        </tr>
                                        
                                        <tr>
                                            <td className='specification_table_value'>Apple M1 chip with 8-core CPU</td>
                                            <td className='specification_table_icon_edit'><i class="fa-solid fa-pen"></i></td>
                                            <td className='specification_table_icon'><i class="fa-solid fa-trash"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> */}
                            
                            {/* <div>
                                <button type="button" class="specification-previous-button">Previous</button> 
                                <button type="button" class="specification-next-button">Next</button> 
                            </div> */}
                        </div> 
                    </div>

                    <div className='specification_table_main_division'>
                        <div
                            style={{
                                marginBottom: 0,
                            }}>
                            <span className='all_products_data_table'
                                style={{
                                    marginLeft: 8,
                                }}
                            >
                                {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                            </span>
                        </div>
                        <div className='w-100 allproduct_table_number'>
                            {/* <Table className='all_products_tables' rowSelection={rowSelection} columns={columns} dataSource={data} /> */}



                            <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        className='all_products_tables'
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>












                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Specification;