import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useStateValue } from "../../StateProviders";
import './Sidebar.css'

function Sidebarthree() {


    const [hover, setHover] = useState(false);
    const [click, setclick] = useState(false);

    const handleMouseEnter = () => {
        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
        setopen(false)
        setopentwo(false)
        setopenthree(false)
    };

    const barside = {
        width: hover || click ? 240 : 75,
    }


    const location = useLocation();
    const pathname = location.pathname;

    let navigate = useNavigate()



    const [open, setopen] = useState(false);
    const [opentwo, setopentwo] = useState(false);
    const [openthree, setopenthree] = useState(false);

    const [{ ShowAdminLoginPop }, dispatch] = useStateValue();



    return (
        <div>


            <div
                style={barside}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className='sidebar_three' >
                <div>
                    <li className={pathname === "/administration/dashboard" ? "activee" : "non_activee"}>
                        <Link to="/administration/dashboard" className='link'>
                            <i className="fa-solid fa-globe fa-xl menu_icon"></i>
                            <span className='span_li'>Dashboard</span>
                        </Link>
                    </li>

                    <li className={pathname === "/administration/allorder" ? "activee" : "non_activee"}>
                        <Link to="/administration/allorder" className='link'>
                            <i className="fa-solid fa-truck fa-xl menu_icon"></i>
                            <span className='span_li'> Order</span>
                        </Link>
                    </li>


                    <li className='link' onClick={() => {
                        setopen(!open)
                        setopentwo(false)
                        setopenthree(false)
                    }}>
                        <i className="fa-solid fa-box fa-xl menu_icon"></i>
                        <span className='span_li'>Product</span>
                        <i className={`fa-solid ${open ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: 'white', marginLeft: '75px' }}></i>
                    </li>

                    {open ?
                        <div className='feature_div'>
                            <div className={pathname === "/administration/addproduct" ? 'ul_element_activee' : 'ul_element'}>
                                <Link className='linktitle' to="/administration/addproduct"
                                >- Add Product</Link>
                            </div>
                            <div className={pathname === "/administration/allproduct" ? 'ul_element_activee' : 'ul_element'}>
                                <Link className='linktitle' to="/administration/allproduct">- All Product</Link></div>
                        </div>

                        : null
                    }



                    <li className={pathname === "/administration/alluser" ? "activee" : "non_activee"}>
                        <Link to="/administration/alluser" className='link'>
                            <i className="fa-solid fa-user fa-xl menu_icon"></i>
                            <span className='span_li'>All user</span>
                        </Link>
                    </li>



                    <li className='link' onClick={() => {
                        setopentwo(!opentwo)
                        setopen(false)
                        setopenthree(false)
                    }}>
                        <i className="fa-solid fa-house fa-xl menu_icon"></i>
                        <span className='span_li'>Homepage</span>
                        <i className={`fa-solid ${opentwo ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: 'white', marginLeft: '45px' }}></i>
                    </li>

                    {opentwo ?
                        <div className='feature_div'>
                            <div className={pathname === "/administration/slider" ? 'ul_element_activee' : 'ul_element'}>
                                <Link className='linktitle' to="/administration/slider1"
                                >- Slider1</Link>
                            </div>
                            <div className={pathname === "/administration/slider" ? 'ul_element_activee' : 'ul_element'}>
                                <Link className='linktitle' to="/administration/slider2"
                                >- Slider2</Link>
                            </div>
                            <div className={pathname === "/administration/categoryimage" ? 'ul_element_activee' : 'ul_element'}>
                                <Link className='linktitle' to="/administration/categoryimage">- Category Mobile</Link></div>
                        </div>

                        : null
                    }


                    <li className='link' onClick={() => {
                        setopenthree(!openthree)
                        setopentwo(false)
                        setopen(false)
                    }}>
                        <i className="fa-solid fa-percent fa-xl menu_icon"></i>
                        <span className='span_li'>Promocode</span>
                        <i className={`fa-solid ${openthree ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: 'white', marginLeft: '51px' }}></i>
                    </li>

                    {openthree ?
                        <div className='feature_div'>
                            <div className={pathname === "/administration/promocode" ? 'ul_element_activee' : 'ul_element'}>
                                <Link className='linktitle' to="/administration/promocode"
                                >- Promocode</Link>
                            </div>
                            <div className={pathname === "/administration/addpromocode" ? 'ul_element_activee' : 'ul_element'}>
                                <Link className='linktitle' to="/administration/addpromocode">- Add Promocode</Link></div>
                        </div>

                        : null
                    }




                    <li className={pathname === "/administration/addnotifications" ? "activee" : "non_activee"}>
                        <Link to="/administration/addnotifications" className='link'>
                            <i className="fa-solid fa-bell fa-xl menu_icon"></i>
                            <span className='span_li'>Mobile app notification</span>
                        </Link>
                    </li>

                    <li className={pathname === "/administration/contact-details" ? "activee" : "non_activee"}>
                        <Link to="/administration/contact-details" className='link'>
                            <i class="fa-solid fa-message fa-xl menu_icon"></i>
                            <span className='span_li'>Messages</span>
                        </Link>
                    </li>

                    <li className={pathname === "/administration/delete-request" ? "activee" : "non_activee"}>
                        <Link to="/administration/delete-request" className='link'>
                            <i class="fa-solid fa-trash fa-xl menu_icon"></i>
                            <span className='span_li'>Delete Request</span>
                        </Link>
                    </li>

                    <li className={pathname === "/administration/refund-request" ? "activee" : "non_activee"}>
                        <Link to="/administration/refund-request" className='link'>
                            <i class="fa-solid fa-arrows-rotate fa-xl menu_icon"></i>
                            <span className='span_li'>Refund Request</span>
                        </Link>
                    </li>

                    <li className={pathname === "/administration/newsletter" ? "activee" : "non_activee"}>
                        <Link to="/administration/newsletter" className='link'>
                            <i class="fa-solid fa-arrows-rotate fa-xl menu_icon"></i>
                            <span className='span_li'>Newsletter</span>
                        </Link>
                    </li>
                    {/* <li className={pathname === "/administration/addblogs" ? "activee" : "non_activee"}>
                        <Link to="/administration/addblogs" className='link'>
                            <i class="fa-solid fa-blog fa-xl menu_icon"></i>
                            <span className='span_li'>Blogs</span>
                        </Link>
                    </li> */}

                    {/* <li className={pathname === "/administration/forget-password" ? "activee" : "non_activee"}>
                        <Link to="/administration/forget-password" className='link'>
                            <i class="fa-solid fa-lock fa-xl menu_icon"></i>
                            <span className='span_li'>Forget Password</span>
                        </Link>
                    </li> */}



                    <li className="non_activee"
                        onClick={(e) => {
                            localStorage.clear()
                            navigate("/administration");
                            e.preventDefault();
                            dispatch({ type: "OpenALogin" })
                        }}
                    >
                        <Link className='link'>
                            <i className="fa-solid fa-arrow-right-from-bracket  fa-xl menu_icon"></i>
                            <span className='span_li'>Logout</span>
                        </Link>
                    </li>








                </div>

            </div>


            {/* <button style={{ position: 'fixed', right: '0' }} onClick={() => {
                setclick(!click)
                setopen(false)
                setopentwo(false)
                setopenthree(false)
            }}>open</button> */}
        </div >
    )
}

export default Sidebarthree;