import './OrderDetailone.css';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useStateValue } from '../../../StateProviders';
import toast, { Toaster } from 'react-hot-toast';
import ClipLoader from 'react-spinners/ClipLoader';
import Loader from '../../Loader';

function OrderDetailone() {


    const [loading, setLoading] = useState(false);



    const [{ ShowLoginPop }, dispatch] = useStateValue()
    let navigate = useNavigate()
    const params = useParams()
    const { OID } = params
    const [OrderNotes, setOrderNotes] = useState([]);
    const [NoteDesc, setNoteDesc] = useState("");
    const [DeliveryAmount, setDeliveryAmount] = useState(0)
    // const [NoteType,setNoteType] = useState("");
    const [sendOrderMail, setSendOrderMail] = useState([
        {
            dropdownname: "Dispatched"
        },
        {
            dropdownname: "Shipped"
        },
        {
            dropdownname: "Out for Delivery"
        },
        {
            dropdownname: "Delivered"
        },
        {
            dropdownname: "Return"
        },
        {
            dropdownname: "Cancel"
        },

    ])




    const [orderAction, setOrderAciton] = useState([

        {
            dropdownname: "Choose an action"
        },
        {
            dropdownname: "Email invoice / order detail to customer"
        },
        {
            dropdownname: "Resend new order notification"
        },
        {
            dropdownname: "Gegenerate download permissions"
        },

    ])
    const [privateNote, setPrivateNote] = useState([

        {
            dropdownname: "Private Note"
        },
        {
            dropdownname: "Note to customer"
        },

    ])



    const [selectedOptionSendOrderMail, setSelectedOptionSendOrderMail] = useState(sendOrderMail[0].dropdownname);
    const [selectedOrderAction, setSelectedOrderAction] = useState(orderAction[0].dropdownname);
    const [selectedOrderNotes, setSelectedOrderNotes] = useState(privateNote[0].dropdownname);
    const [OrderData, setOrderData] = useState({})


    function GetOrderData() {


        var Url = window.API_PREFIX + 'admin/eachorder'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                { "Token": localStorage.getItem("Token"), "OrderGroupId": OID }),

        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                setOrderData({ ...data['Orderdetails'] })
                setOrderNotes([...data['Notes']])
                setDeliveryAmount(data['Orderdetails']['DeliveryAmount'])

                if (!data['Orderdetails']['IsDelivery']) {
                    setSendOrderMail([
                        {
                            dropdownname: "Ready For Pick Up"
                        },
                        {
                            dropdownname: "Delivered"
                        },
                        {
                            dropdownname: "Return"
                        },
                        {
                            dropdownname: "Cancel"
                        },


                    ])

                    setSelectedOptionSendOrderMail("Ready For Pick Up")

                }
            }
            if (data['status'] === "0") {
                dispatch({ type: "OpenALogin" })
            }
        })


    }
    useEffect(() => {


        GetOrderData()


    }, [OID])

    const [GroupName, setGroupName] = useState('')
    // const [unreadcount,setunreadcount] = useState("")


    const [chatSocket, setChatSocket] = useState(null);
    const [webSocketInitialized, setWebSocketInitialized] = useState(false);

    useEffect(() => {

        if (!webSocketInitialized) {
            setGroupName("Order-" + OID)

            const socket = new WebSocket(
                window.WEBSOC + 'ws/order/Order-' + OID + '/'
            );
            socket.onopen = () => {
                console.log("WebSocket connection opened");
                setWebSocketInitialized(true);

            };
            socket.onmessage = function (e) {
                console.log("msg", e)
                GetOrderData()
            };

            socket.onclose = function (e) {
                console.error('Chat socket closed unexpectedly');
                setWebSocketInitialized(false);

            };
            setChatSocket(socket);

        }
    }, [GroupName])

    const notify = (msg) => toast.success(msg);
    const alert = (alertmsg) => toast.error(alertmsg);
    const InvoiceAvailable = () => toast.success('Invoice generated');
    const InvoiceFailed = () => toast.error('DInvoice failed to generate contact Admin');

    function formatIndianNumber(num) {
        const numStr = num.toString();
        const parts = [];
        let counter = 0;

        // Handle the last 3 digits
        if (numStr.length > 3) {
            parts.unshift(numStr.slice(-3));
            counter = 1;
        } else {
            parts.unshift(numStr);
        }

        // Handle the rest of the digits
        for (let i = numStr.length - 3; i > 0; i -= 2) {
            parts.unshift(numStr.slice(Math.max(0, i - 2), i));
            counter++;
        }

        return parts.join(',');
    }
    return (
        <>

            <div className='orderdetail_main_division'>

                <div onClick={(() => {
                    navigate('/administration/allorder')
                })}><i class="fa-solid fa-arrow-left fa-xl"></i></div>


                <div className='orderdetail_title_button_field'>
                    <div className='orderdetail_edit_order_title'>Edit order #{OID}</div>
                    <div className='orderdetail_button_field'>
                        {/* <button type="button" class="orderdetail-btn-add-order">Add Order</button> */}
                    </div>
                </div>

                <div className='orderdetail_sub_main_division'>
                    <div className='orderdetail_first_main_division'>
                        <div className='orderdetail_first_sub_main_division'>
                            <div className='orderdetail_sub_title'>
                                <div className='orderdetail_item_title'>Item</div>
                                <div className='orderdetail_cost_title'>Cost</div>
                                <div className='orderdetail_qty_title'>Qty</div>

                                {OrderData['Variant']?.[0]?.Tax?.map((Taxheading) => {

                                    return (
                                        <div className='orderdetail_qty_title'>{Taxheading["Type"]}%</div>
                                    )
                                })}

                                <div className='orderdetail_qty_title'>Total Tax%</div>

                                {/* <div className='orderdetail_qty_title'>CGST%</div>
                                <div className='orderdetail_qty_title'>SGST%</div>
                                <div className='orderdetail_qty_title'>CGST% + SGST%</div> */}
                                {/* <div className='orderdetail_qty_title'>IGST%</div> */}
                                <div className='orderdetail_total_title'>Total</div>
                            </div>



                            {OrderData['Variant']?.map((eachOrder) => {

                                return (
                                    <div className='orderdetail_product_order_detail'>
                                        <div className='orderdetail_img_product_detail_division'>
                                            <div>
                                                {/* <img className='orderdetail_order_img' src={orderdetailitemimg}></img> */}
                                            </div>

                                            <div className='orderdetail_order_title_detail'>
                                                <div>
                                                    <div className='orderdetail_iphone_title'><u>{eachOrder['Product_Name']}</u></div>
                                                    <div className='orderdetail_sku_division'>
                                                        {/* <div className='orderdetail_sku_title'>Product:</div>
                                                <div className='orderdetail_sku_detail'>{eachOrder['Product_Name']}</div> */}
                                                    </div>
                                                    {/* <div className='orderdetail_variation_division'>
                                                    <div className='orderdetail_variation_title'>Variation ID:</div>
                                                    <div className='orderdetail_sku_detail'>629</div>
                                                </div> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='orderdetail_cost_value'>₹{eachOrder['Main_Amount']}</div>
                                        <div className='orderdetail_qty_value'>× {eachOrder['Quantity']}</div>

                                        {eachOrder.Tax?.map((eachTax) => {

                                            return (<div className='orderdetail_qty_value'>{eachTax["Gst_Amount"]}({eachTax["Percent"]}%)</div>)

                                        })}
                                        <div className='orderdetail_qty_value'>{eachOrder["Gst_Amount"]}({eachOrder["Percent"]}%)</div>

                                        <div className='orderdetail_total_value'>₹{formatIndianNumber(eachOrder['Amount'])}</div>
                                    </div>

                                )
                            })

                            }

                            {OrderData["IsDelivery"] ? <div className='orderdetail_product_order_detail'>
                                <div className='orderdetail_img_product_detail_division'>
                                    <div>
                                        {/* <img className='orderdetail_order_img' src={orderdetailitemimg}></img> */}
                                    </div>

                                    <div className='orderdetail_order_title_detail'>
                                        <div>
                                            <div className='orderdetail_iphone_title'><u>Delivery</u></div>
                                            <div className='orderdetail_sku_division'>
                                                {/* <div className='orderdetail_sku_title'>Product:</div>
                                                <div className='orderdetail_sku_detail'>{eachOrder['Product_Name']}</div> */}
                                            </div>
                                            {/* <div className='orderdetail_variation_division'>
                                                    <div className='orderdetail_variation_title'>Variation ID:</div>
                                                    <div className='orderdetail_sku_detail'>629</div>
                                                </div> */}
                                        </div>
                                    </div>
                                </div>

                                {/* <div className='orderdetail_cost_value'>₹{eachOrder['Price']}</div> */}
                                {/* <div className='orderdetail_qty_value'>× {eachOrder['Quantity']}</div> */}
                                <div className='orderdetail_total_value'>₹{DeliveryAmount}</div>
                            </div> : null}

                            {OrderData["CouponApplied"] && (
                                <>
                                    <div className='orderdetail_product_order_detail'>
                                        <div className='orderdetail_img_product_detail_division'>
                                            <div className='orderdetail_order_title_detail'>
                                                <div>
                                                    <div className='orderdetail_title'>
                                                        <div className='orderdetail_iphone_title'>{OrderData['CouponName']}</div>
                                                        <div className='orderdetail_title_tag'>COUPON</div>
                                                    </div>
                                                    <div className='orderdetail_coupon_desc'>
                                                        {/* {OrderData['Description']} */}This is test description for coupon...
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='orderdetail_total_value'>- ₹{OrderData['CouponAmount']}</div>
                                    </div>
                                </>
                            )}



                            <div className='orderdetail_order_title_division'>
                                <div className='orderdetail_sub_total_title'>
                                    <div>
                                        {/* <div className='orderdetail_price_title'>Items Subtotal:</div> */}
                                        <div className='orderdetail_price_title'>Order Cost:</div>
                                    </div>
                                </div>

                                <div className='orderdetail_order_price_division'>
                                    <div>
                                        {/* <div className='orderdetail_price_value'>₹{OrderData['Paid']}</div> */}
                                        <div className='orderdetail_price_value'>₹{OrderData['TotalMainAmount']}</div>
                                    </div>
                                </div>
                            </div>


                            {OrderData['TotalTax']?.map((eachTax) => {

                                return (
                                    <div className='orderdetail_order_title_division'>
                                        <div className='orderdetail_sub_total_title'>
                                            <div>
                                                {/* <div className='orderdetail_price_title'>Items Subtotal:</div> */}
                                                <div className='orderdetail_price_title'>{eachTax['Type']}</div>
                                            </div>
                                        </div>

                                        <div className='orderdetail_order_price_division'>
                                            <div>
                                                {/* <div className='orderdetail_price_value'>₹{OrderData['Paid']}</div> */}
                                                <div className='orderdetail_price_value'>₹{eachTax["Gst_Amount"]}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}


                            <div className='orderdetail_order_title_division'>
                                <div className='orderdetail_sub_total_title'>
                                    <div>
                                        {/* <div className='orderdetail_price_title'>Items Subtotal:</div> */}
                                        <div className='orderdetail_price_title'>Order Total:</div>
                                    </div>
                                </div>

                                <div className='orderdetail_order_price_division'>
                                    <div>
                                        {/* <div className='orderdetail_price_value'>₹{OrderData['Paid']}</div> */}
                                        <div className='orderdetail_price_value'>₹{(+OrderData['TotalOrderAmount'] + +DeliveryAmount) - +OrderData['CouponAmount']}</div>
                                    </div>
                                </div>
                            </div>




                            <div className='orderdetail_button_msg_division'>
                                {OrderData["IsRefund"] === "-1" ? <div className='orderdetail_refund_button'><button type="button" class="orderdetail-btn-add-order"
                                    onClick={() => {
                                        var Url = window.API_PREFIX + 'order/RefundRazorPay'
                                        fetch(Url, {
                                            method: 'POST',
                                            headers: {
                                                'Content-type': 'application/json',
                                            },
                                            body: JSON.stringify(
                                                { "Token": localStorage.getItem("Token"), "OrderId": OID }),

                                        }).then(resp => resp.json()).then(data => {
                                            console.log(data)
                                            if (data['status'] === "1") {
                                                window.location.reload()
                                            }
                                            else if (data['status'] === "0") {
                                                alert("already Refunded")
                                            }
                                        })
                                    }}
                                >Refund</button></div> : <></>}
                                {/* <div className='orderdetail_last_msg'>This order is no longer editable.</div>
                                {OrderData["IsRefund"] !== "-1" ? <div className='orderdetail_last_msg'>This Order is refunded on {OrderData["IsRefund"]} RefundId:{OrderData['RefundId']}</div> : null} */}
                            </div>
                        </div>

                        <div className='orderdetail_second_sub_main_division'>
                            <div className='orderdetail_order_detail_title'>Order #{OID} details</div>
                            {OrderData['PaymentId'] !== "-1" ? <div className='orderdetail_order_detail_sub_title'>Payment via {"UPI"}:{OrderData['PaymentId']}. Customer IP: {OrderData['CustomerIP']}</div> : null}

                            <div className='orderdetail_second_sub_division'>
                                <div className='orderdetail_general_main_division'>
                                    <div className='orderdetail_general_title'>General</div>

                                    <div className='orderdetail_date_created_title'>Date created:</div>
                                    <div className='orderdetail_date_field_division'>
                                        {/* <div><input type="date" class="orderdetailformControl me-2" placeholder="" value={}></input></div> */}
                                        {OrderData['ConfirmOrderDate']}
                                    </div>



                                    <div className='orderdetail_line_main_div'>
                                        <div className='orderdetail_date_created_title'>Customer:</div>
                                        <div className='orderdetail_profile_tag'
                                            onClick={() => {
                                                navigate("/administration/edituser/" + OrderData['UID'])
                                            }}
                                        ><u>Profile <i class="fa-solid fa-arrow-right"></i></u></div>
                                        <div className='orderdetail_profile_tag'
                                            onClick={() => {
                                                navigate("/administration/allorder/users/" + OrderData['UID'])
                                            }}
                                        ><u>View other orders <i class="fa-solid fa-arrow-right"></i></u></div>
                                    </div>

                                    {/* <div className='orderdetail_customer_search_field'>
                                            <div className='orderdetail_user_id_name'>Parth Patel(#9 - parthpatel0171@gmail.com) <i class="fa-solid fa-xmark px-1"></i> <i class="fa-solid fa-chevron-down"></i></div>
                                            <div><input type="text" class="orderdetailuseridformControl" placeholder=""></input></div>
                                            <div className='orderdetail_error_msg'>Please enter 1 or more characters</div>
                                        </div> */}

                                </div>
                                {OrderData["IsDelivery"] ? <>
                                    <div className='orderdetail_Billing_main_division'>
                                        <div className='orderdetail_shipping_title_icon_field'>
                                            <div className='orederdetail_billing_title'>Billing</div>
                                            {/* <div className='orderdetail_edit_icon'><i class="fa-solid fa-pen"></i></div> */}
                                        </div>

                                        <div className='orderdetail_user_details'>{OrderData['BillingAddName']}</div>
                                        <div className='orderdetail_user_details'>{OrderData['BStreet_Flat_name']}</div>
                                        <div className='orderdetail_user_details'>{OrderData['BLandmark']}</div>
                                        <div className='orderdetail_user_details'>{OrderData['BCity_Town']}  {OrderData['BPincode']}</div>
                                        <div className='orderdetail_user_details'>{OrderData['BState']}</div>

                                        <div className='orderdetail_email_heading'>Email address:</div>
                                        <div className='orderdetail_email'><u>{OrderData['Email']}</u></div>

                                        <div className='orderdetail_email_heading'>Phone:</div>
                                        <div className='orderdetail_email'><u>{OrderData['Mobile']}</u></div>
                                    </div>

                                    <div className='orderdetail_shipping_main_division'>
                                        <div className='orderdetail_shipping_title_icon_field'>
                                            <div className='orderdetail_shipping_title'>Shipping</div>
                                            {/* <div className='orderdetail_edit_icon'><i class="fa-solid fa-pen"></i></div> */}
                                        </div>


                                        <div className='orderdetail_shipping_address_msg'>{OrderData['ShippingAddName']}</div>
                                        <div className='orderdetail_shipping_address_msg'>{OrderData['SStreet_Flat_name']}</div>
                                        <div className='orderdetail_shipping_address_msg'>{OrderData['SLandmark']}</div>
                                        <div className='orderdetail_shipping_address_msg'>{OrderData['SCity_Town']}  {OrderData['SPincode']}</div>
                                        <div className='orderdetail_shipping_address_msg'>{OrderData['SState']}</div>
                                    </div></> :
                                    <div className='orderdetail_Billing_main_division'>
                                        <div className='orederdetail_billing_title'>In Store pick up</div>
                                        <div className='orderdetail_email_heading'>Email address:</div>
                                        <div className='orderdetail_email'><u>{OrderData['Email']}</u></div>

                                        <div className='orderdetail_email_heading'>Phone:</div>
                                        <div className='orderdetail_email'><u>{OrderData['Mobile']}</u></div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="order_detail_second_main_deivion">
                        <div className="order_detail_send_order_mail_division">
                            <p className='order_detail_send_order_mail'>Order status:  {OrderData['Status']}</p>
                            {OrderData['Status'] !== "Awaiting Confirmation" && OrderData['Status'] !== "Failed to place order" && OrderData['Status'] !== "Failed" && OrderData['PaymentId'] !== "-1" ?
                                <div class="dropdown">
                                    <button class="btn order_detail_save_order_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {selectedOptionSendOrderMail}
                                    </button>
                                    <ul class="dropdown-menu">
                                        {
                                            sendOrderMail.map((item) => {
                                                return (
                                                    <li><a className={selectedOptionSendOrderMail === item.dropdownname ? "bgColorRed dropdown-item order_detail_save_order_dropdown_menu" : "dropdown-item order_detail_save_order_dropdown_menu"}
                                                        onClick={() => setSelectedOptionSendOrderMail(item.dropdownname)}>
                                                        {item.dropdownname}
                                                    </a></li>
                                                )
                                            }
                                            )
                                        }
                                    </ul>
                                </div>
                                : null}
                            {/* 
                            {OrderData['Status'] === "Pending Payment" ? <>
                                <div class="dropdown">
                                    <button class="btn order_detail_save_order_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {selectedManualPayment}
                                    </button>
                                    <ul class="dropdown-menu">
                                        {
                                            manualPayment.map((item) => {
                                                return (
                                                    <li><a className={setSelectedManualPayment === item.dropdownname ? "bgColorRed dropdown-item order_detail_save_order_dropdown_menu" : "dropdown-item order_detail_save_order_dropdown_menu"}
                                                        onClick={() => setSelectedManualPayment(item.dropdownname)}>
                                                        {item.dropdownname}
                                                    </a></li>
                                                )
                                            }
                                            )
                                        }
                                    </ul>
                                </div>
                                <button type="button" class="btn save_order_send_button"
                                    onClick={() => {
                                        var Url = window.API_PREFIX + 'admin/paymentdone'
                                        fetch(Url, {
                                            method: 'POST',
                                            headers: {
                                                'Content-type': 'application/json',
                                            },
                                            body: JSON.stringify(
                                                { "Token": localStorage.getItem("Token"), "OrderGroupId": OID, 'Status': "Paid", PaymentId: `Manual_${selectedManualPayment}` }),

                                        }).then(resp => resp.json()).then(data => {
                                            console.log(data)
                                            if (data['status'] === "1") {
                                                var tmp = OrderData
                                                tmp.Status = "Paid"
                                                tmp.PaymentId = `Manual_${selectedManualPayment}`
                                                setOrderData({ ...tmp })
                                                notify("Payment completed.")
                                                // alert("Mail sent")
                                            }
                                        })

                                    }}

                                >Manual Paid</button></>
                                : null} */}

                            {
                                loading ?
                                    <div className="loader-container">
                                        <ClipLoader size={50} />
                                    </div>
                                    : null}

                            {OrderData['Status'] !== "Failed to place order" && OrderData['Status'] !== "Failed" && OrderData['PaymentId'] !== "-1" && OrderData['Status'] !== "Awaiting Confirmation" ?
                                <button type="button" class="btn save_order_send_button"
                                    onClick={() => {
                                        var Url = window.API_PREFIX + 'admin/changestatus'
                                        setLoading(true)
                                        fetch(Url, {
                                            method: 'POST',
                                            headers: {
                                                'Content-type': 'application/json',
                                            },
                                            body: JSON.stringify(
                                                { "Token": localStorage.getItem("Token"), "OrderGroupId": OID, 'Status': selectedOptionSendOrderMail }),

                                        }).then(resp => resp.json()).then(data => {
                                            console.log(data)
                                            if (data['status'] === "1") {
                                                var tmp = OrderData
                                                tmp.Status = selectedOptionSendOrderMail
                                                setOrderData({ ...tmp })
                                                setLoading(false)
                                                notify("Status updated.")
                                                if (selectedOptionSendOrderMail === "Shipped") {
                                                    Url = window.API_PREFIX + 'admin/delivery_slip';

                                                    // Construct the URL with query parameters
                                                    Url += `?Token=${localStorage.getItem("Token")}&OrderGroupId=${OID}`;
                                                    fetch(Url, {
                                                        method: 'GET',
                                                        headers: {
                                                            'Content-type': 'application/json',
                                                        },
                                                    }).then(resp => resp.json()).then(data => {
                                                        console.log(data)
                                                        if (data['status'] === "1") {
                                                            notify('Package slip generated')
                                                        }
                                                        else {
                                                            notify('Package slip failed to generate contact Admin')
                                                        }
                                                    })
                                                }
                                                if (selectedOptionSendOrderMail === "Delivered") {
                                                    Url = window.API_PREFIX + 'admin/generate_pdf';

                                                    // Construct the URL with query parameters
                                                    Url += `?Token=${localStorage.getItem("Token")}&OrderGroupId=${OID}`;
                                                    fetch(Url, {
                                                        method: 'GET',
                                                        headers: {
                                                            'Content-type': 'application/json',
                                                        },
                                                    }).then(resp => resp.json()).then(data => {
                                                        console.log(data)
                                                        if (data['status'] === "1") {
                                                            notify('Invoice generated')
                                                        }
                                                        else {
                                                            notify('Invoice failed to generate contact Admin')
                                                        }
                                                    })
                                                }

                                                // alert("Mail sent")
                                            }
                                            else {
                                                notify(data['message'])

                                            }
                                        })

                                    }}

                                >Change Status</button>
                                : null}
                            {OrderData['PaymentId'] === "-1" && OrderData['Status'] === "Awaiting Confirmation" && OrderData["IsDelivery"] ?
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px' }}><input type="number" class="orderdetailformControl me-2" placeholder="" value={DeliveryAmount} disabled={!OrderData["IsDelivery"]}
                                    onChange={(e) => {
                                        setDeliveryAmount(e.target.value);
                                    }}
                                ></input></div>
                                : null}
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
                                {OrderData['PaymentId'] === "-1" && OrderData['Status'] === "Awaiting Confirmation" ? <button type="button" class="btn accept_button"
                                    onClick={() => {
                                        var Url = window.API_PREFIX + 'admin/changestatus'
                                        fetch(Url, {
                                            method: 'POST',
                                            headers: {
                                                'Content-type': 'application/json',
                                            },
                                            body: JSON.stringify(
                                                {
                                                    "Token": localStorage.getItem("Token"),
                                                    "OrderGroupId": OID,
                                                    'Status': "Pending Payment",
                                                    DeliveryAmount: DeliveryAmount,
                                                }),

                                        }).then(resp => resp.json()).then(data => {
                                            console.log(data)
                                            if (data['status'] === "1") {
                                                var tmp = OrderData
                                                tmp.Status = "Pending Payment"
                                                setOrderData({ ...tmp })

                                                Url = window.API_PREFIX + 'admin/delivery_slip';

                                                // Construct the URL with query parameters
                                                Url += `?Token=${localStorage.getItem("Token")}&OrderGroupId=${OID}`;
                                                fetch(Url, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-type': 'application/json',
                                                    },
                                                }).then(resp => resp.json()).then(data => {
                                                    console.log(data)
                                                    if (data['status'] === "1") {
                                                        notify('Package slip generated')
                                                    }
                                                    else {
                                                        notify('Package slip failed to generate contact Admin')
                                                    }
                                                })


                                                // alert("Mail sent")
                                            }
                                            else {
                                                notify(data['message'])

                                            }
                                        })
                                    }}
                                >Accept</button>
                                    : null}

                                {OrderData['PaymentId'] === "-1" && OrderData['Status'] === "Awaiting Confirmation" ? <button type="button" class="btn reject_button"
                                    onClick={() => {
                                        var Url = window.API_PREFIX + 'admin/changestatus'
                                        fetch(Url, {
                                            method: 'POST',
                                            headers: {
                                                'Content-type': 'application/json',
                                            },
                                            body: JSON.stringify(
                                                { "Token": localStorage.getItem("Token"), "OrderGroupId": OID, 'Status': "Rejected" }),

                                        }).then(resp => resp.json()).then(data => {
                                            console.log(data)
                                            if (data['status'] === "1") {
                                                var tmp = OrderData
                                                tmp.Status = "Rejected"
                                                setOrderData({ ...tmp })

                                            }
                                            else {
                                                notify(data['message'])

                                            }
                                        })

                                    }}

                                >Reject</button> : null}

                            </div>
                        </div>

                        <div className='d-flex'>


                            {OrderData['Status'] === "Delivered" ?
                                <div className='mt-2 me-2'>
                                    <button class="Download-button"
                                        onClick={() => {
                                            var Url = window.API_PREFIX + 'order/getpdf';
                                            fetch(Url, {
                                                method: 'POST',
                                                headers: {
                                                    'Content-type': 'application/json',
                                                },
                                                body: JSON.stringify(
                                                    { "Token": localStorage.getItem("Token"), "OrderGroupId": OID }),
                                            }).then(resp => resp.json()).then(data => {
                                                console.log(data)
                                                if (data['status'] === "1") {
                                                    const link = document.createElement('a');
                                                    link.href = data['pdf'];
                                                    // link.download = 'your_filename.pdf';
                                                    document.body.appendChild(link);
                                                    link.click();
                                                    document.body.removeChild(link);
                                                    notify('Download Successfully.')
                                                }
                                            })
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="16"
                                            width="20"
                                            viewBox="0 0 640 512"
                                        >
                                            <path
                                                d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z"
                                                fill="white"
                                            ></path>
                                        </svg>
                                        <span>Invoice</span>
                                    </button>
                                    <Toaster
                                        position="top-center"
                                        reverseOrder={false}
                                    />
                                </div>
                                : <></>}


                            {OrderData['Status'] !== "Dispatched" ?
                                <div className='mt-2'>
                                    <button class="Download-button"
                                        onClick={() => {
                                            var Url = window.API_PREFIX + 'admin/get_packageslip';
                                            fetch(Url, {
                                                method: 'POST',
                                                headers: {
                                                    'Content-type': 'application/json',
                                                },
                                                body: JSON.stringify(
                                                    { "Token": localStorage.getItem("Token"), "OrderGroupId": OID }),
                                            }).then(resp => resp.json()).then(data => {
                                                console.log(data)
                                                if (data['status'] === "1") {
                                                    const link = document.createElement('a');
                                                    link.href = data['slip'];
                                                    // link.download = 'your_filename.pdf';
                                                    document.body.appendChild(link);
                                                    link.click();
                                                    document.body.removeChild(link);
                                                    notify('Download Successfully.')
                                                }
                                            })
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="16"
                                            width="20"
                                            viewBox="0 0 640 512"
                                        >
                                            <path
                                                d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z"
                                                fill="white"
                                            ></path>
                                        </svg>
                                        <span>Packing Slip</span>
                                    </button>
                                    <Toaster
                                        position="top-center"
                                        reverseOrder={false}
                                    />
                                </div> : <></>}

                        </div>

                        {/* <div className="order_detail_order_action_mail_division">
                            <p className='order_detail_order_note'>order action</p>
                            <div class="dropdown">
                                <button class="btn order_detail_order_action_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedOrderAction}
                                </button>
                                <ul class="dropdown-menu">
                                    {
                                        orderAction.map((item) => {
                                            return (
                                                <li><a className={selectedOrderAction === item.dropdownname ? "bgColorRed dropdown-item order_detail_order_action_dropdown_menu" : "dropdown-item order_detail_order_action_dropdown_menu"} onClick={() => setSelectedOrderAction(item.dropdownname)}>{item.dropdownname}</a></li>
                                            )
                                        }
                                        )
                                    }
                                </ul>
                            </div>
                            <div className='order_detail_movetotrash_division'>
                                <p className='order_detail_movetotrash'>Move to trash</p>
                                <button type="button" class="btn order_detail_order_action_button">Update</button>

                            </div>
                        </div> */}
                        {/* <div className="order_detail_create_pdf_mail_division">
                            <p className='order_detail_create_pdf'>create PDF</p>


                            <button type="button" class="btn order_detail_create_pdf_button">PDF Packing Slip</button>

                        </div> */}
                        <div className="order_detail_order_notes_main_division">
                            <p className='order_detail_order_action'>order note</p>
                            <div className='order_detail_order_notes_division'>


                                {OrderNotes?.map((eachNotes) => {
                                    // console.log(eachNotes['Date_Time'])

                                    return (<>
                                        <div className={eachNotes['IsAdmin'] ? 'order_detail_adminorder_note_text_division' : "order_detail_order_usersidenote_text_division"}>
                                            <p className='order_detail_adminorder_note_text'>-{eachNotes['Decription']}</p>
                                        </div>

                                        <p className='order_detail_adminorder_text_data'>-{eachNotes['Date_Time']} -- {eachNotes['IsAdmin'] ? "Private Note" : "Note to customer"}</p>
                                    </>)
                                })}
                            </div>

                            <div className='order_detail_add_note_division'>
                                <label for="exampleFormControlTextarea1" class="form-label">Add Note</label>
                                <textarea class="form-control order_detail_add_note_textarea" id="exampleFormControlTextarea1" rows="3" value={NoteDesc}
                                    onChange={(e) => {
                                        setNoteDesc(e.target.value)
                                        // console.log(e.target.value)
                                    }}
                                ></textarea>
                            </div>
                            <div className='order_detail_private_note_division'>
                                <div class="dropdown">
                                    <button class="btn order_detail_order_note_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {selectedOrderNotes}
                                    </button>
                                    <ul class="dropdown-menu">
                                        {
                                            privateNote.map((item) => {
                                                return (
                                                    <li><a className={selectedOrderNotes === item.dropdownname ? "bgColorRed dropdown-item order_detail_order_note_dropdown_menu" : "dropdown-item order_detail_order_note_dropdown_menu"}
                                                        onClick={() => setSelectedOrderNotes(item.dropdownname)}>{item.dropdownname}</a></li>
                                                )
                                            }
                                            )
                                        }
                                    </ul>
                                </div>
                                {
                                    loading ? <Loader /> :
                                     <button type="button" class="btn order_detail_order_note_button"
                                        onClick={() => {
                                            if (NoteDesc.length !== 0) {
                                                var Url = window.API_PREFIX + 'order/AddNote'
                                                setLoading(true)
                                                const isAdmin = selectedOrderNotes === "Private Note"
                                                fetch(Url, {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-type': 'application/json',
                                                    },
                                                    body: JSON.stringify(
                                                        { "Token": localStorage.getItem("Token"), "OrderGroupId": OID, "NoteDesc": NoteDesc, "IsAdmin": isAdmin }),

                                                }).then(resp => resp.json()).then(data => {
                                                    console.log(data)
                                                    setNoteDesc("")
                                                    if (data['status'] == "1") {
                                                        var tmpNotes = OrderNotes
                                                        tmpNotes.push({
                                                            "Date_Time": data['Date_Time'],
                                                            "Decription": NoteDesc,
                                                            "IsAdmin": selectedOrderNotes
                                                        })
                                                        setLoading(false)
                                                        setOrderNotes([...tmpNotes])

                                                    }
                                                })
                                            }
                                            else {
                                                toast.error("Add Note Description")
                                            }
                                        }}
                                    >Add</button>
                                }

                            </div>
                        </div>
                    </div>
                </div >

            </div>
        </>
    )
}

export default OrderDetailone;