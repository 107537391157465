import React, { useState } from 'react';

function Deleteaccount() {

    const [name, setname] = useState();
    const [mail, setmail] = useState();
    const [reason, setreason] = useState();
    const [error, setError] = useState({});

    const handleSubmit = async () => {
        let errors = {};
        if (!name) errors.name = "Name is required";
        if (!mail) errors.mail = "Email is required";
        if (!reason) errors.reason = "Reason is required";

        setError(errors);

        if (Object.keys(errors).length === 0) {
            try {
                const response = await fetch( window.API_PREFIX + 'admin/delete_account', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name,
                        mail,
                        reason,
                    }),
                });

                const result = await response.json();
                console.log('API response:', result);
                setname("")
                setmail("")
                setreason("")
            } catch (error) {
                console.error('Error submitting data:', error);
            }
        }
    };


    return (
        <div className='container  pb-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className='col-12 col-sm-5'>
                <h2 className='mt-5'>Account Deletion Request</h2>

                <div>
                    {/* <div className="adminlogin_label_name">Enter Your Mobile Number</div> */}
                    <input
                        // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" maxlength="10"
                        style={{ height: '40px', width: '60%' }}
                        type="text"
                        class="form-control adminlogin_text_search_field"
                        id="inputtext"
                        placeholder="Enter Full Name"
                        value={name}
                        onChange={(e) => {
                            setname(e.target.value);
                        }}
                    />
                    {error.name && <div className="error-message">{error.name}</div>}

                    <input
                        style={{ height: '40px', width: '60%' }}
                        type="email"
                        class="form-control adminlogin_text_search_field mt-2"
                        id="email"
                        placeholder="Enter Registered Mail Address"
                        value={mail}
                        onChange={(e) => {
                            setmail(e.target.value);
                        }}
                    />
                    {error.mail && <div className="error-message">{error.mail}</div>}

                    <input
                        style={{ height: '40px', width: '60%' }}
                        type="text"
                        class="form-control adminlogin_text_search_field mt-2"
                        id="inputtext"
                        placeholder="Reason For Deleting Your Account"
                        value={reason}
                        onChange={(e) => {
                            setreason(e.target.value);
                        }}
                    />
                    {error.reason && <div className="error-message">{error.reason}</div>}
                </div>


                <div className='mt-3 d-flex justify-content-start'>
                    <button style={{ width: '300px' }} className="adminlogin_login_btn"
                        onClick={handleSubmit}
                    >
                        Submit Request
                    </button>

                </div>

            </div>


        </div>
    )
}

export default Deleteaccount;