import './MyOrder.css';
import React, { useEffect, useState } from "react";
import { useStateValue } from '../../../StateProviders';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import './VerticalStepper.css';
import toast, { Toaster } from 'react-hot-toast';

import {
    Stepper,
    Step,
    useStepper,
    StepNumber,
    StepTitle,
    StepStatus,
    StepDescription,
} from "react-progress-stepper";
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { colors } from '@mui/material';

const ReviewPopStyle = {
    backgroundColor: "white",
    color: "black",
    border: "none",
    borderradius: "5px",
    padding: "30px 30px",
    boxShadow: "1px 1px 10px 1px rgb(236 236 236 / 20%)",
    width: "auto",


};

function MyOrder() {
    const notify = (msg) => toast.success(msg);

    const [{ Showpop }, dispatch] = useStateValue()
    const { step, incrementStep, decrementStep } = useStepper(1, 3);
    const [OrderData, setOrderData] = useState([])
    let navigate = useNavigate()
    const [ActiveOrderFlag, setActiveOrderFlag] = useState(true)
    const [ReviewPop, setReviewPop] = useState(false)
    const [RatingValue, setRatingValue] = useState("")
    const [ReviewDesc, setReviewDesc] = useState("")
    const [UserName, setUserName] = useState("")
    const [ReviewOrderId, setReviewOrderId] = useState("")



    useEffect(() => {
        var Url = window.API_PREFIX + 'order/SingleActiveUserOrder'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                { "Token": localStorage.getItem("Token") }),

        }).then(resp => resp.json()).then(data => {

            if (data['status'] == "1") {

                setOrderData([...data['SingleUserOrderData']])
            }
            else if (data['status'] == "0") {
                dispatch({ type: "Logout" })

                navigate('/')
            }

        })
    }, [])

    const steps = [
        {
            label: 'oredr placed',
            description: `For each ad campaign that you create, you can control how much
                    you're willing to spend on clicks and conversions, which networks
                    and geographical locations you want your ads to show on, and more.`,
        },
        {
            label: 'shipped',
            description:
                'An ad group contains one or more ads which target a shared set of keywords.',
        },
        {
            label: 'out for delivery',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
        {
            label: 'deliverd',
            description:
                'An ad group contains one or more ads which target a shared set of keywords.',
        },

    ];
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    return (
        <>
            <div className='container'>
                <div className='d-flex'>
                    <div className='myorder_path'>My Account</div>
                    <div className='myorder_path_icon'><i class="fa-solid fa-chevron-right"></i></div>
                    <div className='myorder_path_details'>My Order</div>
                </div>
                <div className='myorder_main_division'>
                    <div className='order_title_main'>My Order</div>
                    <ul class="nav nav-pills">
                        <li class="nav-item">
                            <a class={ActiveOrderFlag ? "nav-link active" : "nav-link"} aria-current="page"
                                onClick={() => {
                                    setActiveOrderFlag(true)

                                    var Url = window.API_PREFIX + 'order/SingleActiveUserOrder'
                                    fetch(Url, {
                                        method: 'POST',
                                        headers: {
                                            'Content-type': 'application/json',
                                        },
                                        body: JSON.stringify(
                                            { "Token": localStorage.getItem("Token") }),

                                    }).then(resp => resp.json()).then(data => {

                                        if (data['status'] == "1") {
                                            setOrderData([...data['SingleUserOrderData']])
                                        }
                                        else if (data['status'] == "0") {
                                            dispatch({ type: "Logout" })
                                            navigate('/')
                                        }
                                    })
                                }}
                            >Active Order</a>
                        </li>
                        <li class="nav-item">
                            <a class={!ActiveOrderFlag ? "nav-link active" : "nav-link"} href="#"
                                onClick={() => {
                                    setActiveOrderFlag(false)
                                    var Url = window.API_PREFIX + 'order/SinglePastUserOrder'
                                    fetch(Url, {
                                        method: 'POST',
                                        headers: {
                                            'Content-type': 'application/json',
                                        },
                                        body: JSON.stringify(
                                            { "Token": localStorage.getItem("Token") }),

                                    }).then(resp => resp.json()).then(data => {
                                        console.log(data)
                                        if (data['status'] == "1") {
                                            setOrderData([...data['SingleUserOrderData']])
                                        }
                                        else if (data['status'] == "0") {
                                            dispatch({ type: "Logout" })
                                            navigate('/')

                                        }
                                    })
                                }}
                            >Past Order</a>
                        </li>
                    </ul>



                </div>






                <Popup
                    modal

                    contentStyle={ReviewPopStyle}
                    open={ReviewPop}
                    closeOnDocumentClicks
                    onClose={() => {
                        setReviewPop(false)

                    }}

                >
                    <div className='d-flex justify-content-center'>
                        <div className='customer_review_pop_division'>
                            <i class="fa-solid fa-xmark d-flex justify-content-end"
                            style={{color:'black'}}
                                onClick={() => {
                                    setReviewPop(false)

                                }}
                            ></i>
                            <h4> Customer Review</h4>


                            <div class="mt-2 mb-2">
                                <Stack spacing={1}>
                                    <Rating className='product_review_costomer_rating' name="half-rating-read" defaultValue={5} precision={1}
                                        onChange={(event, newValue) => {
                                            setRatingValue(newValue)
                                        }}
                                    ></Rating>

                                </Stack>
                            </div>

                            <textarea className='customer_review_popup_textarea' id="w3review" placeholder='Descriptions' name="w3review" rows="4" cols="50"
                                onChange={(e) => {
                                    setReviewDesc(e.target.value)
                                }}
                            >
                            </textarea>
                            <div className='review_popup_post_review_button_division'>
                                <button type="button" class="review_popup_post_review_button"
                                    onClick={() => {
                                        var Url = window.API_PREFIX + 'user/post_review'
                                        fetch(Url, {
                                            method: 'POST',
                                            headers: {
                                                'Content-type': 'application/json',
                                            },
                                            body: JSON.stringify(
                                                {
                                                    "Token": localStorage.getItem("Token"),
                                                    // "OID": ReviewOrderId,
                                                    "ReviewDesc": ReviewDesc,
                                                    // "UserName": UserName,
                                                    "Rating": RatingValue

                                                }),

                                        }).then(resp => resp.json()).then(data => {

                                            if (data['status'] === "1") {
                                                toast.success("Review Posted")
                                                setReviewPop(false)
                                            }
                                            else if (data['status'] === "-1") {
                                                dispatch({ type: "Logout" })

                                                navigate('/')
                                            }
                                            else if (data['status'] === '-2') {
                                                toast.error("Already reviewed Product")
                                                setReviewPop(false)

                                            }

                                        })
                                    }}

                                >Post Review</button>
                            </div>
                        </div>
                    </div>

                </Popup>


                {
                    OrderData.map((eachGrpOrder, OrderIndex) => {
                        return (<>
                            <div className='order_page_main'>
                                <div className='myorder_product_informations'>
                                    <div className='myorder_product_info_division'>
                                        <div className='d-flex'>
                                            <div className='myorder_order_id_title'>Order ID :</div>
                                            <div className='myorder_order_number'>{eachGrpOrder['OrderGroupId']}</div>
                                        </div>
                                        {eachGrpOrder?.['Products'].map((eachOrder) => {
                                            return (
                                                <div className='myorder_jewellery_iamge'>

                                                    <img src={eachOrder['ProductImg']} className='myorderimg'
                                                        onClick={() => {
                                                            navigate("/product/" + eachOrder['Product_Id'])
                                                        }}
                                                    ></img>

                                                    <div className='myorder_title'>
                                                        <div className='myorder_jewellery_name'>{eachOrder['Product_Name']}</div>
                                                        <div className='myorder_price'> ₹{eachOrder['Amount']}.00</div>
                                                    </div>
                                                </div>)
                                        })}

                                        {eachGrpOrder['DeliveredDate'] !== "-1" ?
                                            <div className='myorder_download_invoice'
                                                onClick={() => {
                                                    var Url = window.API_PREFIX + 'order/getpdf';
                                                    fetch(Url, {
                                                        method: 'POST',
                                                        headers: {
                                                            'Content-type': 'application/json',
                                                        },
                                                        body: JSON.stringify(
                                                            { "Token": localStorage.getItem("Token"), "OrderGroupId": eachGrpOrder['OrderGroupId'] }),
                                                    }).then(resp => resp.json()).then(data => {
                                                        console.log(data)
                                                        if (data['status'] === "1") {
                                                            const link = document.createElement('a');
                                                            link.href = data['pdf'];
                                                            // link.download = 'your_filename.pdf';
                                                            document.body.appendChild(link);
                                                            link.click();
                                                            document.body.removeChild(link);
                                                            notify('Download Successfully.')
                                                        }
                                                    })
                                                }}
                                            >
                                                <i class="myorder_invoice_icon fa-solid fa-file-invoice me-2"></i> Download Invoice
                                            </div> : null}

                                        <div className='myorder_delivery_address_title'>Delivery Address:</div>
                                        <div className='myorder_order_person_name'>{eachGrpOrder['User_Name']}</div>
                                        <div className='myorder_delivery_address'>{eachGrpOrder['BillingAddress_1']}<br></br>{eachGrpOrder['BillingAddress_2']}</div>
                                        <div className='myorder_mumber_division'>
                                            <div className='myorder_number'>+91{eachGrpOrder['BillingAddress_Num']}</div>
                                        </div>

                                        <div className='myorder_note_from_pankaj_jewellers_division'>
                                            <div className='myorder_note_pankaj_jewellers'>note from Pankaj Jeweller's: </div>
                                            {
                                                eachGrpOrder.Notes.map((eachNote, index) => {
                                                    return (
                                                        <div className='myorder_note_from_pankaj_jewellers'>
                                                            {index + 1} {")"} {eachNote['Decription']}--{eachNote['Date_Time']}
                                                        </div>
                                                    )

                                                })
                                            }
                                        </div>

                                    </div>




                                </div>
                                <div className='order_status_cart_order_page'>
                                    <div style={{ fontSize: '20px' }}>Order Status</div>
                                    {eachGrpOrder['DeliveredDate'] !== "-1" ?
                                        <><div className='date_time_order_page'>
                                            Delivered on {eachGrpOrder['DeliveredDate']}
                                        </div>
                                            <div className='delieverd_status_order_page'>
                                                Your order has {eachGrpOrder['Status']}
                                            </div>
                                            <div className='Rating_order_page'>
                                                {/* <i class="fa-solid fa-star  rat_order"></i>Rate & Review */}
                                            </div>



                                            {eachGrpOrder['RefundDate'] === "-1" && eachGrpOrder['DeliveredDate'] !== "-1" ? <div className='refund_type_order_page'
                                                onClick={() => {
                                                    var Url = window.API_PREFIX + 'order/RefundNote'
                                                    fetch(Url, {
                                                        method: 'POST',
                                                        headers: {
                                                            'Content-type': 'application/json',
                                                        },
                                                        body: JSON.stringify(
                                                            {
                                                                "Token": localStorage.getItem("Token"),
                                                                "OrderId": eachGrpOrder['OrderGroupId']
                                                            })

                                                    }).then(resp => resp.json()).then(data => {

                                                        if (data['status'] == "1") {
                                                            var tmp = OrderData
                                                            tmp[OrderIndex]['Notes'].push({ Decription: data['Decs'], Date_Time: data['Date_Time'] })
                                                            setOrderData([...tmp])
                                                            alert("Refund Request Generated")
                                                        }
                                                        else if (data['status'] == "2") {
                                                            alert("Refund Request has already been generated on " + data['Decs'])
                                                        }

                                                    })
                                                }}
                                            >Refund</div> : null}
                                        </> :
                                        <>Expected Delivery : {eachGrpOrder['ExpectedDeliveryDate']}</>

                                    }




                                    <div className='order_status_div'>
                                        {eachGrpOrder['ConfirmOrderDate'] !== "-1" ?
                                            <div className='order_placed_order_page_down'>
                                                <div className='d-flex align-items-center'>
                                                    {/* <i class="fa-solid fa-circle-check fa-xl me-1" style={{ color: '#8863FB' }}></i> */}
                                                    Order Placed <br></br> {eachGrpOrder['ConfirmOrderDate']}
                                                </div>
                                            </div>
                                            : null}

                                        {eachGrpOrder['DispatchedDate'] !== "-1" ?
                                            <div className='order_placed_order_page_down'>
                                                <div className='d-flex align-items-center'>
                                                    {/* <i class="fa-solid fa-circle-check fa-xl me-1" style={{ color: '#8863FB' }}></i> */}
                                                    Dispatch <br></br>{eachGrpOrder['DispatchedDate']}
                                                </div>

                                            </div> : null
                                        }
                                        {eachGrpOrder['ShippedDate'] !== "-1" ?
                                            <div className='order_placed_order_page_down'>
                                                <div className='d-flex align-items-center'>
                                                    {/* <i class="fa-solid fa-circle-check fa-xl me-1" style={{ color: '#8863FB' }}></i> */}
                                                    Shipped<br></br> {eachGrpOrder['ShippedDate']}
                                                </div>
                                            </div>
                                            : null}

                                        {eachGrpOrder['OutForDeliveryDate'] !== "-1" ?
                                            <div className='order_placed_order_page_down'>
                                                <div className='d-flex align-items-center'>
                                                    {/* <i class="fa-solid fa-circle-check fa-xl me-1" style={{ color: '#8863FB' }}></i> */}
                                                    Out of delivery <br></br>{eachGrpOrder['OutForDeliveryDate']}
                                                </div>
                                            </div> : null
                                        }
                                        {eachGrpOrder['CancelDate'] !== "-1" ?
                                            <div className='order_placed_order_page_down'>
                                                <div className='d-flex align-items-center'>
                                                    {/* <i class="fa-solid fa-circle-check fa-xl me-1" style={{ color: '#8863FB' }}></i> */}
                                                    Cancelled <br></br>{eachGrpOrder['CancelDate']}
                                                </div>
                                            </div> : null
                                        }
                                    </div>

                                    <button className=' btn myorder_download_invoice' onClick={() => {
                                        setReviewPop(true)
                                    }}>
                                        Review
                                    </button>


                                </div>
                            </div>
                        </>)
                    })
                }

            </div>
        </>
    )
}


export default MyOrder;