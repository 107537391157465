import React from 'react';
import './Blogs.css';
import side_image_1 from '../../../../images/Desktop_gold-min.png'

function Blogtwo() {

    const postDate = new Date();
    const formattedDate = postDate.toLocaleDateString();



    return (
        <div className='container'>
            <div className='blog_main_title'>BEAUTIFUL NATURE </div>
            <div className='d-flex'>
                <div className='blog_main_title2'>Posted by patel</div>
                &nbsp;|&nbsp;
                <div className='blog_main_title2'>{formattedDate}</div>
                &nbsp;|&nbsp;
                <div className='blog_main_title2 ms-1' >Nature</div>
                &nbsp;|&nbsp;
                <div className='d-flex'>
                    <span>4.6 <i className="fa-solid fa-star" style={{ color: 'pink' }}></i> 78 </span>
                </div>
            </div>

            <div>lorem34</div>

        </div>
    )
}

export default Blogtwo