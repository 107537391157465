import './Homepage.css'
import { useEffect, useState } from 'react';
import * as React from "react";
import 'antd/dist/antd.css';
import { Carousel } from 'antd';
import { useStateValue } from '../../../StateProviders';
import { default as Carousel1 } from "react-elastic-carousel";
import { useNavigate } from 'react-router-dom';
import goldpadents from '../../../../images/brand1.png'
import goldrings from '../../../../images/brand2.png'
import goldearings from '../../../../images/brand3.png'
import goldchains from '../../../../images/brand4.png'
import goldbraclets from '../../../../images/brand6.png'
import goldnosepin from '../../../../images/brand5.png'
import under999 from '../../../../images/under999.jpg'
import giftofchoice from '../../../../images/giftofchoice.png'
import under2999 from '../../../../images/under2999.jpg'
import under4999 from '../../../../images/under4999.jpg'
import premiumgift from '../../../../images/premiumgift.jpg'
import { Splide, SplideSlide, breakpoints } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';


function Homepage() {
    let navigate = useNavigate()
    const [{ ShowLoginPop }, dispatch] = useStateValue()
    const [AllSlider, setAllSlider] = useState([])
    const [AllSliderMobile, setAllSliderMobile] = useState([])
    const [AllCategory, setAllCategory] = useState([])
    const [LatestProductData, setLatestProductData] = useState([])
    const [LaptopProductData, setLaptopProductData] = useState([])

    useEffect(() => {
        var url = window.API_PREFIX + "order/allslider1";
        fetch(url, {
            method: 'POST',

        }).then(resp => resp.json()).then(data => {

            if (data['status'] == "1") {
                setAllSlider([...data['SliderData']])
            }
        })




        var url = window.API_PREFIX + "order/allslidermobile";
        fetch(url, {
            method: 'POST',

        }).then(resp => resp.json()).then(data => {

            if (data['status'] == "1") {
                setAllSliderMobile([...data['SliderData']])
            }
        })




        var url = window.API_PREFIX + "products/get_category";
        fetch(url, {
            method: 'GET',

        }).then(resp => resp.json()).then(data => {


            if (data['status'] === "1") {
                setAllCategory([...data['AllCategory']])
            }
        })


        var url = window.API_PREFIX + "products/homepage_Latest";
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    "Token": localStorage.getItem('Token')

                }

            )
        }).then(resp => resp.json()).then(data => {

            if (data['status'] === "1") {
                setLatestProductData([...data['products']])
            }
        })
    }, [])


    useEffect(() => {

        var url = window.API_PREFIX + "products/categorywise_products";
        var Data = {}
        if (localStorage.getItem('Token') != null) {
            Data['Token'] = localStorage.getItem('Token')

        }

        Data['Category_Id'] = '1'

        Data['Type'] = 'Category'


        fetch(url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                Data

            )
        }).then(resp => resp.json()).then(data => {

            if (data['status'] === "1") {
                setLaptopProductData([...data['products']])
            }
        })
    }, [])

    const contentStyle = {
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
        objectFit: 'content',
    };
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
        { width: 768, itemsToShow: 3 },
        { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
        { width: 1450, itemsToShow: 5 },
        { width: 1750, itemsToShow: 6 },
    ];
    const breakPoints2 = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 3 },
        { width: 768, itemsToShow: 3 },
        { width: 1150, itemsToShow: 4 },
        { width: 1450, itemsToShow: 5 },
        { width: 1750, itemsToShow: 6 },
    ];


    const CustomArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === 'PREV' ? <i class="fa-solid fa-chevron-left fa-2x"></i> : <i class="fa-solid fa-chevron-right fa-2x"></i>;
        return (
            <div
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={onClick}
                disabled={isEdge}
                className="custom-arrow"
            >
                {pointer}
            </div>
        );
    };



    return (
        <div className='homepage_banner_carousel'>
            <Carousel autoplay dotPosition="bottom" arrows className='hpmepage_main_carousal' >
                {AllSlider.map((eachSlider) => {

                    return (<div>
                        <a href={eachSlider['SliderLink']}> <h3 style={contentStyle}> <img src={eachSlider['SliderImg']} style={{ width: "100%" }}></img> </h3></a>
                    </div>)
                })}
            </Carousel>

            <div className='container mt-5 mb-5'>







                <div className=' row g-4'>
                    {AllCategory.map((eachCategory) => {
                        return (
                            <div className='col col-12 col-md-6 col-lg-3'>
                                <div className='cat_main_div_two mt-3' >
                                    <img className='' src={eachCategory['CategoryImg']}></img>
                                    <div className='cat_name' >{eachCategory['CategoryName']}</div>
                                    <button className='btn exp_btn' onClick={() => {
                                        navigate("/filter/Category&" + eachCategory['CategoryName'] + '&' + eachCategory['CategoryId'])
                                    }}>EXPLORE</button>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className='container text-center mt-5 relative'>
                    <img src={giftofchoice} className='gift_of_choice'></img>

                </div>


                {/* vishwa homepage just arrived code start */}
                <div>
                    <div className="homepage_title_name mt-3">Just Arrived</div>
                    <div className="App">
                        <Carousel1 breakPoints={breakPoints} pagination={false} renderArrow={CustomArrow}  >
                            {LatestProductData.map((eachProduct, ProductIndex) => {
                                if (eachProduct['IsOffer'] === true) {
                                    var offer = Math.floor(((eachProduct.BuyPrice - eachProduct.SalePrice) / eachProduct.BuyPrice) * 100)
                                }
                                return (
                                    <div className='cardd_mainn' onClick={() => {
                                        navigate(`/product/${eachProduct.ProductId}`)
                                    }}>

                                        <div className='d-flex justify-content-center'>
                                            <div className='card_top' >
                                                <img src={eachProduct.ProductImg} className='image_size' alt="Product Image"></img>
                                                {eachProduct.IsOffer ? <div className='le'>{offer}% Off</div> : null}
                                                <div className='review_div'>4.9/75</div>
                                                {/* <div className='re'>
                                                        <Popup
                                                            ref={ref}
                                                            trigger={

                                                                <div
                                                                ><i className={!eachProducts["IsWishlisted"] ? "fa-regular fa-heart fa-xl heart_Product_page" : "fa-solid fa-heart fa-xl heart_Product_page_1"}
                                                                    onClick={(e) => {
                                                                        if (ShowLoginPop) {
                                                                            var tmp_wishlist = wishList
                                                                            var Url = ""
                                                                            if (eachProducts["IsWishlisted"]) { Url = window.API_PREFIX + 'products/delete_wishlist' }
                                                                            else { Url = window.API_PREFIX + 'products/add_wishlist' }

                                                                            fetch(Url, {
                                                                                method: 'POST',
                                                                                headers: {
                                                                                    'Content-type': 'application/json',
                                                                                },
                                                                                body: JSON.stringify(
                                                                                    { "Token": localStorage.getItem("Token"), "ProductId": eachProducts.ProductId }),
                                                                            }).then(resp => resp.json()).then(data => {

                                                                                if (data['status'] == "1" || data['status'] == "-1") {
                                                                                    var Tmp = ProductData
                                                                                    tmp_wishlist[ProductIndex] = !tmp_wishlist[ProductIndex]
                                                                                    Tmp[ProductIndex]['IsWishlisted'] = !Tmp[ProductIndex]['IsWishlisted']
                                                                                    setProductData([...Tmp])
                                                                                    if (Tmp[ProductIndex]['IsWishlisted']) {
                                                                                        dispatch({
                                                                                            type: 'ADD_TO_WISHLIST',
                                                                                            item: ProductData[ProductIndex]
                                                                                        });
                                                                                    } else {
                                                                                        dispatch({
                                                                                            type: 'REMOVE_FROM_WISHLIST',
                                                                                            id: ProductData[ProductIndex].ProductId
                                                                                        });
                                                                                    }
                                                                                }
                                                                                else if (data['status'] == "0") {
                                                                                    dispatch(
                                                                                        { type: "Logout" }
                                                                                    )
                                                                                    dispatch(
                                                                                        { type: "OpenLogin" }
                                                                                    )
                                                                                }
                                                                            }

                                                                            )

                                                                            const timeId = setTimeout(() => {
                                                                                // closeTooltip()
                                                                            }, 2000)
                                                                        }
                                                                        else {
                                                                            dispatch({ type: "OpenLogin" })
                                                                        }

                                                                    }}></i></div>

                                                            }

                                                            position="left top"
                                                            className='wishlist_cross_btn'
                                                            contentStyle={contentStyle}
                                                        >

                                                            <div className='wishlist_msg_icon'>
                                                                <div><img className='wishlist_mobile_image' src={right_tick} /></div>
                                                                {wishList[ProductIndex] ? <div className='wishlist_remove_msg'>Product Added wishlist successfully!</div> : <div className='wishlist_remove_msg'> Product removed from wishlist successfully!</div>}

                                                            </div>
                                                        </Popup>
                                                    </div> */}
                                            </div>
                                        </div>

                                        <div className='price_of_product_vertical_product' style={{ textAlign: 'left' }}>
                                            <div style={{ maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                <span style={{ fontSize: '16px', fontWeight: '400' }}>{eachProduct['Name']}</span>
                                            </div>

                                            {eachProduct.IsOffer ? <div className='price_both_discounted_and_regular_vertical_page'>
                                                <div className='discounted_price'>
                                                    <span style={{ fontSize: '20px' }} >₹{eachProduct['SalePrice']}</span>
                                                </div>

                                                <span className='mx-2 text-decoration-line-through' style={{ fontSize: '10px', color: '#878787' }}>₹{eachProduct['BuyPrice']}</span>

                                            </div> :
                                                <div className='price_both_discounted_and_regular_vertical_page'>
                                                    <span className='mx-2 text-decoration-line-through' style={{ fontSize: '10px', color: '#878787' }} >₹{eachProduct['BuyPrice']}</span>
                                                </div>}
                                            <div className='name_reference_of_product_vertical_page' onClick={() => {
                                                navigate(`/product_/` + eachProduct.ParmaLink)

                                            }}>

                                            </div>



                                            <button type="button" className='add_to_cart_btn btn'
                                                onClick={() => {
                                                    if (ShowLoginPop) {
                                                        var Url = window.API_PREFIX + 'products/add_99to_cart'
                                                        fetch(Url, {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-type': 'application/json',
                                                            },
                                                            body: JSON.stringify(
                                                                { "Token": localStorage.getItem("Token"), "Product_Id": eachProduct["ProductId"] }),
                                                        }).then(resp => resp.json()).then(data => {

                                                            if (data['status'] == "1") {
                                                                navigate("/checkout")
                                                            }
                                                        })
                                                    }
                                                    else {
                                                        dispatch(
                                                            { type: "Logout" }
                                                        )
                                                        dispatch(
                                                            { type: "OpenLogin" }
                                                        )
                                                    }
                                                }}
                                            >{eachProduct.AllVarient[0].IsCart ? "Go to Cart" : "Add to Cart"}</button>


                                        </div>
                                    </div>

                                )
                            })}
                        </Carousel1>
                    </div>
                    {/* vishwa homepage just arrived code end  */}


                </div>



                {/* vishwa homepage In Vouge code start  */}
                <div>
                    <h2 className="homepage_title_name">In Vouge</h2>
                    <div className="App">
                        <Carousel1 breakPoints={breakPoints} pagination={false} renderArrow={CustomArrow}>
                            {LatestProductData.map((eachProduct, ProductIndex) => {
                                if (eachProduct['IsOffer'] === true) {
                                    var offer = Math.floor(((eachProduct.OriginalPrice - eachProduct.SelingPrice) / eachProduct.OriginalPrice) * 100)
                                }
                                return (
                                    <div className='cardd_mainn' onClick={() => {
                                        navigate(`/product/${eachProduct.ProductId}`)
                                    }}>

                                        <div className='d-flex justify-content-center'>
                                            <div className='card_top' >
                                                <img src={eachProduct.ProductImg} className='image_size' alt="Product Image"></img>
                                                <div className='review_div'>4.9/75</div>
                                            </div>
                                        </div>

                                        <div className='price_of_product_vertical_product' style={{ textAlign: 'left' }}>
                                            <div style={{ maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                <span style={{ fontSize: '16px', fontWeight: '400' }}>{eachProduct['Name']}</span>
                                            </div>
                                            {eachProduct.IsOffer ? <div className='price_both_discounted_and_regular_vertical_page'>
                                                <div className='discounted_price'>
                                                    <span style={{ fontSize: '20px' }} >₹{eachProduct['SalePrice']}</span>
                                                </div>

                                                <span className='mx-2 text-decoration-line-through' style={{ fontSize: '10px', color: '#878787' }}>₹{eachProduct['BuyPrice']}</span>

                                            </div> :
                                                <div className='price_both_discounted_and_regular_vertical_page'>
                                                    <span className='mx-2 text-decoration-line-through' style={{ fontSize: '10px', color: '#878787' }} >₹{eachProduct['BuyPrice']}</span>
                                                </div>}
                                            <div className='name_reference_of_product_vertical_page' onClick={() => {
                                                navigate(`/product_/` + eachProduct.ParmaLink)

                                            }}>

                                            </div>



                                            <button type="button" className='add_to_cart_btn btn'
                                                onClick={() => {
                                                    if (ShowLoginPop) {
                                                        var Url = window.API_PREFIX + 'products/add_99to_cart'
                                                        fetch(Url, {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-type': 'application/json',
                                                            },
                                                            body: JSON.stringify(
                                                                { "Token": localStorage.getItem("Token"), "Product_Id": eachProduct["ProductId"] }),
                                                        }).then(resp => resp.json()).then(data => {

                                                            if (data['status'] == "1") {
                                                                navigate("/checkout")
                                                            }
                                                        })
                                                    }
                                                    else {
                                                        dispatch(
                                                            { type: "Logout" }
                                                        )
                                                        dispatch(
                                                            { type: "OpenLogin" }
                                                        )
                                                    }
                                                }}
                                            >{eachProduct.AllVarient[0].IsCart ? "Go to Cart" : "Add to Cart"}</button>


                                        </div>
                                    </div>
                                )
                            })}




                        </Carousel1>
                    </div>
                    {/* vishwa homepage In Vouge code end  */}


                </div>


                {/* vishwa homepage shop by price code start */}
                <div>
                    <h2 className="homepage_title_name">shop by Price</h2>
                    <div className='hompage_shopbyprcie_section_division row '>
                        <img src={under999} className='under_tag col-6 col-sm-3 '></img>
                        <img src={under2999} className='under_tag col-6 col-sm-3 '></img>
                        <img src={under4999} className='under_tag col-6 col-sm-3 '></img>
                        <img src={premiumgift} className='under_tag col-6 col-sm-3 '></img>
                    </div>
                </div>
                {/* vishwa homepage shop by price code end*/}

                {/* vishwa homepage shop by Category code start */}
                {/* <div>
                    <h2 className="homepage_title_name">shop by Category</h2>
                    <div className='homepage_shopbybrand_section_division row'>

                        <div className='col col-6 col-sm-4 col-md-4' onClick={() => {
                            navigate("filter/Category&Rings&25")
                        }}>
                            <div className='card' >
                                <img src={goldpadents} className='gold_padents'>
                                </img>
                            </div>
                            <h3 className='pedants'>
                                <b>Gold padents</b>
                            </h3>
                        </div>

                        <div className='col col-6 col-sm-4 col-md-4' onClick={() => {
                            navigate("/filter/Brand&_&" + 'Apple')
                        }}>
                            <div className='card' >
                                <img src={goldrings} className='gold_padents'>
                                </img>
                            </div>
                            <h3 className='pedants'>
                                <b>Gold rings</b>
                            </h3>
                        </div>

                        <div className='col col-6 col-sm-4 col-md-4' onClick={() => {
                            navigate("/filter/Brand&_&" + 'Vivo')
                        }}>
                            <div className='card' >
                                <img src={goldearings} className='gold_padents'>
                                </img>
                            </div>
                            <h3 className='pedants'>
                                <b>Gold earings</b>
                            </h3>
                        </div>

                        <div className='col col-6 col-sm-4 col-md-4' onClick={() => {
                            navigate("/filter/Brand&_&" + 'Oppo')
                        }}>
                            <div className='card' >
                                <img src={goldchains} className='gold_padents'>
                                </img>
                            </div>
                            <h3 className='pedants'>
                                <b>Gold chains</b>
                            </h3>
                        </div>

                        <div className='col col-6 col-sm-4 col-md-4' onClick={() => {
                            navigate("/filter/Brand&_&" + 'Oneplus')
                        }}>
                            <div className='card' >
                                <img src={goldnosepin} className='gold_padents'>
                                </img>
                            </div>
                            <h3 className='pedants'>
                                <b>Gold Nosepins</b>
                            </h3>
                        </div>

                        <div className='col col-6 col-sm-4 col-md-4' onClick={() => {
                            navigate("/filter/Brand&_&" + 'Oneplus')
                        }}>
                            <div className='card' >
                                <img src={goldbraclets} className='gold_padents'>
                                </img>
                            </div>
                            <h3 className='pedants'>
                                <b>Gold Braclets</b>
                            </h3>
                        </div>


                    </div>
                </div> */}
                {/* vishwa homepage shop by Category code end */}

            </div>
        </div>
    )
}
// const rootElement = document.getElementById("root");
// ReactDOM.render(<Homepage />, rootElement);

export default Homepage;