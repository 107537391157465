import './CategoryImage.css'
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import Loader from "../../Loader";

function CategoryImage() {


    const [AddCategory, setAddCategory] = useState("")
    const [AddCategoryFlag, setAddCategoryFlag] = useState(false)



    const [addproductCategory, setaddproductCategoryl] = useState([])


    const [firstImage, setFirstImage] = useState(null);
    const [secondImage, setSecondImage] = useState(null);

    const [CategoryId, setCategoryId] = useState('-1')

    const handleUpload = (event) => {
        var uploadData = new FormData();
        uploadData.append("CategoryImg", firstImage);
        uploadData.append("CategoryImg2", secondImage);
        uploadData.append('CategoryId', CategoryId);

        var url = window.API_PREFIX + "products/add_category_image";

        fetch(url, {
            method: 'POST',
            body: uploadData
        }).then(resp => resp.json()).then(data => {
            if (data['status'] === "1") {
                setcurrentImg(data["ImgAddress"]);
                setcurrentImg2(data["ImgAddress2"]);
                window.location.reload();
            }
        });
    };

    useEffect(() => {
        var Url = window.API_PREFIX + 'products/get_category'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                { "Token": localStorage.getItem("Token") }),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                setaddproductCategoryl([...data["AllCategory"]])
                setSelectedOptionaddproductCategory(data["AllCategory"][0]["CategoryName"])
                setcurrentImg(data['AllCategory'][0]['CategoryImg'])
                setcurrentImg2(data['AllCategory'][0]['CategoryImg2'])

            }
        })
    }, [])

    const [selectedOptionaddproductCategory, setSelectedOptionaddproductCategory] = useState();
    const [currentImg, setcurrentImg] = useState('')
    const [currentImg2, setcurrentImg2] = useState('')
    return (

        <div className='CategoryImage_main_division'>

            <div className='CategoryImage_title'>Category Image</div>

            <div className='addproduct_category_text_field'>
                <div className='me-4'>Select Category</div>
                <div class="dropdown">
                    <button class="btn addproduct_detail_save_order_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" >
                        {selectedOptionaddproductCategory}
                    </button>
                    <ul class="dropdown-menu">
                        {
                            addproductCategory.map((item) => {
                                return (
                                    <li><a className={selectedOptionaddproductCategory === item.CategoryName ? "bgColorRed dropdown-item order_detail_save_order_dropdown_menu" : "dropdown-item order_detail_save_order_dropdown_menu"}
                                        onClick={() => {
                                           
                                            setSelectedOptionaddproductCategory(item.CategoryName)
                                            setCategoryId(item.CategoryId)
                                            setcurrentImg(item.CategoryImg)
                                            setcurrentImg2(item.CategoryImg2)
                                        }}
                                    >{item.CategoryName}</a></li>
                                )
                            }
                            )
                        }
                    </ul>
                </div>
                {!AddCategoryFlag ?
                    <div className='addproduct_add_icon'
                        onClick={() => {
                            setAddCategoryFlag(true)
                        }}
                    ><i class="fa-solid fa-circle-plus"></i></div> :
                    <>
                        <input type="text" class="form-control addproductcategoryformControl" placeholder=""

                            onChange={(e) => {
                                setAddCategory(e.target.value)

                            }}
                        ></input>
                        <button className='addproduct_category_add_btn'
                            onClick={() => {
                                if (AddCategory.length != 0) {
                                    var Url = window.API_PREFIX + 'products/add_category'
                                    fetch(Url, {
                                        method: 'POST',
                                        headers: {
                                            'Content-type': 'application/json',
                                        },
                                        body: JSON.stringify(
                                            { "Token": localStorage.getItem("Token"), "CategoryName": AddCategory }),
                                    }).then(resp => resp.json()).then(data => {
                                        console.log(data)
                                        if (data['status'] == "1") {
                                            setAddCategory("")
                                            setAddCategoryFlag(false)
                                            toast.success(`Successfully Added Category ${AddCategory} `)
                                        }
                                    })

                                }
                            }}
                        >ADD</button>
                        <button className='addproduct_category_add_btn'
                            onClick={() => {
                                setAddCategoryFlag(false)
                            }}
                        >CLEAR</button></>}
            </div>
            <div className='row '>


                <div className='col col-md-6'>
                    <h6>Category Website</h6>
                    <input
                        style={{ cursor: "pointer" }}
                        type="file"
                        name="myImage1"
                        accept="image/png, image/jpeg"
                        onChange={(event) => setFirstImage(event.target.files[0])}
                    />
                    <img style={{ width: '10%', border: '1px solid black' }} src={currentImg}
                    />
                    <h6 style={{ wordBreak: "break-word", marginTop: '5px', width: '30%' }}>{currentImg}</h6>
                </div>

                <div className='col col-md-6'>
                    <h6>Category Application</h6>
                    <input
                        style={{ cursor: "pointer" }}
                        type="file"
                        name="myImage2"
                        accept="image/png, image/jpeg"
                        onChange={(event) => setSecondImage(event.target.files[0])}
                    />

                    <img style={{ width: '10%', border: '1px solid black' }} src={currentImg2}
                    />
                    <h6 style={{ wordBreak: "break-word", marginTop: '5px', width: '30%' }}>{currentImg2}</h6>
                </div>

            </div>





            <button className='active_order_ac' onClick={handleUpload}>Upload Images</button>

        </div>
    )
}





export default CategoryImage;