export const initialState = {
    basket:[],
    wishList:[],
    ShowLoginPop:true,
    CloseLogin:false,
    ShowAdminLoginPop:false,
    ComparePop:false,
    CompareVar:false,
    NewNotification:false,

};

const reducer = (state,action)  =>
{

    switch(action.type)
    {case 'ADD_TO_BASKET':
    return{
        ...state,basket:[...state.basket,action.item],
    };
    case 'REMOVE_FROM_BASKET':
            const index = state.basket.findIndex(item => item.ProductId === action.id);
            if (index >= 0) {
                const newBasket = [...state.basket];
                newBasket.splice(index, 1);
                return {
                    ...state,
                    basket: newBasket,
                };
            } else {
                console.warn(`Can't remove product (id: ${action.id}) as it's not in the basket!`);
                return state;
            }
            case 'CLEAR_BASKET':
                return {
                    ...state,
                    basket: [],
                };
    case 'ADD_TO_WISHLIST':
    return{
        ...state, wishList:[...state.wishList,action.item],
    }
    case 'REMOVE_FROM_WISHLIST':
        const updatedWishList = state.wishList.filter(item => item.ProductId !== action.id);
        return {
            ...state,
            wishList: updatedWishList,
        };
    case 'Login':
    return{
        ...state,ShowLoginPop:true,
    };
    case 'Logout':
        return{
            ...state,ShowLoginPop:false,
        };
    case 'CloseLogin':
        return{
            ...state,CloseLogin:false,
        };
    case 'OpenLogin':
        return{
            ...state,CloseLogin:true,
        };
    case 'CloseALogin':
        return{
            ...state,ShowAdminLoginPop:true,
        };
    case 'OpenALogin':
        return{
            ...state,ShowAdminLoginPop:false,
        };
    case 'OpenComparePop':
        return{
            ...state,ComparePop:true,
        };
    case 'CloseComparePop':
        return{
            ...state,ComparePop:false,
        };
        
    case 'UpdateCompare':
        return{
            ...state,CompareVar:!state.CompareVar,
        };
    case 'NewNotification':
        return{
            ...state,NewNotification:true,
        }
    default: return state;
    }
}
export default reducer;