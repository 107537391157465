import './ContactUs.css';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';




function ContactUs() {

  const recaptcha = useRef()


  let navigate = useNavigate()
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Contact: '',
    Description: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue()

    if (!captchaValue) {
      alert('Please verify the reCAPTCHA!')
    } else {
      // make form submission
      alert('Form submission successful!')
    }

    var Url = window.API_PREFIX + 'admin/contact_us'
    fetch(Url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(formData)
    }).then(resp => resp.json()).then(data => {

      if (data['status'] === "1") {
        alert("Admin will contact soon")
        navigate('/')
      }
      else {
        alert("quantity is not available")
      }
    }
    )
  };

  return (
    <div className='container w-50'>
      <form onSubmit={handleSubmit}>
 
          <input
            name="Name"
            style={{ height: '40px', width: '60%' }}
            type="text"
            class="form-control adminlogin_text_search_field mt-2 "
            id="inputtext"
            placeholder="Enter Full Name"
            value={formData.Name}
            onChange={handleChange}
            required
          />

    
          <input
            name="Email"
            style={{ height: '40px', width: '60%' }}
            type="mail"
            class="form-control adminlogin_text_search_field mt-2 "
            id="inputtext"
            placeholder="Enter Your Mail"
            value={formData.Email}
            onChange={handleChange}
            required
          />
 

          <input
            name="Contact"
            style={{ height: '40px', width: '60%' }}
            type="tel"
            class="form-control adminlogin_text_search_field mt-2 "
            id="inputtext"
            placeholder="Enter Your Number"
            value={formData.Contact}
            onChange={handleChange}
            required
          />
   
   
          <textarea
           placeholder="Enter Description "
            name="Description"
            class="form-control mt-2"
            style={{width:'60%'}}
            id="exampleFormControlTextarea1"
            rows="3"
            value={formData.Description}
            onChange={handleChange}
            required
          ></textarea>

      
        <ReCAPTCHA sitekey='6LfkrxkqAAAAAC404JlbVlxQUqIG0-jzjgE_MbI3' className='mt-2' ref={recaptcha} />
        <button type="submit"  className='btn adminlogin_login_btn mt-2 w-50'>Submit</button>
      </form>

    </div>

  );
}


export default ContactUs;