import React, { useState } from 'react';
import './Forgetpassword.css';
import { Password } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

const App = () => {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [rOTP, setrOtp] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    let navigate = useNavigate()

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        
        var Url = window.API_PREFIX + 'admin/generate_otp_forget_password'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                { "Email": email}),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                setrOtp(data['OTP'])
                alert("OTP sent")
                setStep(2);
            }
            else{
                alert("Invalid email")

            }
        })
        // Call API to send OTP to email
        console.log(`Email submitted: ${email}`);
    };

    const handleOtpSubmit = (e) => {
        e.preventDefault();
        // Call API to verify OTP
        if (otp===rOTP){
            setStep(3);

        }
        else{
            alert("invalid OTP")
        }
        console.log(`OTP submitted: ${otp}`);

    };

    const handlePasswordReset = (e) => {
        e.preventDefault();
        if (password === confirmPassword) {
            // Call API to reset password
            var Url = window.API_PREFIX + 'admin/forget_password'
            fetch(Url, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(
                { "Email": email,
                    "Password":password,
                    OTP:otp
                }),
       
            }).then(resp => resp.json()).then(data => {
                console.log(data)
                if (data['status'] === "1") {
                 alert("Password has changed successfully")
                 navigate('/administration')
                    setStep(1);
                }
                else{
                    alert("Invalid email")
    
                }
            })

            console.log(`Password reset to: ${password}`);
            alert('Password has been reset successfully!');
        } else {
            alert('Passwords do not match');
        }
    };

    return (
        <div className="App">
            <div className="form-container">
                {step === 1 && (
                    <form onSubmit={handleEmailSubmit}>
                        <p>Enter your email to get a password reset</p>
                        <label>
                            Email:
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </label>
                        <button type="submit" className="btn submit_button">Send OTP on email</button>
                        {/* <button type="button" className="btn reset_button mt-3" onClick={() => setStep(1)}>Reset Password</button> */}
                    </form>
                )}

   

                {step === 2 && (
                    <form onSubmit={handlePasswordReset}>
                           <label>
                            Enter the OTP:
                            <input
                                type="text"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                required
                            />
                        </label>
                        <label>
                            New Password:
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </label>
                        <label>
                            Confirm New Password:
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </label>
                        <button type="submit" className="submit_button">Reset Password</button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default App;
