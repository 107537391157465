import { useEffect, useState } from 'react';
import './MyWishlist.css';
import { useNavigate } from 'react-router-dom';
import { Rating, Stack } from '@mui/material';
import { useStateValue } from '../../../StateProviders';
function MyWishlist() {



    let navigate = useNavigate()
    const [{ ShowLoginPop }, dispatch] = useStateValue()
    const [WishListData, setWishListData] = useState([])



    useEffect(() => {

        var Url = window.API_PREFIX + 'products/wishlist_page'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                { "Token": localStorage.getItem("Token") }
            ),
        }).then(resp => resp.json()).then(data => {

            if (data['status'] === "1") { setWishListData([...data['WishlistData']]) }
            else if (data['status'] === "0") {
                dispatch(
                    { type: "Logout" }
                )
                dispatch(
                    { type: "OpenLogin" }
                )
            }


        }
        )
    }, [])
    return (
        <div className='container mt-2'>

            {/* <div className='d-flex'>
                <div className='mywishlist_path'>My Account</div>
                <div className='mywishlist_path_icon'><i class="fa-solid fa-chevron-right"></i></div>
                <div className='mywishlist_path_details'>My Wishlist</div>
            </div> */}

            <div className='mywishlist_page_form mt-3 mb-3'>
                <div className='mywishlist_page_title'>My Wishlist</div>
            </div>

            {/* <div>{WishListData.length}</div> */}

            <div>
                <div className='mywishlist_laptop_division'>
                    {WishListData.map((eachWishlist, WishlistIndex) => {
                        return (
                            <div className='mywishlist_main_division'>
                                <div className='d-flex justify-content-end'>
                                    <div type="button" class="mywishlist-btn-dalete"
                                        onClick={() => {
                                            var Url = window.API_PREFIX + 'products/delete_wishlist'
                                            fetch(Url, {
                                                method: 'POST',
                                                headers: {
                                                    'Content-type': 'application/json',
                                                },
                                                body: JSON.stringify(
                                                    { "Token": localStorage.getItem("Token"), "ProductId": eachWishlist.ProductId }),
                                            }).then(resp => resp.json()).then(data => {

                                                if (data['status'] === "1") {
                                                    dispatch({
                                                        type: 'REMOVE_FROM_WISHLIST',
                                                        id: eachWishlist.ProductId
                                                    });
                                                    var tmp = WishListData
                                                    tmp.splice(WishlistIndex, 1)
                                                    setWishListData([...tmp])

                                                }
                                            })
                                        }}

                                    ><span></span></div>
                                </div>




                                <div className='mywishlist_mobile_picture'>
                                    <img className='mywishlist_image' src={eachWishlist.ProductImg} />
                                </div>

                                <div className='mywishlist_product_contain'>
                                    <div className='mywishlist_product_name'>{eachWishlist.ProductName}</div>
                                    <div className='mywishlist_product_id'>Product ID: {eachWishlist.ProductId}</div>

                                    {/* <div className=''>₹ {eachWishlist.BuyPrice}</div> */}

                                    <div className='mywishlist_price_details'>
                                        {eachWishlist.IsOffer ?
                                            <div className='d-flex align-items-center'>
                                                <div className='me-1' style={{fontSize:'1.5rem'}}>₹ {eachWishlist.BuyPrice}</div>
                                                <div className='mywishlist_product_price'>₹ {eachWishlist.SalePrice}</div>

                                            </div>
                                            :
                                            <div className='d-flex align-items-center'>
                                                <div className='me-1' style={{fontSize:'1.5rem'}}>₹ {eachWishlist.BuyPrice}</div>
                                                <div className='mywishlist_product_price'>₹ {eachWishlist.SalePrice}</div>

                                            </div>
                                            // <div>
                                            //     <div className='mywishlist_product_price'>₹ {eachWishlist.BuyPrice}</div>
                                            //     <div className='mywishlist_product_price'>₹ {eachWishlist.SalePrice}</div>
                                            // </div>
                                        }

                                        {/* <div className='mywishlist_rating_star'>
                                        <Stack spacing={1}>
                                            <Rating className='mywishlist_star_mobile' name="half-rating-read" defaultValue={eachWishlist['Ratings']} precision={0.5} readOnly />
                                        </Stack>
                                        <div className='mywishlist_reviews'>({eachWishlist.Rating})</div>
                                    </div> */}


                                    </div>

                                    {/* <div className='mywishlist_show_date_calender'>
                                    <div className='mywishlist_calender_icon'><i class="fa-solid fa-calendar"></i></div>
                                    <div className='mywishlist_date'>Added on {eachWishlist.AddedDate}</div>
                                </div> */}
                                </div>



                                <div class="mywishlist-btn-add-to-cart mt-2"
                                    onClick={() => {

                                        var Url = window.API_PREFIX + 'order/update_cart'
                                        fetch(Url, {
                                            method: 'POST',
                                            headers: {
                                                'Content-type': 'application/json',
                                            },
                                            body: JSON.stringify(
                                                { "Token": localStorage.getItem("Token"), "ProductId": eachWishlist.ProductId }),
                                        }).then(resp => resp.json()).then(data => {

                                            if (data['status'] === "1") {

                                                dispatch({
                                                    type: 'ADD_TO_BASKET',
                                                    item: eachWishlist
                                                })

                                                var Url = window.API_PREFIX + 'products/delete_wishlist'
                                                fetch(Url, {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-type': 'application/json',
                                                    },
                                                    body: JSON.stringify(
                                                        { "Token": localStorage.getItem("Token"), "ProductId": eachWishlist.ProductId }),
                                                }).then(resp => resp.json()).then(data => {

                                                    if (data['status'] === "1") {
                                                        dispatch({
                                                            type: 'REMOVE_FROM_WISHLIST',
                                                            id: eachWishlist.ProductId
                                                        });
                                                        var tmp = WishListData
                                                        tmp.splice(WishlistIndex, 1)
                                                        setWishListData([...tmp])

                                                    }
                                                })



                                            }
                                        })
                                    }}

                                >add to cart</div>




                            </div>)
                    })}


                </div>

                {WishListData.length !== 0 ? null :
                    <div className='mywishlist_path_details container'>
                        <div>
                            <span className='wishlist_icon'></span>
                            <div>Uh Oh!</div>
                            <div>Your Wishlist Seems to be Empty!</div>
                            <button type='button' className='btn add_to_cart_btn mt-2' onClick={() => navigate("/")}>Start Shopping</button>
                        </div>
                    </div>}

            </div>


        </div>
    )
}

export default MyWishlist;