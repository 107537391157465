import "./TrashProduct.css";
import { Empty, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import Loader from "../../Loader";
import { DeleteFilled, RollbackOutlined } from "@ant-design/icons";
import toast, { Toaster } from 'react-hot-toast';

const TrashProduct = ({ open, setOpen, isRestored, setIsRestored }) => {
  const [AllProductData, setAllProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const columns = [
    {
        title: 'Productid',
        dataIndex: 'ProductGroupId',
        render(text, record, index) {
            return {
                props: {
                    style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
                },
                children: <div style={{ fontWeight: '600' }}  >{record.Index}</div>
            }
        }
    },
    {
        title: 'Name',
        dataIndex: 'Product_Name',
        render(text) {
            return {
                props: {
                    style: { fontSize: "16px", textAlign: "center" }
                },
                children: <div
                    //  style={{ width: "150px" }}
                    style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '150px' }}
                >{text}</div>
            }
        }
    },
    {
        title: 'Categories',
        dataIndex: 'Categories',
        render(text) {
            return {
                props: {
                    style: { fontSize: "16px", textAlign: "center" }
                },
                children: <div>{text}</div>
            }
        }
    },
    {
        title: 'SubCategories',
        dataIndex: 'SubCategories',
        render(text) {
            return {
                props: {
                    style: { fontSize: "16px", textAlign: "center" }
                },
                children: <div>{text}</div>
            }
        }
    },
    {
        title: 'total quantity',
        dataIndex: 'total_quantity',
        render(text) {
            return {
                props: {
                    style: { fontSize: "16px", textAlign: "center" }
                },
                children: <div>1</div>
            }
        }
    },
    {
        title: 'Date',
        dataIndex: 'Date_Time',
        render(text) {
            return {
                props: {
                    style: { fontSize: "16px", textAlign: "center" }
                },
                children: <div style={{ width: "max-content" }}>{text}</div>
            }
        }
    },
    {
      title: "Restore",
      dataIndex: "Restore",
      render: (text, record, index) => (
        <>
          <RollbackOutlined
            style={{
              fontSize: "18px",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              var Url = window.API_PREFIX + "admin/untrash_product";

              fetch(Url, {
                method: "POST",
                headers: {
                  "Content-type": "application/json",
                },
                body: JSON.stringify({
                  Token: localStorage.getItem("Token"),
                  VariantId: record.ProductGroupId,
                }),
              })
                .then((resp) => resp.json())
                .then((Revdata) => {
                  if (Revdata["status"] === "1") {
                    var tmpOrder = AllProductData;
                    tmpOrder.splice(index, 1);
                    setAllProductData([...tmpOrder]);
                    setIsRestored(!isRestored);
                    toast.success('Restored Successfully')
                  }
                });
            }}
          />
        </>
      ),
    },
    {
      title: 'Delete',
      dataIndex: 'Delete',
      render: (text, record, index) => <><DeleteFilled style={{ color: "#e11f48", fontSize: "18px", display: "flex", justifyContent: "center", cursor: 'pointer' }}

          onClick={() => {
              var Url = window.API_PREFIX + 'admin/del_product'

              fetch(Url, {
                  method: 'POST',
                  headers: {
                      'Content-type': 'application/json',
                  },
                  body: JSON.stringify(
                      {
                          "Token": localStorage.getItem("Token"),
                          "VariantId": record.ProductGroupId,

                      }
                  ),
              }).then(resp => resp.json()).then(Revdata => {
                  console.log(Revdata)
                  if (Revdata['status'] === "1") {
                      var tmpProduct = AllProductData
                      tmpProduct.splice(index, 1)
                      setAllProductData([...tmpProduct])
                      toast.success('Deleted Successfully')

                  }
              })
          }}

      /></>

  },
  
];



  const getTrashedOrderData = () => {
    var Url = window.API_PREFIX + "admin/alltrash_product";
    setLoading(true);
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("Token"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data["status"] === "1") {
          setAllProductData(data["products"]);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    getTrashedOrderData();
  }, []);

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        title="Deleted Products"
        className="TrashedOrder_Modal"
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={"85vw"}
      >
        <Table
          className="all_products_tables"
          columns={columns}
          dataSource={AllProductData}
          locale={{
            emptyText: loading ? <Loader /> : <Empty />,
          }}
        />
      </Modal>
    </div>
  );
};

export default TrashProduct;
