import { useEffect, useState } from 'react';
import './UserProfile.css';

import { useNavigate } from 'react-router-dom';
// import MobileImage from '../../images/download (3).png'


import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

// import OtpInput from "react-otp-input";
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import '../UserLogin/LoginPage.css';
import Popup from "reactjs-popup";
import { useStateValue } from '../../../StateProviders';
import OtpInput from "react-otp-input";


const Numberstyle = {
    border: '1px solid black',
    boxshadow: "0 0 20px rgba(255, 255, 255, 0.4)",
    borderradius: "5px",
    padding: "30px 10px",
    width: "auto",
    height: "max-content",
    // margin: "160px 450px"
}

function UserProfile() {
    const navigate = useNavigate()
    const [{ ShowLoginPop }, dispatch] = useStateValue()
    const [FirstName, setFirstName] = useState("")
    const [MiddleName, setMiddleName] = useState("")
    const [LastName, setLastName] = useState("")
    const [MobileNumber, setMobileNumber] = useState("")
    const [Email, setEmail] = useState("")
    const [DateOfBirth, setDateOfBirth] = useState("")
    const [gender, setgender] = useState("")
    const [DateOfAnniversary, setDateOfAnniversary] = useState("")
    const [showtitleitem, setshowtitleitem] = useState(false)
    const [titlename, settitlename] = useState("Mr")
    // const [titlelist] = useState(["Mr", "Mrs", "Miss"])

    // const[titlelist, settitlelist] = useState("");



    const [onlyEmail, setonlyEmail] = useState("")
    const [onlyNumber, setonlyNumber] = useState("")

    // Show Alerts
    const [showotppop, setshowotppop] = useState(true)
    const [sendOTPloading, setsendOTPloading] = useState(false)
    const [OTPauthwrong, setOTPauthwrong] = useState(false)
    const [otpTimer, setotpTimer] = useState(0)
    const [EmailExistError, setEmailExistError] = useState(false)
    const [Apiotp, setApiotp] = useState("")
    const [Userotp, setUserotp] = useState(0)
    const [closepop, setclosepop] = useState(false)



    const [Nshowotppop, setNshowotppop] = useState(true)
    const [NsendOTPloading, setNsendOTPloading] = useState(false)
    const [NOTPauthwrong, setNOTPauthwrong] = useState(false)
    const [NotpTimer, setNotpTimer] = useState(0)
    const [NumExistError, setNumExistError] = useState(false)
    const [NApiotp, setNApiotp] = useState("")
    const [NUserotp, setNUserotp] = useState(0)
    const [closeNpop, setcloseNpop] = useState(false)



    useEffect(() => {
        var Url = window.API_PREFIX + 'user/profile'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ "Token": localStorage.getItem("Token") }),
        }).then(resp => resp.json()).then(data => {

            if (data['status'] === "1") {
                settitlename(data['UserDetail']['Title'])
                setDateOfBirth(data['UserDetail']['DOb'])
                setDateOfAnniversary(data['UserDetail']['DateOfAnniversary'])
                setFirstName(data['UserDetail']['FirstName'])
                setMiddleName(data['UserDetail']['MiddleName'])
                setLastName(data['UserDetail']['LastName'])
                setEmail(data['UserDetail']['Email'])
                setgender(data['UserDetail']['Gender'])
                setMobileNumber(data['UserDetail']['Phone_Num'])



            }
            else {
                dispatch({ type: "Logout" })

                navigate('/')
            }
        }
        )
    }, [])

    function Userregister(onlyEmail) {
        var Url = window.API_PREFIX + 'user/checkOTP'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ "Data": onlyNumber,
                "Data":MobileNumber ,
                OTP:Userotp
             }),
        }).then(resp => resp.json()).then(data => {
            if (data['status'] == "1") {
            setshowotppop(true)
            var Url = window.API_PREFIX + 'user/verify_email'
            fetch(Url, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({ "Token": localStorage.getItem('Token'), "Email": onlyEmail }),
            }).then(resp => resp.json()).then(data => {

                if (data['status'] == "1") {
                    setshowotppop(true)
                    setOTPauthwrong(false)
                    setsendOTPloading(false)
                    setEmail(onlyEmail)
                    setclosepop(true)

                }

                else {
                    alert("SomeThing Went wrong")

                    // setAlertData({ 'Type': 'error', 'Message': 'Email Id Already exist' })
                    // setShowAlert(true)
                    // setOnspinner(false)
                }
            })


        }
        else {
            setOTPauthwrong(true)
        }
    })
    }

    function NUserregister(onlyNumber) {

        var Url = window.API_PREFIX + 'user/checkOTP'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ "Data": onlyNumber,
                "Number_change":true,
                "oldNum":MobileNumber ,
                OTP:NUserotp
             }),
        }).then(resp => resp.json()).then(data => {
            if (data['status'] == "1") {

            setshowotppop(true)
            var Url = window.API_PREFIX + 'user/verify_mobile'
            fetch(Url, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({ "Token": localStorage.getItem('Token'), "Phone_num": onlyNumber }),
            }).then(resp => resp.json()).then(data => {

                if (data['status'] == "1") {
                    setNshowotppop(true)
                    setNOTPauthwrong(false)
                    setNsendOTPloading(false)
                    setMobileNumber(onlyNumber)
                    setcloseNpop(true)






                }

                else {
                    alert("SomeThing Went wrong")

                    // setAlertData({ 'Type': 'error', 'Message': 'Email Id Already exist' })
                    // setShowAlert(true)
                    // setOnspinner(false)
                }
            })


        }
        else {
            setNOTPauthwrong(true)
        }
    })
    }

    function send_verification_code(email_to_send_otp) {
        setsendOTPloading(true)


        var Url = window.API_PREFIX + 'user/change_email_web'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ "Token": localStorage.getItem('Token'), "Email": email_to_send_otp }),
        }).then(resp => resp.json()).then(data => {

            if (data['status'] === "1") {
                setsendOTPloading(false)
                setotpTimer(30)
                setshowotppop(false)
                setOTPauthwrong(false)
                setUserotp(0)
                setApiotp(data['OTP'])
                setotpTimer(30)



            }
            else if (data['status'] === "0") {
                setEmailExistError(true)
            }
            else {

            }

        })
    }


    function Nsend_verification_code(onlyNumber) {
        setNsendOTPloading(true)


        var Url = window.API_PREFIX + 'user/change_mobilenum_web'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ "Token": localStorage.getItem('Token'), "Phone_num": onlyNumber }),
        }).then(resp => resp.json()).then(data => {

            if (data['status'] === "1") {
                setNsendOTPloading(false)
                setNotpTimer(30)
                setNshowotppop(false)
                setNOTPauthwrong(false)
                setNUserotp(0)
                setNApiotp(data['OTP'])




            }
            else if (data['status'] === "0") {
                setNumExistError(true)
            }
            else {

            }

        })
    }

    useEffect(() => {
        const timer = otpTimer > 0 && setInterval(() => setotpTimer(otpTimer - 1), 1000);
        return () => clearInterval(timer);
    }, [otpTimer])

    useEffect(() => {
        const timer1 = NotpTimer > 0 && setInterval(() => setNotpTimer(NotpTimer - 1), 1000);
        return () => clearInterval(timer1);
    }, [NotpTimer])
    return (
        <div className='container mt-2'>
            <div className='profile_page'>
                <div className='profile_path'>My Account</div>
                <div className='profile_path_icon'><i class="fa-solid fa-chevron-right"></i></div>
                <div className='profile_path_details'>My Profile Page</div>
            </div>

            <div className='profile_extra_division'>
                <div className='profile_second_extra_division'>
                    <div className='profile_page_form'>
                        <div className='profile_page_title'>My Profile Page</div>

                        <form class="row g-3">
                            <div class="col-md-6 profile_page_division_col">
                                <label class="profile-form-label">Title</label>
                                <select class="form-select form-select-lg profile_name_cat_dropdown" aria-label="Large select example"
                                    value={titlename}
                                    onChange={(e) => {
                                        settitlename(e.target.value)
                                    }}>
                                    <option selected>Mr</option>
                                    <option value="1">Mrs</option>
                                    <option value="2">Miss</option>
                                </select>
                            </div>

                            <div class="col-md-6 profile_page_division_col">
                                <label class="profile-form-label">First Name</label>
                                <input type="email" class="form-control userprofileformControl" id="exampleFormControlInput1" placeholder="Enter First Name" value={FirstName}
                                    onChange={(e) => {
                                        setFirstName(e.target.value)

                                    }} />

                            </div>

                            <div class="col-md-6 profile_page_division_col">
                                <label class="profile-form-label">Middle Name</label>
                                <input type="email" class="form-control userprofileformControl" placeholder="Enter Middle Name" value={MiddleName}
                                    onChange={(e) => {
                                        setMiddleName(e.target.value)

                                    }}
                                >
                                </input>
                            </div>
                            <div class="col-md-6 profile_page_division_col" >
                                <label class="profile-form-label">Last Name</label>
                                <input type="email" class="form-control userprofileformControl" placeholder="Enter last Name" value={LastName}
                                    onChange={(e) => {
                                        setLastName(e.target.value)

                                    }}
                                ></input>
                            </div>

                            <div className='profile_radio_button_division'>
                                <div class="form-check profile_radio_button form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" value="option1" checked={gender === "Female" ? true : false}
                                        onClick={() => {
                                            setgender("Female")

                                        }}
                                    ></input>
                                    <label class="form-check-label">Female</label>
                                </div>
                                <div class="form-check profile_radio_button form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" value="option2" checked={gender === "Male" ? true : false}
                                        onClick={() => {
                                            setgender("Male")


                                        }}
                                    ></input>
                                    <label class="form-check-label">Male</label>
                                </div>
                                <div class="form-check profile_radio_button form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" value="option3" checked={gender === "Other" ? true : false}
                                        onClick={() => {
                                            setgender("Other")
                                        }}
                                    ></input>
                                    <label class="form-check-label">Other</label>
                                </div>

                            </div>

                            <div class="col-md-6 profile_page_division_col">
                                <label class="profile-form-label">Mobile Number *</label>
                                <div class="profile-add-right">
                                    <span class="profile-input-group-addon"> + 91 </span>


                                    <div className='profile_email_popup_division'>


                                        <Popup
                                            trigger={

                                                // <i className="fa-solid fa-user header_user_icon"></i>
                                                <button type="button" class="profile-input-group-right"><i class="fa-solid fa-pencil"></i></button>

                                            }
                                            modal
                                            contentStyle={Numberstyle}
                                        >
                                            {close => (<>
                                                <div className='profile_email_popup_division'><i class="popup_cancel fa-solid fa-xmark"
                                                    onClick={close}
                                                ></i></div>
                                                <div className='profile_change_number'>
                                                    <div class="col-md-12">
                                                        <label class="profile-new-change-number-form-label">New Mobile Number</label>
                                                        <div class="profile-number-add-right">
                                                            <span class="profile-number-input-group-addon"> + 91 </span>
                                                            <input type="tel" class="userprofileeditnumberformControl " placeholder="Enter your Mobile Number" maxlength="10"
                                                                onChange={(e) => {
                                                                    setMobileNumber(e.target.value)

                                                                }}
                                                            ></input>
                                                            <div className='profile_error'>Mobile No is required</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='profile_send_otp_button'>
                                                    <button type="button" class="profile-send-otp">SEND OTP</button>
                                                </div></>
                                            )}
                                        </Popup>


                                    </div>
                                    <Popup
                                        trigger={

                                            // <i className="fa-solid fa-user header_user_icon"></i>
                                            <button type="button" class="profile-input-group-right"><i class="fa-solid fa-pencil"></i></button>

                                        }
                                        modal
                                        // open={true}
                                        contentStyle={Numberstyle}
                                    >
                                        {close => (<>{
                                            Nshowotppop ? <>
                                                <div><i class="popup_cancel fa-solid fa-xmark"
                                                    onClick={close}
                                                ></i></div>
                                                <div className='profile_change_number'>
                                                    <div class="col-md-12 profile_page_division_col">
                                                        <label class="profile-new-change-number-form-label">New Mobile Number</label>
                                                        <div class="profile-number-add-right">


                                                            <span class="profile-number-input-group-addon"> + 91 </span>
                                                            <input type="tel" class="form-control userprofileeditnumberformControl " placeholder="Enter your Mobile Number" maxlength="10"

                                                                onChange={(e) => {
                                                                    setonlyNumber(e.target.value)
                                                                }}


                                                                onKeyDown={(e) => {
                                                                    if (e.key == "Enter") {

                                                                        Nsend_verification_code(onlyNumber)
                                                                        setNsendOTPloading(true)

                                                                    }
                                                                }}


                                                            ></input>
                                                            {onlyNumber.length === 0
                                                                ? <div className='profile_error'>Number is required</div> : null}
                                                            {NumExistError ? <div className='profile_error'>Number already used by other</div> : null}

                                                            {sendOTPloading ? <div><CircularProgress color="success" className='spinner' /></div> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='profile_send_otp_button'>
                                                    <button type="button" class="profile-send-otp"
                                                        onClick={(e) => {
                                                            Nsend_verification_code(onlyNumber)
                                                            setNsendOTPloading(true)
                                                        }}
                                                    >SEND OTP</button>
                                                </div></>
                                                :

                                                <>

                                                    <button type="button" class="btn-close btn-close-white" aria-label="Close"
                                                        onClick={() => {
                                                            setNApiotp(0)
                                                            setNshowotppop(true)
                                                            //    close()



                                                        }

                                                        }
                                                    ></button>

                                                    <div className='otpverify_title'>VERIFY WITH OTP</div>
                                                    <div className='otpverify_subtitle'>sent to {onlyNumber}</div>
                                                    {NOTPauthwrong ? <Alert severity="error">Incorrect OTP</Alert> : null}

                                                    {NsendOTPloading ? <div><CircularProgress color="success" className='spinner' /></div> : null}

                                                    <OtpInput
                                                        separator={
                                                            <span>
                                                                <strong>.</strong>
                                                            </span>
                                                        }
                                                        onChange={otp => {
                                                            setNUserotp(otp)
                                                            setNOTPauthwrong(false)
                                                        }
                                                        }
                                                        // onKeyDown={(e) => {
                                                        //     if (e.key == "Enter") {
                                                        //     if(Userotp.length==4)
                                                        //     {

                                                        //         Userregister();
                                                        //     }
                                                        // }}}

                                                        value={NUserotp}

                                                        numInputs={4}
                                                        className="otp_field_section"
                                                        inputStyle={{
                                                            height: "3.2rem",
                                                            // margin:"60px 0px 0px 20px",
                                                            width: "75%",
                                                            borderRadius: "10px",
                                                            textalign: "center",
                                                            fontweight: "700",
                                                            backgroundColor: "black",
                                                            border: "1px solid white",
                                                            fontsize: "22px",
                                                            color: "white",
                                                            display: "flex",
                                                            justifycontent: "center",
                                                        }}
                                                    />


                                                    <div className='otpverify_time'>Resend OTP in: 00:{NotpTimer} sec</div>
                                                    {NotpTimer === 0 ? <div className='otpverify_resend_otp'>
                                                        <div className='otpverify_receive_otp'>Didn't Receive Your OTP?</div>
                                                        <div className='resend_otp'
                                                            onClick={() => {
                                                                Nsend_verification_code(onlyNumber)
                                                            }}
                                                        >Resend OTP</div>
                                                    </div> : null}
                                                    <div className='loginpage_continue_button'>
                                                        <button type="button" className={NUserotp.length == 4 ? "loginpage_continue_selected" : "loginpage_continue"}
                                                            onClick={() => {
                                                                if (NUserotp.length == 4) {

                                                                    NUserregister(onlyNumber)
                                                                    if (closeNpop) { close() }
                                                                }
                                                            }}
                                                        >Submit OTP</button>
                                                    </div>
                                                </>
                                        }</>

                                        )}




                                    </Popup>



                                    <input type="tel" class=" form-control userprofilenumberformControl " placeholder={MobileNumber != 0 ? MobileNumber : "Enter your Mobile Number"} name="usrPrfMobile" maxlength="10"
                                        disabled
                                    ></input>
                                </div>
                            </div>

                            <div class="col-md-6 profile_page_division_col">
                                <label class="profile-form-label">Email Id *</label>
                                <div class="profile-add-right">
                                    <Popup
                                        trigger={

                                            // <i className="fa-solid fa-user header_user_icon"></i>
                                            <button type="button" class="profile-input-group-right"><i class="fa-solid fa-pencil"></i></button>

                                        }
                                        modal
                                        contentStyle={Numberstyle}
                                    >
                                        {close => (<>{
                                            showotppop ? <>
                                                <div><i class="popup_cancel fa-solid fa-xmark"
                                                    onClick={close}
                                                ></i></div>
                                                <div className='profile_change_number'>
                                                    <div class="col-md-12 profile_page_division_col">
                                                        <label class="profile-new-change-number-form-label">New Email Address</label>
                                                        <div class="profile-number-add-right">

                                                            <input type="email" class="form-control userprofileeditemailformControl" placeholder="Enter your Email"
                                                                onChange={(e) => {
                                                                    setonlyEmail(e.target.value)
                                                                }}


                                                                onKeyDown={(e) => {
                                                                    if (e.key == "Enter") {

                                                                        send_verification_code(onlyEmail)
                                                                        setsendOTPloading(true)

                                                                    }
                                                                }}


                                                            ></input>
                                                            {onlyEmail.length === 0
                                                                ? <div className='profile_error'>Email is required</div> : null}
                                                            {EmailExistError ? <div className='profile_error'>Email already used by other</div> : null}

                                                            {sendOTPloading ? <div><CircularProgress color="success" className='spinner' /></div> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='profile_send_otp_button'>
                                                    <button type="button" class="profile-send-otp"
                                                        onClick={(e) => {
                                                            send_verification_code(onlyEmail)
                                                            setsendOTPloading(true)






                                                        }}
                                                    >SEND OTP</button>
                                                </div></>
                                                :

                                                <>

                                                    <button type="button" class="btn-close btn-close-white" aria-label="Close"
                                                        onClick={() => {
                                                            setApiotp(0)
                                                            setshowotppop(true)
                                                            //    close()



                                                        }

                                                        }
                                                    ></button>

                                                    <div className='otpverify_title'>VERIFY WITH OTP</div>
                                                    <div className='otpverify_subtitle'>sent to {onlyEmail}</div>
                                                    {OTPauthwrong ? <Alert severity="error">Incorrect OTP</Alert> : null}

                                                    {sendOTPloading ? <div><CircularProgress color="success" className='spinner' /></div> : null}

                                                    <OtpInput
                                                        separator={
                                                            <span>
                                                                <strong>.</strong>
                                                            </span>
                                                        }
                                                        onChange={otp => {
                                                            setUserotp(otp)
                                                            setOTPauthwrong(false)
                                                        }
                                                        }
                                                        // onKeyDown={(e) => {
                                                        //     if (e.key == "Enter") {
                                                        //     if(Userotp.length==4)
                                                        //     {

                                                        //         Userregister();
                                                        //     }
                                                        // }}}

                                                        value={Userotp}

                                                        numInputs={4}
                                                        className="otp_field_section form-control"
                                                        inputStyle={{
                                                            height: "3.2rem",
                                                            // margin:"60px 0px 0px 20px",
                                                            width: "75%",
                                                            borderRadius: "10px",
                                                            textalign: "center",
                                                            fontweight: "700",
                                                            backgroundColor: "black",
                                                            border: "1px solid white",
                                                            fontsize: "22px",
                                                            color: "white",
                                                            display: "flex",
                                                            // justifyContent: "center",
                                                        }}
                                                    />


                                                    <div className='otpverify_time'>Resend OTP in: 00:{otpTimer} sec</div>
                                                    {otpTimer === 0 ? <div className='otpverify_resend_otp'>
                                                        <div className='otpverify_receive_otp'>Didn't Receive Your OTP?</div>
                                                        <div className='resend_otp'
                                                            onClick={() => {
                                                                send_verification_code(onlyEmail)
                                                            }}
                                                        >Resend OTP</div>
                                                    </div> : null}
                                                    <div className='loginpage_continue_button'>
                                                        <button type="button" className={Userotp.length == 4 ? "loginpage_continue_selected" : "loginpage_continue"}
                                                            onClick={() => {
                                                                close()
                                                                if (Userotp.length == 4) {

                                                                    Userregister(onlyEmail)

                                                                    if (closepop) { close() }
                                                                }
                                                            }}
                                                        >Submit OTP</button>
                                                    </div>
                                                </>
                                        }</>

                                        )}




                                    </Popup>

                                    <input type="email" class="form-control userprofileformControl " placeholder={Email != 0 ? Email : "Enter Email Id"}
                                        disabled
                                    ></input>
                                </div>
                            </div>

                            <div class="col-md-6 profile_page_division_col">
                                <label class="profile-form-label">Date of Birth</label>
                                <input type="date" class="form-control userprofileformControl" placeholder="DD/MM/YYYY" value={DateOfBirth}
                                    onChange={(e) => {
                                        setDateOfBirth(e.target.value)

                                    }}
                                ></input>
                            </div>

                            <div class="col-md-6 profile_page_division_col">
                                <label class="profile-form-label">Date of Anniversary</label>
                                <input type="date" class="form-control userprofileformControl" placeholder="DD/MM/YYYY" value={DateOfAnniversary}
                                    onChange={(e) => {
                                        setDateOfAnniversary(e.target.value)

                                    }}
                                ></input>
                            </div>

                            <div className='profile-button'>
                                <button type="button" class="profile-btn-discard" onClick={() => { window.history.back();}}>DISCARD CHANGES</button>
                                <button type="button" class="profile-btn-save"
                                    onClick={() => {


                                        var Url = window.API_PREFIX + 'user/edit_profile'
                                        fetch(Url, {
                                            method: 'POST',
                                            headers: {
                                                'Content-type': 'application/json',
                                            },
                                            body: JSON.stringify({
                                                "Token": localStorage.getItem("Token"),
                                                'Title': titlename,
                                                'DOB': DateOfBirth,
                                                'DateOfAnniversary': DateOfAnniversary,
                                                'FirstName': FirstName,
                                                'MiddleName': MiddleName,
                                                'LastName': LastName,
                                                'Gender': gender

                                            }),
                                        }).then(resp => resp.json()).then(data => {

                                            if (data['status'] === "1") {
                                                // navigate("/")
                                                window.history.back();

                                            }
                                        })
                                    }

                                    }
                                >SAVE CHANGES</button>
                            </div>

                        </form>

                        {/* <div className='wishlist_cross_btn'>
                            <div><i class="wishlist_cancel fa-solid fa-xmark"></i></div>
                            <div className='wishlist_msg_icon'>
                                <div><img className='mobile_image' src={MobileImage}/></div>
                                <div className='wishlist_remove_msg'>Product removed from wishlist successfully!</div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>


        </div>
    )
}

export default UserProfile;