
import { Outlet } from "react-router-dom"
import { useStateValue } from "../StateProviders";
import Homepage from "./UserComponent/HomeLayout/Homepage"



export default function Userlayout() {

  const [{ ShowLoginPop }, dispatch2] = useStateValue();




  return (
    <>
      <Homepage />
    </>


  );
}

