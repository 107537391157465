import './ArihantFooter.css';
import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import googleplay from '../../../images/googleplay.png'
import appstore from '../../../images/appstore.png'


function ArihantFooter() {
    const validateEmail = (input) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(emailPattern.test(input));
    };

    let navigate = useNavigate()
    const [Email, setEmail] = useState("")
    const [IsValidEmail, setIsValidEmail] = useState("")
    return (
        <>
            {/* vishwa footer code start */}
            <div className='footer_main_division'>
                <div className='footer_first_main_section row'>
                    <div className='col footer_first_row'>
                        <h2 className='footer_first_title'>
                            <strong>Quick links</strong></h2>
                        <div className='footer_quick_links_items' onClick={() => {
                            navigate()
                        }}>Customer Reviews </div>
                        <div className='footer_quick_links_items' onClick={() => {
                            navigate()
                        }}> Our Blogs </div>
                        <div className='footer_quick_links_items' onClick={() => {
                            navigate()
                        }}> Store Locator </div>
                        <div className='footer_quick_links_items' onClick={() => {
                            navigate()
                        }}>Jewellery Care </div>
                        <div className='footer_quick_links_items' onClick={() => {
                            navigate()
                        }}>Offers & Promotions</div>

                    </div>
                    <div className='col footer_first_row'>
                        <h2 className='footer_first_title'>
                            <strong>Info</strong></h2>
                        <div className='footer_quick_links_items' onClick={() => {
                            navigate('/shipping-policy')
                        }}>Shipping Policy</div>
                        <div className='footer_quick_links_items' onClick={() => {
                            navigate('/privacy-policy')
                        }}>Privacy Policy</div>
                        <div className='footer_quick_links_items' onClick={() => {
                            navigate('/return-and-refund-policy')
                        }}>Return & Refund</div>
                        <div className='footer_quick_links_items' onClick={() => {
                            navigate('/terms-and-conditions')
                        }}>Terms of Service</div>
                    </div>
                    <div className='col footer_first_row'>
                        <h2 className='footer_first_title'>
                            <strong>Category</strong></h2>
                        <div className='footer_quick_links_items' onClick={() => {
                            navigate()
                        }}>Gold</div>
                        <div className='footer_quick_links_items' onClick={() => {
                            navigate()
                        }}>Silver</div>
                        <div className='footer_quick_links_items' onClick={() => {
                            navigate()
                        }}>Platinum</div>
                        <div className='footer_quick_links_items' onClick={() => {
                            navigate()
                        }}>Idol</div>
                    </div>
                    <div className='col footer_first_row'>
                        <h2 className='footer_first_title'>
                            <strong>Contact us</strong></h2>
                        <p className='footer_contact_us'>BIS : CM/L - 7290074016</p>
                        <p className='footer_contact_us'>For any suggestions, queries or complaints please contact us:</p>
                        {/* <p className='footer_contact_us'>Indiejewel Fashions Private Limited</p> */}
                        <p className='footer_contact_us'>MG Road Chokshi Bazar, nr. bus stand, Mandvi, Vadodara, Gujarat 390001</p>
                        {/* <p className='footer_contact_us'>-&nbsp; care@giva.co</p> */}
                        <p className='footer_contact_us'> Time : 10 AM to 6:30 PM</p>
                    </div>
                </div>
                <div className='footer_second_main_section row'>
                    <div className='col-md-6 footer_second_row'>
                        <p className='footer_subscribe_title'> OUR APPLICATION IS AVAILABLE ON </p>
                        <div className="application_link">
                            <a href="">
                                <img src={googleplay} className="google_play"></img>
                            </a>
                            <a href="">
                                <img src={appstore} className="app_store"></img>
                            </a>
                        </div>
                        <div class='keep_in_touch'> FIND US ON </div>
                        <div className='footer_social_icon_division'>
                            <div className='footer_social_icon'><a href='#' target="_blank"><i class="fa-brands fa-facebook-f"></i></a></div>
                            <div className='footer_social_icon_instagram'><a href='https://www.instagram.com/pankajjewellers1995/?igsh=MWg0N2Exdjc1emJnZQ%3D%3D' target="_blank"><i class="fa-brands fa-instagram"></i></a></div>
                            <div className='footer_social_icon'><a href='#'><i class="fa-brands fa-linkedin"></i></a></div>
                        </div>
                    </div>
                    <div className='col-md-6 footer_second_row'>

                        <div className='footer_subscribe_title'>Subscribe for exclusive offers and updates!</div>

                        <div className="email_footer">
                            <input type="email" class="form-control input_div" id="exampleFormControlInput1" placeholder="Email"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    validateEmail(e.target.value);
                                }}
                                onKeyDown={(e) => {

                                    if (e.key === "Enter") {
                                        if (IsValidEmail) {

                                            var Url = window.API_PREFIX + 'admin/newsletter'
                                            fetch(Url, {
                                                method: 'POST',
                                                headers: {
                                                    'Content-type': 'application/json',
                                                },
                                                body: JSON.stringify(
                                                    {
                                                        "mail": Email,

                                                    }),

                                            }).then(resp => resp.json()).then(data => {
                                                console.log(data)
                                                if (data['status'] === "1") {
                                                    alert("Thank you for subscribing our Newsletter.")
                                                }
                                                else {
                                                    alert("Email Id is already subscribe for Newsletter")
                                                }


                                            }
                                            )
                                        }
                                        else {
                                            alert("Please enter valid email")
                                        }
                                    }
                                }} />
                            <button type="button" class="btn button_right"
                                onClick={() => {
                                    if (IsValidEmail) {
                                        var Url = window.API_PREFIX + 'admin/newsletter'
                                        fetch(Url, {
                                            method: 'POST',
                                            headers: {
                                                'Content-type': 'application/json',
                                            },
                                            body: JSON.stringify(
                                                {
                                                    "mail": Email,

                                                }),

                                        }).then(resp => resp.json()).then(data => {
                                            console.log(data)
                                            if (data['status'] === "1") {
                                                alert("Thank you for subscribing our Newsletter.")
                                            }
                                            else {
                                                alert("Email Id is already subscribe for Newsletter")

                                            }



                                        }
                                        )
                                    }
                                    else {
                                        alert("Please enter valid email")
                                    }
                                }}><svg
                                    fill="white" className="right_mail" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg></button>
                        </div>

                    </div>
                </div>

            </div> 
             <div className='footer_copyright_division'>
                <div className='footer_copyright'>COPYRIGHT <i class="fa-regular fa-copyright"></i> 2024 PANKAJ JEWELLERS |<a href='https://www.kodenauts.com/' className='footer_developedby_link' target='blank'> DEVELOPED BY KodeNauts</a></div>
            </div>
            

            {/* vishwa footer code end */}


        </>
    )
}

export default ArihantFooter;