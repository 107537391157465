
import { useState, useEffect } from 'react';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import './LoginPage.css';
import Popup from "reactjs-popup";
import { useStateValue } from "../../../StateProviders";
import OtpInput from "react-otp-input";
import { useNavigate } from 'react-router-dom';

const LoginStyle = {
    backgroundColor: "white",
    boxshadow: "0 0 20px rgba(255, 255, 255, 0.4)",
    borderradius: "5px",
    padding: "30px 70px",
    width: "auto",
    height: "max-content"
};


function LoginPage() {

    let navigate = useNavigate()

    const [{ ShowLoginPop }, dispatch2] = useStateValue();
    const [{ CloseLogin }, dispatch3] = useStateValue();

    const [EmailNumber, setEmailNumber] = useState("")
    const [onlyNumber, setonlyNumber] = useState("")


    const [Apiotp, setApiotp] = useState("")
    const [Userotp, setUserotp] = useState(0)

    const [showotppop, setshowotppop] = useState(true)
    const [senderemailnumber, setsenderemailnumber] = useState("")
    const [EnterOnlyNumber, setEnterOnlyNumber] = useState(false)
    const [Errormsg, setErrormsg] = useState("")

    const [isChecked, setIsChecked] = useState(true);
    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };


    const [sendOTPloading, setsendOTPloading] = useState(false)
    const [OTPauthwrong, setOTPauthwrong] = useState(false)

    function Userregister(EmailNumber) {


        var Url = window.API_PREFIX + 'user/checkOTP'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ "Data": EmailNumber,
                OTP:Userotp
             }),
        }).then(resp => resp.json()).then(data => {
            if (data['status'] == "1") {

      setshowotppop(true)
            var Url = window.API_PREFIX + 'user/register_user'
            fetch(Url, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({ "Data": EmailNumber }),
            }).then(resp => resp.json()).then(data => {

                if (data['status'] == "1") {
                    dispatch2({
                        type: 'Login',

                    })
                    localStorage.setItem('Token', data['Token'])
                    setshowotppop(true)
                    setOTPauthwrong(false)
                    setsendOTPloading(false)
                    dispatch3({
                        type: 'CloseLogin',

                    })
                    setEnterOnlyNumber(false)
                    window.location.reload()
                }
                else {
                    alert("SomeThing Went wrong")
                }
            })
            }
            else
            {
                alert(data['message'])
            setOTPauthwrong(true)

            }

      
        


    })}






    function send_verification_code(email_to_send_otp) {
        setsendOTPloading(true)
        if (!EnterOnlyNumber) {

            var Url = window.API_PREFIX + 'user/issue_otp_web'
            fetch(Url, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({ "Data": email_to_send_otp }),
            }).then(resp => resp.json()).then(data => {


                if (data['status'] == "1") {
                    setshowotppop(false)
                    setOTPauthwrong(false)
                    setsendOTPloading(false)
                    setUserotp(0)
                    // setApiotp(data['OTP'])
                    setotpTimer(30)
                    setsenderemailnumber(data["Data"])
                }
                else if (data['status'] == "2") {
                    setsenderemailnumber(data['Data'])
                    setshowotppop(false)
                    setOTPauthwrong(false)
                    setsendOTPloading(false)
                    setUserotp(0)
                    // setApiotp(data['OTP'])
                    setotpTimer(30)
                }
                else {
                    setErrormsg(data["Data"])
                    setEnterOnlyNumber(true)
                    setsendOTPloading(false)
                }
            })
        }
        else {
            var Url = window.API_PREFIX + 'user/issueotp_onlymobile_web'
            fetch(Url, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({ "Number": email_to_send_otp }),
            }).then(resp => resp.json()).then(data => {

                if (data['status'] == "1") {
                    setshowotppop(false)
                    setOTPauthwrong(false)
                    setsendOTPloading(false)
                    setUserotp(0)
                    // setApiotp(data['OTP'])
                    setotpTimer(30)
                    setsenderemailnumber(data["Data"])
                }
                else if (data['status'] == "2") {
                    setshowotppop(false)
                    setOTPauthwrong(false)
                    setsendOTPloading(false)
                    setUserotp(0)
                    // setApiotp(data['OTP'])
                    setotpTimer(30)
                    setsenderemailnumber(data['Data'])
                }


                else {
                    alert("SomeThing went wrong")
                }
            })

        }
    }


    const [otpTimer, setotpTimer] = useState(30);
    useEffect(() => {
        const timer = otpTimer > 0 && setInterval(() => setotpTimer(otpTimer - 1), 1000);
        return () => clearInterval(timer);
    }, [otpTimer])





    return (



        <Popup

            modal
            className='login_style_popup_mobile'
            contentStyle={LoginStyle}
            open={CloseLogin}
            closeOnDocumentClick={false}
            onClose={() => {
                dispatch3({
                    type: 'CloseLogin',

                })

            }}
        >
            <div>
                {showotppop ?
                    <>
                        <div>
                            <i class="loginpage_cancel fa-solid fa-xmark fa-xl mb-3"
                                onClick={
                                    () => {

                                        dispatch3({
                                            type: 'CloseLogin'
                                        })
                                    }
                                }
                            ></i>
                        </div>
                        <div className='loginpage_login_account_field'>
                            <div className='loginpage_login'>Signin</div>
                            <div className='loginpage_OR'>OR</div>
                            <div className='loginpage_create_account'>Signup</div>
                        </div>


                        {EnterOnlyNumber ? <>
                            <Alert severity="error">{Errormsg}</Alert>
                            <div className='loginpage_email_number_title'>Please Enter your Phone number</div>
                            <div class="loginpage_email_number_field" >
                                <input type="text" id="onlyphone" name="onlyphone" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" maxlength="10" class="loginpageformControl form-control" placeholder="Enter your Phone number" value={onlyNumber}
                                    onChange={(e) => {
                                        setonlyNumber(e.target.value)

                                    }}
                                    onKeyDown={(e) => {
                                        if (onlyNumber.length !== 0) {
                                            if (e.key == "Enter") {

                                                send_verification_code(onlyNumber)
                                                setsendOTPloading(true)
                                            }
                                        }
                                    }}
                                ></input>
                            </div></>
                            : <><div className='loginpage_email_number_title'>Please enter your Phone number</div>
                                <div class="loginpage_email_number_field" >

                                    <input
                                    className='nineonefield'
                                        type="text"
                                        value="+91"
                                        readOnly
                                        style={{
                                            marginRight: '5px',
                                            padding: '5px',
                                            fontSize: '16px',
                                            border:'none',
                                            width:'20%',
                                            textAlign:'center'
                                        }}
                                    />

                                    
                                    <input type="text" class="loginpageformControl form-control" placeholder="Enter your Phone number"
                                        maxLength={10}
                                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"

                                        onChange={(e) => {
                                            setEmailNumber(e.target.value)

                                        }}
                                        onKeyDown={(e) => {
                                            if (EmailNumber.length !== 0) {
                                                if (e.key == "Enter") {

                                                    send_verification_code(EmailNumber)
                                                }
                                            }
                                        }}
                                    ></input>
                                </div></>}




                        <div className='loginpage_terms_privacy'>
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={isChecked} onChange={handleCheckboxChange} />
                            <div className='loginpage_agree'>By continuing you agree to our </div>
                            <div className='d-flex justify-content-center'>
                                <div className='loginpage_terms' onClick={() => {
                                    navigate('/terms-and-conditions')
                                }} >Terms of Use </div>
                                <div className='loginpage_and'> &</div>
                                <div className='loginpage_privacy' onClick={() => {
                                    navigate('/privacy-policy')
                                }}>Privacy Policy</div>
                            </div>
                        </div>

                        {sendOTPloading ? <div className='loginpage_spinner_loader'><CircularProgress color="success" className='spinner' /></div> : null}
                        {EnterOnlyNumber ? <div className='loginpage_continue_button'>
                            <button type="button " className={onlyNumber.length === 10 ? "loginpage_continue_selected btn" : "loginpage_continue btn"}
                                onClick={(e) => {
                                    if (onlyNumber.length !== 0) {

                                        send_verification_code(onlyNumber)

                                    }


                                }}
                                disabled={!isChecked}
                            >Continue</button>


                        </div> : <div className='loginpage_continue_button'>
                            <button type="button " className={EmailNumber.length > 0 ? "loginpage_continue_selected btn" : "loginpage_continue btn"}
                                onClick={(e) => {
                                    if (EmailNumber.length !== 0) {
                                        send_verification_code(EmailNumber)
                                    }


                                }}
                                disabled={!isChecked}
                            >Continue</button>


                        </div>}</> : <>

                        <div>
                            <i class="loginpage_cancel fa-solid fa-xmark fa-xl mb-3"
                                onClick={
                                    () => {

                                        dispatch3({
                                            type: 'CloseLogin'
                                        })
                                    }
                                }
                            ></i>
                        </div>

                        <div className='otpverify_title'>VERIFY WITH OTP</div>
                        <div className='otpverify_subtitle'>sent to {senderemailnumber}</div>
                        {OTPauthwrong ? <Alert severity="error">Incorrect OTP</Alert> : null}

                        {sendOTPloading ? <div><CircularProgress color="success" className='spinner' /></div> : null}

                        <OtpInput
                            separator={
                                <span>
                                    {/* <strong>.</strong> */}
                                </span>
                            }
                            onChange={otp => {
                                setUserotp(otp)
                                setOTPauthwrong(false)
                            }
                            }


                            value={Userotp}

                            numInputs={4}
                            className="otp_field_section"
                            inputStyle={{
                                height: "3.2rem",
                                width: "50%",
                                borderRadius: "10px",
                                textalign: "center",
                                fontweight: "700",
                                border: "1px solid black",
                                fontsize: "22px",
                                color: "black",
                                display: "flex",
                                justifycontent: "end",
                            }}
                        />


                        <div className='otpverify_time'>Resend OTP in: 00:{otpTimer} sec</div>
                        {otpTimer === 0 ? <div className='otpverify_resend_otp'>
                            <div className='otpverify_receive_otp'>Didn't Receive Your OTP?</div>
                            <div className='resend_otp'
                                onClick={() => {
                                    EnterOnlyNumber ? send_verification_code(onlyNumber) : send_verification_code(EmailNumber)
                                }}
                            >Resend OTP</div>
                        </div> : null}
                        <div className='loginpage_continue_button'>
                            <button type="button " className={Userotp.length == 4 ? "loginpage_continue_selected btn" : "loginpage_continue btn"}
                                onClick={() => {
                                    if (Userotp.length == 4) {

                                        EnterOnlyNumber ? Userregister(onlyNumber) : Userregister(EmailNumber);
                                    }
                                }}
                            >Submit OTP</button>
                        </div>


                    </>
                }
            </div>
        </Popup>


    )
}

export default LoginPage;