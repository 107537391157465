import React, { useState, useEffect } from 'react';
import './Addnotifications.css'
import { Transfer } from 'antd';
import Loader from "../../Loader";
import { Empty } from "antd";
import { Table, Collapse } from 'antd';
import toast from 'react-hot-toast';

const { Panel } = Collapse;

function Addnotifications() {
    const [loading, setloading] = useState(false)

    const [title, setTitle] = useState("")
    const [textInput, setTextInput] = useState('');
    const [isAllUser, setIsAllUser] = useState(false)
    const token = localStorage.getItem("Token")

    const handleInputChange = (event) => {
        setTextInput(event.target.value);
    };

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    }


    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);

    const handleCheckboxChange = (event) => {
        setIsAllUser(event.target.checked);
    };

    const handleFilter = (inputValue, option) => {
        return option.Name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
    }

    const handleChange = (newTargetKeys) => {
        setSelectedUser(newTargetKeys);
    };

    function getNotifications() {

        var Url = window.API_PREFIX + "admin/view_general_notification";
        fetch(Url, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                "Token": token,
            }),
        })
            .then((resp) => resp.json())
            .then((data) => {
                setAllnotificationdata([...data["notification"]])
            });

    }


    useEffect(() => {
        var Url = window.API_PREFIX + "admin/all_user";

        fetch(Url, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },

        })
            .then((resp) => resp.json())
            .then((data) => {
                setUsers([...data["users"]])
            });
        getNotifications()


    }, []);

    const handleAddNotification = () => {
        var Url = window.API_PREFIX + "admin/add_notification";
        setloading(true)
        fetch(Url, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                "Token": token,
                "Message": textInput,
                "Title": title,
                "IsAll": isAllUser,
                "User": !isAllUser ? selectedUser : []
            })
        })
            .then((resp) => resp.json())
            .then((data) => {
                if (data["status"] === "1") {
                    setTitle("")
                    setTextInput("")
                    setIsAllUser(false)
                    setSelectedUser("")
                    getNotifications()
                    toast.success("Notification sent successfully")
                    setloading(false)

                }
                else {
                    toast.error("Notification failed")
                }
            });
    }


    const columns = [
        {
            title: 'Title',
            dataIndex: 'Title',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
                    },
                    children: <div style={{ fontWeight: '600' }}  >{text}</div>
                }
            }
        },
        {
            title: 'Message',
            dataIndex: 'Message',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
                    },
                    children: <div style={{ fontWeight: '600' }}  >{text}</div>
                }
            }
        },
        {
            title: 'User',
            dataIndex: 'User',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
                    },
                    children: (
                        <Collapse>
                            <Panel header="Users" key="1">
                                {text.map((eachText, index) => (
                                    <div key={index}>{eachText}</div>
                                ))}
                            </Panel>
                        </Collapse>
                    )
                };
            }
        },
        {
            title: 'Date/Time',
            dataIndex: 'Datetime',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
                    },
                    children: <div style={{ fontWeight: '600' }}  >{text}</div>
                }
            }
        },


    ];


    const [Allnotificationdata, setAllnotificationdata] = useState([]);


    return (
        <div className='p-3'>

            <h4>Add Notifications</h4>
            <div>
                <div class="content d-flex align-items-center mb-3">
                    <label class="checkBox d-flex">
                        <input id="ch1" type="checkbox" checked={isAllUser} onChange={handleCheckboxChange} />
                        <div class="transition"></div>
                    </label>
                    <span className='mx-3'>All Users</span>
                </div>
                {!isAllUser ?
                    <div>
                        <Transfer
                            dataSource={users}
                            showSearch
                            listStyle={{
                                width: 300,
                                height: 400,
                            }}
                            filterOption={handleFilter}
                            targetKeys={selectedUser}
                            onChange={handleChange}
                            render={(item) => `${item.id}. ${item.Name}-${item.MobileNo}`}
                            rowKey={(item) => `${item.id}`}
                            showSelectAll={true}
                        />
                    </div> : null}
            </div>



            <div className='mt-3'>

                <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="Title" style={{ width: '320px' }} value={title} onChange={handleTitleChange} />
                    <label for="floatingInput">Title</label>
                </div>

                <div>
                    <textarea class="form-control" placeholder="Comments" id="floatingTextarea2" style={{ width: '320px' }} value={textInput}
                        onChange={handleInputChange}></textarea>

                </div>
                {
                    loading ? <Loader /> : <button type="button" class="btn allproducts_addnew_button mt-3" onClick={handleAddNotification}>Post</button>
                }

            </div>


            <Table
                className="all_products_tables"
                columns={columns}
                dataSource={Allnotificationdata}
                locale={{
                    emptyText: loading ? <Loader /> : <Empty />,
                }}
            />



        </div>
    )
}

export default Addnotifications