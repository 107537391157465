import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Thankupage.css'

function Thankupage() {

    let navigate = useNavigate();

    return (
        <div className='container mt-5 mb-5 text-center'>

            <div className="thank_you_container">
                <div className='thankkyou'>Thank You</div>
                <p className='thankkyou_2'>FOR ORDERING FROM <span className='thankkyou_3'>PANKAJ JEWELLERS</span></p>
                <div className="button_container">
                    <div className="button_home" onClick={() => {
                        navigate('/')
                    }}>Home</div>
                    <div className="button_order_thankyou" onClick={(() => {
                        navigate('/myorder')
                    })}>My Order</div>
                </div>
            </div>



            {/* <h1>THANK YOU</h1>
            <h5>For Shopping With Us!</h5>
            <div className='d-flex justify-content-evenly'>
                <button type="button" className="button_order" style={{ width: '20%' }} onClick={() => {
                    navigate('/')
                }}>
                    Home
                </button>
                <button type="button" className="button_order" style={{ width: '20%' }} onClick={(() => {
                    navigate('/myorder')
                })}>
                    My Orders
                </button>
            </div> */}
        </div>
    )
}

export default Thankupage