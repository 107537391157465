import React, { useState,useEffect } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
function Adminblogs() {

    const [title, settitle] = useState()
    const [author, setauthor] = useState()







    const [inputValue, setInputValue] = useState('');
    const [blogCategory, setblogCategory] = useState([]);
    const [selectCatId, setselectCatId] = useState("");
    const [blogdesc, setblogdesc] = useState("");

    const [currentImg, setcurrentImg] = useState(null)
    const [imgPreview, setimgPreview] = useState(null)




    
    useEffect(() => {
        var Url = window.API_PREFIX + 'admin/get_blog_category'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                { "Token": localStorage.getItem("Token") }),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                setblogCategory([...data["AllCategory"]])
            }
        })
    }, [])


    const handleSubmit = (event) => {

        if (blogdesc.trim() === '') 
            {alert("Fill blog content")
                }
        else{
            var uploadData = new FormData();

            uploadData.append('Token', localStorage.getItem("Token"))
            uploadData.append('Title', title)
            uploadData.append('Author', author)
            uploadData.append('CategoryId', selectCatId)
            uploadData.append('Description', blogdesc)
            uploadData.append("blog_cover", currentImg);


            var Url = window.API_PREFIX + 'admin/add_blog'
            fetch(Url, {
                method: 'POST',
                // headers: {
                //     'Content-type': 'application/json',
                // },
                body:uploadData,
            }).then(resp => resp.json()).then(data => {
                console.log(data)
                if (data['status'] === "1") {
                    alert("Blog added Successfully");
                    window.location.reload()
                }
            })
        }
    };



    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const addToDropdown = () => {

    var Url = window.API_PREFIX + 'admin/add_blog_category'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                { "Token": localStorage.getItem("Token"),
                CategoryName:inputValue
                }),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                const newOption = { CategoryId: data['blogCategoryId'] , CategoryName: inputValue };
                setblogCategory([...blogCategory, newOption]);
                setInputValue('');
            }
        })
            

      
    };



    return (
        <div className='container'>
            <h2>Add Blogs</h2>
            <div>

            <div>
                    <div>Select Blog Category</div>
                    <select 
                    onChange={(e)=>{
                        setselectCatId(e.target.value);
                        
                    }}>
                        <option value="">Select an option</option>
                        {blogCategory.map((eachBlogCategory, index) => (
                            <option key={eachBlogCategory.CategoryId} value={eachBlogCategory.CategoryId}>{eachBlogCategory.CategoryName}</option>
                        ))}
                    </select>
                </div>

                {selectCatId===""?<>             <input type="text" class="addproductformControl w-50" placeholder="Blog Category"
                        value={inputValue}
                        onChange={handleInputChange} />
    
    
                    <button className='btn addproduct_btn_publish' onClick={addToDropdown}>Add New Category</button>
                    </>
       
                :null}
            {/* <form onSubmit={handleSubmit}> */}

                <input type="text" class="addproductformControl w-50" placeholder="Blog Title"
                    onChange={(e) => {
                        settitle(e.target.value)
                    }}  required/>

                <input type="text" class="addproductformControl w-50" placeholder="Author Name"
                    onChange={(e) => {
                        setauthor(e.target.value)
                    }} required/>


                    <input
                style={{ "cursor": "pointer" }}
                type="file"
                name="myImage"
                accept='image/png/jpeg'
                onChange={(event) => {

                    // setSelectedImage(event.target.files[0]);
                    setcurrentImg( event.target.files[0])
                    const file = event.target.files[0];

                    // Check if a file was selected
                    if (file) {
                      // Create a new FileReader instance
                      const reader = new FileReader();
                
                      // Set up FileReader onload event handler
                      reader.onload = () => {
                        // Update the selectedImage state with the data URL of the selected file
                        setimgPreview(reader.result);
                      };
                
                      // Read the selected file as a data URL
                      reader.readAsDataURL(file);
                    }

         

                }}
            required/>
     {imgPreview && (
        <div>
          <h2>Preview:</h2>
          <img src={imgPreview} alt="Selected" style={{ maxWidth: '100%', maxHeight: '300px' }} />
        </div>
      )}


   
            
                <div className="addproduct_text_editor_App">
                                <div className='addproduct_long_discription'>Blog Content</div>
                                <CKEditor
                                    editor={ClassicEditor}

                                    data="<p></p>"
                                    onReady={editor => {
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        // console.log( { event, editor, data } );
                                        setblogdesc(data)
                                        // console.log(ShortDes)
                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        console.log('Focus.', editor);
                                    }}


                                />


                            </div>

                            {selectCatId!==""?    
                <button className='addproduct_category_add_btn mt-2'
                    
                                        onClick={handleSubmit}
                    
                >ADD BLOG</button>
                                :null}



{/* </form> */}
            </div>
        </div>
    )
}

export default Adminblogs