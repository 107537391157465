import { useEffect, useState, useRef } from 'react';
import { useStateValue } from "../../../StateProviders";
import { useNavigate, useParams } from 'react-router-dom';
import usePagination from "./Pagination";
import './VerticalProductCards.css';
import './Popup.css'
import Popup from "reactjs-popup";
import React from 'react';
import './Popup.css';
import right_tick from '../../UserMedia/Popups/download (3).png'
import MultiRangeSlider from "../VerticalProductCards/MultiRangeSlider";
import Loader from '../../../Admin/Loader.js';
import toast, { Toaster } from 'react-hot-toast';

const contentStyle = {
  maxWidth: "600px",
  width: "90%",
  backgroundColor: "black",
  boxshadow: "0 0 20px rgba(255, 255, 255, 0.4)",
  width: "max-content",
  borderradius: "5px",
  padding: "5px",
};

function VerticalProductCards() {


  const ref = useRef();
  const params = useParams();
  const { Filterparam } = params;

  let navigate = useNavigate();
  const [CopyProductData, setCopyProductData] = useState([]);
  const [ProductData, setProductData] = useState([]);

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;


  const Pagecount = Math.ceil(ProductData.length / PER_PAGE);
  const _DATA = usePagination(ProductData, PER_PAGE);



  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };


  const [wishList, setwishList] = useState([])
  const [selectedCategory, setselectedCategory] = useState([])
  const [selectedPrice, setselectedPrice] = useState([])
  const [selectFilter, setselectFilter] = useState([])
  const [productList, setproductList] = useState([])
  const [{ basket }, dispatch] = useStateValue();
  const [{ ShowLoginPop, ComparePop, CompareVar }, dispatch2] = useStateValue();
  const [CurrentSort, setCurrentSort] = useState("Latest")
  const [Heading, setHeading] = useState()


  useEffect(() => {
    var Data = {}
    if (localStorage.getItem('Token') != null) {
      Data['Token'] = localStorage.getItem('Token')

    }

    Data['Category_Id'] = Filterparam.split("&")[2]
    Data['Category_Name'] = Filterparam.split("&")[1]
    Data['Type'] = Filterparam.split("&")[0]


    var Url = window.API_PREFIX + 'products/categorywise_products'
    setloading(true)
    fetch(Url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(
        Data
      ),
    }).then(resp => resp.json()).then(data => {
      if (data['status'] === "1") {
        setTimeout(() => {
          setloading(false)
        }, 2000)

        setProductData([...data["products"]])
        setCopyProductData([...data["products"]])
        setproductList([...data["Product_Id"]])
        setHeading(data['Heading'])
      }
    })
  }, [ComparePop, CompareVar]
  )


  const [loading, setloading] = useState(false);


  let debounceTimeout;

  const getProductDetails = () => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    debounceTimeout = setTimeout(() => {
      var Data = {}
      if (localStorage.getItem('Token') != null) {
        Data['Token'] = localStorage.getItem('Token')

      }

      Data['Category_Id'] = Filterparam.split("&")[2]

      Data['Category_Name'] = Filterparam.split("&")[1]

      Data['Type'] = Filterparam.split("&")[0]
      Data['Keyword'] = "_"


      var Url = window.API_PREFIX + 'products/categorywise_products'
      setloading(true)
      fetch(Url, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(
          Data
        ),
      }).then(resp => resp.json()).then(data => {
        // console.log(data)

        if (data['status'] === "1") {
          setTimeout(() => {
            setloading(false)
          }, 2000)
          setProductData([...data["products"]])
          setCopyProductData([...data["products"]])


          setproductList([...data["Product_Id"]])
          setHeading(data['Heading'])
          setselectFilter([])
          setselectedCategory([])
          setselectedPrice([])

        }
      }
      )
    }, 300);
  }



  useEffect(() => {


    getProductDetails()


  }, [Filterparam])

  const [filterpageDropdown, setFilterpageDropdown] = useState(false)

  const handleAddCart = (eachProducts) => {


    if (!ShowLoginPop) {

      dispatch(
        { type: "Logout" }
      )
      dispatch(
        { type: "OpenLogin" }
      )
    }
    else {
      if (eachProducts.AllVarient[0].IsCart) {
        navigate(`/checkout`)
      } else {
        var Url = window.API_PREFIX + 'order/update_cart'
        fetch(Url, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(
            {
              "Token": localStorage.getItem("Token"),
              "ProductId": eachProducts.ProductId, "Quantity": 1
            }
          ),
        }).then(resp => resp.json()).then(data => {

          if (data['status'] === "1") {

            dispatch({
              type: 'ADD_TO_BASKET',
              item: eachProducts
            })
            getProductDetails()
          }
          else {
          toast.error('Quantity is not available')
          }
        })
      }
    }
  }

  const showToast = () => {
    toast((t) => (
      <span>
        Quantity is not available
        <button onClick={() => toast.dismiss(t.id)} className='mx-2'>
          Dismiss
        </button>
      </span>
    ));
  };



  const sortByPriceHighestFirst = () => {
    const tmpProductData = ProductData.sort(
      (objA, objB) => Number(objB.OriginalPrice) - Number(objA.OriginalPrice)
    );
    setProductData([...tmpProductData]);
    setCurrentSort('Price (Highest First)');
    setFilterpageDropdown(false);
  };

  const sortByPriceLowestFirst = () => {
    const tmpProductData = ProductData.sort(
      (objA, objB) => Number(objA.OriginalPrice) - Number(objB.OriginalPrice)
    );
    setProductData([...tmpProductData]);
    setCurrentSort('Price (Lowest First)');
    setFilterpageDropdown(false);
  };

  const sortByDiscountDescending = () => {
    const tmpProductData = ProductData.sort(
      (objA, objB) =>
        Number(
          Math.floor(
            ((objB.OriginalPrice - objB.SelingPrice) / objB.OriginalPrice) * 100
          )
        ) -
        Number(
          Math.floor(
            ((objA.OriginalPrice - objA.SelingPrice) / objA.OriginalPrice) * 100
          )
        )
    );
    setProductData([...tmpProductData]);
    setCurrentSort('Discount (Descending)');
    setFilterpageDropdown(false);
  };

  const sortByTopRated = () => {
    const tmpProductData = ProductData.sort(
      (objA, objB) => Number(objB.Ratings) - Number(objA.Ratings)
    );
    setProductData([...tmpProductData]);
    setCurrentSort('Top Rated');
    setFilterpageDropdown(false);
  };





  return (
    <>
      <div className='container'>
        <div className=' row'>
          <div className='filter_section col'>
            <div className='filter_by'>Filter by</div>
            <div className='filter_by_dropdown'>
              <select className='sortby_dropdown_1'>
                <option value="latest">price</option>
              </select>
              <select className='sortby_dropdown_2 ms-4'>
                <option value="gold">Gold</option>
                <option value="silver">Silver</option>
                <option value="platinum">Platinum</option>
                <option value="Idol">Idol</option>
              </select>
            </div>
          </div>
          <div className='sort_section col'>
            <div className='sort_by'>Sort by</div>
            <div class="sort_by_dropdown">
              <select className='sortby_dropdown_1'
                onChange={(e) => {
                  const value = e.target.value;
                  switch (value) {
                    case 'price_high':
                      sortByPriceHighestFirst();
                      break;
                    case 'price_low':
                      sortByPriceLowestFirst();
                      break;
                    case 'discount':
                      sortByDiscountDescending();
                      break;
                    case 'top_rated':
                      sortByTopRated();
                      break;
                    default:
                      break;
                  }
                }}
              >
                <option value="latest">Latest</option>
                <option value="featured">Featured</option>
                <option value="price_high">Price (Highest First)</option>
                <option value="price_low">Price (Lowest First)</option>
                <option value="discount">Discount (Descending)</option>
                <option value="top_rated">Top Rated</option>
              </select>
            </div>
          </div>
        </div>

        <div className='main_division mt-3'>
          {
            loading ? <Loader /> :
              <div className='row gx-1 gy-1 container'>
                {_DATA.currentData().map((eachProducts, ProductIndex) => {
                  if (eachProducts.IsOffer === true) {
                    var offer = Math.floor(((eachProducts.OriginalPrice - eachProducts.SelingPrice) / eachProducts.OriginalPrice) * 100)
                  }

                  return (
                    <div className='col col-6 col-sm-4 col-md-3'>
                      <div className='cardd_mainn'>
                        <div
                          className='card_top'

                        >
                          <img src={eachProducts.ProductImg} className='image_size' alt="Product Image"
                                onClick={() => {
                                  navigate(`/product/` + eachProducts.ProductId)
                                }}>
                          </img>
                          {eachProducts.IsOffer ? <div className='le'>{offer}% Off</div> : null}
                          <div className='review_div'>4.9/75</div>
                          <div className='re'
                          ><i className={!eachProducts["IsWishlisted"] ? "fa-regular fa-heart fa-2x  " : "fa-solid fa-heart fa-2x  "} style={!eachProducts["IsWishlisted"] ? null : { color: '#4f3267' }}
                            onClick={(e) => {
                              if (ShowLoginPop) {
                                var tmp_wishlist = wishList
                                var Url = ""
                                if (eachProducts["IsWishlisted"]) { Url = window.API_PREFIX + 'products/delete_wishlist' }
                                else { Url = window.API_PREFIX + 'products/add_wishlist' }

                                fetch(Url, {
                                  method: 'POST',
                                  headers: {
                                    'Content-type': 'application/json',
                                  },
                                  body: JSON.stringify(
                                    { "Token": localStorage.getItem("Token"), "ProductId": eachProducts.ProductId }),
                                }).then(resp => resp.json()).then(data => {

                                  if (data['status'] == "1" || data['status'] == "-1") {
                                    var Tmp = ProductData
                                    tmp_wishlist[ProductIndex] = !tmp_wishlist[ProductIndex]
                                    Tmp[ProductIndex]['IsWishlisted'] = !Tmp[ProductIndex]['IsWishlisted']
                                    setProductData([...Tmp])
                                    if (Tmp[ProductIndex]['IsWishlisted']) {

                                      toast.success('Successfully Added')
                                      dispatch({
                                        type: 'ADD_TO_WISHLIST',
                                        item: ProductData[ProductIndex]
                                      });
                                    } else {
                                      toast.success('Successfully Removed')
                                      dispatch({
                                        type: 'REMOVE_FROM_WISHLIST',
                                        id: ProductData[ProductIndex].ProductId
                                      });
                                    }
                                  }
                                  else if (data['status'] == "0") {
                                    dispatch(
                                      { type: "Logout" }
                                    )
                                    dispatch(
                                      { type: "OpenLogin" }
                                    )
                                  }
                                }

                                )

                                const timeId = setTimeout(() => {

                                }, 2000)
                              }
                              else {
                                dispatch({ type: "OpenLogin" })
                              }

                            }}></i></div>


                        </div>






                        <div className='price_of_product_vertical_product'
                          onClick={() => {
                            navigate(`/product/` + eachProducts.ProductId)
                          }}>
                          <div style={{maxWidth:'300px', overflow:'hidden', textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                            <span style={{ fontSize: '16px', fontWeight: '400' }}>{eachProducts.ProductName}</span>
                          </div>
                          {eachProducts.IsOffer ? <div className='price_both_discounted_and_regular_vertical_page'>
                            <div className='discounted_price'>
                              <span style={{ fontSize: '20px' }} >₹{eachProducts.SelingPrice}</span>
                            </div>

                            <span className='mx-2 text-decoration-line-through' style={{ fontSize: '12px', color: '#878787' }}>₹{eachProducts.OriginalPrice}</span>

                          </div> :
                            <div className='price_both_discounted_and_regular_vertical_page'>
                              <span className='mx-2 text-decoration-line-through' style={{ fontSize: '12px', color: '#878787' }} >₹{eachProducts.OriginalPrice}</span>
                            </div>}
                          <div className='name_reference_of_product_vertical_page' onClick={() => {
                            navigate(`/product/` + eachProducts.ProductId)

                          }}>

                          </div>



                          <button type="button" className='add_to_cart_btn btn'
                            onClick={() => handleAddCart(eachProducts)}
                          >{eachProducts.AllVarient[0].IsCart ? "Go to Cart" : "Add to Cart"}</button>


                        </div>
                      </div>
                    </div>

                  )
                })}
              </div>
          }

        </div>

        <div className='pagination'>
          <nav aria-label="...">
            <ul class="pagination">
              {page > 1 ? <><li class="page-item disabled">
                <a class="page-link vertical_product_card_pagination_arrow"
                  onClick={(e) => {
                    handleChange(e, page - 1)
                  }}
                ><i class="fa-solid fa-angles-left"></i></a>
              </li>
                <li class="page-item"
                  onClick={(e) => {
                    handleChange(e, page - 1)
                  }}
                ><a class="page-link vertical_product_card_pagination" >{page - 1}</a></li></> : null}
              <li class="page-item active " aria-current="page">
                <a class="page-link vertical_product_card_pagination_active" >{page}</a>
              </li>
              {page < Pagecount ? <><li class="page-item"
                onClick={(e) => {
                  handleChange(e, page + 1)
                }}
              ><a class="page-link vertical_product_card_pagination" >{page + 1}</a></li>
                <li class="page-item active">
                  <a class="page-link vertical_product_card_pagination_arrow"
                    onClick={(e) => {
                      handleChange(e, page + 1)
                    }}><i class="fa-solid fa-angles-right"></i></a>
                </li></> : null}
            </ul>
          </nav>
        </div>
      </div>




      <Toaster />
    </>
  )
}
export default VerticalProductCards;