import React from 'react'
import { Space, Table, Tag } from 'antd';
import { useState, useEffect } from 'react';


const columns = [
  {
    title: 'name',
    dataIndex: 'Name',
    render(text) {
      return {
        props: {
          style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
        },
        children: <div style={{ fontWeight: '600' }}  >{text}</div>
      }
    }
  },
  {
    title: 'email',
    dataIndex: 'Email',
    render(text) {
      return {
        props: {
          style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
        },
        children: <div style={{ fontWeight: '600' }}  >{text}</div>
      }
    }
  },
  {
    title: 'Description',
    dataIndex: 'Message',
    render(text) {
      return {
        props: {
          style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
        },
        children: <div style={{ fontWeight: '600' }}  >{text}</div>
      }
    }
  },
  {
    title: 'time',
    dataIndex: 'Date',
    render(text) {
      return {
        props: {
          style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
        },
        children: <div style={{ fontWeight: '600' }}  >{text}</div>
      }
    }
  },
]

function RefundRequest() {
  
  const convertToCSV = (data) => {
    const csvRows = [];
    
    // Get headers
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));
  
    // Loop over the rows
    for (const row of data) {
        const values = headers.map(header => {
            const escaped = ('' + row[header]).replace(/"/g, '\\"');
            return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
    }
  
    return csvRows.join('\n');
  };
  
  const downloadCSV = () => {
    const csvData = convertToCSV(contactData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'data.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    pageSizeOptions: ['10', '20', '50', '100'],
    showSizeChanger: true,
  });

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };


  const [contactData, setcontactData] = useState([])
  useEffect(() => {

    var Url = window.API_PREFIX + 'admin/all_refund_request?Token=' + localStorage.getItem("Token")
    fetch(Url, {
      method: 'Get',

    }).then(resp => resp.json()).then(data => {

      if (data['status'] === "1") { setcontactData([...data['all_contacts']]) }

    }
    )
  }, [])
  return (
    <div>
     <div className='col d-flex justify-content-end align-items-center mt-3'>
        <button type="button" class="btn allproducts_addnew_button me-3"  onClick={downloadCSV} >Export</button>
      </div>
      <Table columns={columns} dataSource={contactData} pagination={pagination}
        onChange={handleTableChange} />
    </div>
  )
}

export default RefundRequest