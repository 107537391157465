import React,{useState,useEffect} from 'react';
import './Allusercart.css';
import { useNavigate ,useParams} from 'react-router-dom';
import { Table } from 'antd';

function Allusercart() {
    let navigate = useNavigate()
const [CartData,setCartData] = useState([])
const params = useParams()
var {NameId} = params
console.log(NameId)
const UID = NameId.split("&&")[0]
const UName = NameId.split("&&")[1]

useEffect(()=>{
    var Url = window.API_PREFIX + 'products/checkout_admin'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                { "Token": localStorage.getItem("Token"),
                "UserId":UID
            }
            ),
        }).then(resp => resp.json()).then(data => {
     
            if (data['status'] === "1") {

                setCartData([...data['CheckoutData']])
             }})
},[])

// const [AllMsg,setAllMsg] = useState([])
const [GroupName,setGroupName] = useState('')
// const [unreadcount,setunreadcount] = useState("")


const [chatSocket, setChatSocket] = useState(null);
const [webSocketInitialized, setWebSocketInitialized] = useState(false);

useEffect(()=>{

        if (!webSocketInitialized) {
            // setGroupName('AUC-'+UID)
    
            const socket = new WebSocket(
                window.WEBSOC + 'ws/AUC/AUC-'+UID + "/"
            );
            socket.onopen = () => {
                console.log("WebSocket connection opened");
            setWebSocketInitialized(true);
    
            };
            socket.onmessage = function (e) {
                console.log("msg", e)



                var Url = window.API_PREFIX + 'products/checkout_admin'
                fetch(Url, {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                    },
                    body: JSON.stringify(
                        { "Token": localStorage.getItem("Token"),
                        "UserId":UID
                    }
                    ),
                }).then(resp => resp.json()).then(data => {
             
                    if (data['status'] === "1") {
        
                        setCartData([...data['CheckoutData']])
                     }})






                //   const data = JSON.parse(e.data);
                // setChatLog((prevChatLog) => prevChatLog + e.data + '\n');
            };
        
            socket.onclose = function (e) {
                console.error('Chat socket closed unexpectedly');
            setWebSocketInitialized(false);
    
            };
            setChatSocket(socket);
            // return () => {
            //     socket.close();
            // };
        
    }
    },[GroupName])


    function formatIndianNumber(num) {
        const numStr = num.toString();
        const parts = [];
        let counter = 0;
        
       
        if (numStr.length > 3) {
          parts.unshift(numStr.slice(-3));
          counter = 1;
        } else {
          parts.unshift(numStr);
        }
        
       
        for (let i = numStr.length - 3; i > 0; i -= 2) {
          parts.unshift(numStr.slice(Math.max(0, i - 2), i));
          counter++;
        }
        
        return parts.join(',');
      }


    const columns = [
        {
            title: 'Productid',
            dataIndex: 'ProductId',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Image',
            dataIndex: 'ProductImg',
            render: (img) => <img src={img}
                height="50px" width="50px" />
        },
        {
            title: 'Name',
            dataIndex: 'ProductName',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div style={{ width: "150px" }}>{text}</div>
                }
            }
        },
        // {
        //     title: 'SKU',
        //     dataIndex: 'SKU',
        //     render(text) {
        //         return {
        //             props: {
        //                 style: { fontSize: "16px", textAlign: "center" }
        //             },
        //             children: <div>{text}</div>
        //         }
        //     }
        // },
        // {
        //     title: 'Stock',
        //     dataIndex: 'Stock',
        //     render(text, record) {
        //         return {
        //             props: {
        //                 style: { color: "#00ab41", fontSize: "16px" }
        //             },
        //             children: <div>{text}</div>
        //         };
        //     }
        // },
        {
            title: 'RegularPrice',
            dataIndex: 'OriginalPrice',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>{formatIndianNumber(text)}</div>
                }
            }
        },
        {
            title: 'SalePrice',
            dataIndex: 'SellingPrice',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>{formatIndianNumber(text)}</div>
                }
            }
        },
        {
            title: 'Categories',
            dataIndex: 'Category',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'SubCategories',
            dataIndex: 'SubCategory',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'SubSection',
            dataIndex: 'SubSection',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    return (
        <div className='allusercart_main_division'>
            <button className='allusercart_back_btn'
                onClick={() => {
                    navigate("/administration/alluser")
                }}><i class="fa-solid fa-arrow-left fa-xl"></i></button>
            <br></br>
            <div className='allusercart_username'>{UName}</div>
            <Table className='all_products_tables' rowSelection={rowSelection} columns={columns} dataSource={CartData}/>
        </div>
    )
}

export default Allusercart