
import './EditProduct.css';
import '../AddProduct/ImgSelector.css';
import React, { Component, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import Popup from 'reactjs-popup';
import { EditFilled } from '@ant-design/icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, Popover } from "antd";
import { Form, Input, InputNumber, Space, Table, Tag, Typography, Popconfirm } from 'antd';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';


const animatedComponents = makeAnimated();



const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: false,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};


function EditProduct() {


    const ImagePopStyle = {
        backgroundColor: "white",
        boxshadow: "0 0 20px rgba(255, 255, 255, 0.4)",
        borderradius: "5px",
        padding: "30px",
        width: "35%",
        height: "500px",
        overflow: "scroll",
    };


    let navigate = useNavigate()
    const [ImgSelectPop, setImgSelectPop] = useState(false)
    const [AllImgData, setAllImgData] = useState([])
    const [ImgAddress, setImgAddress] = useState("")
    const [Tax ,setTax] = useState(5)

    const params = useParams();
    const { PID } = params;

    const [showtitleitem, setshowtitleitem] = useState(false)
    const [ProductBrand, setProductBrand] = useState('')
    const [MultiImgId, setMultiImgId] = useState([])
    const [MultiImgAddress, setMultiImgAddress] = useState([])

    const [MImgSelectPop, setMImgSelectPop] = useState(false)
    const [AllMImgData, setAllMImgData] = useState([])

    const [ProductName, setProductName] = useState("")

    const [ShortDes, setShortDes] = useState('')
    const [LongDes, setLongDes] = useState('')
    const [titlelist, settitlelist] = useState(["Texable", "Shipping Only", "None"])
    const [titlename, settitlename] = useState("Texable")
    const [SalePriceDateStart, setSalePriceDateStart] = useState("")
    const [IsOffer, setIsOffer] = useState(true)
    const [Showstock, setShowstock] = useState(false)
    const [parmalink, setparmalink] = useState('')

    const [SalePriceDateEnd, setSalePriceDateEnd] = useState("")
    const [RegularPrice, setRegularPrice] = useState(0)
    const [SalePrice, setSalePrice] = useState(0)
    const [PriceScheduler, setPriceScheduler] = useState(false)
    const [ActiveProductTab, setActiveProductTab] = useState([true, false, false, false, false, false])
    const [SKU, setSKU] = useState(0)
    const [Weight, setWeight] = useState(0)
    const [Length, setLenght] = useState(0)
    const [width, setwidth] = useState(0)
    const [height, setheight] = useState(0)
    const [Upsales, setUpsales] = useState(0)
    const [crosssales, setcrosssales] = useState()
    const [AddCategory, setAddCategory] = useState("")
    const [AddCategoryFlag, setAddCategoryFlag] = useState(false)
    const [AddSubCategory, setAddSubCategory] = useState("")
    const [AddSubCategoryFlag, setAddSubCategoryFlag] = useState(false)
    const [AddSubSection, setAddSubSection] = useState("")
    const [AddSubSectionFlag, setAddSubSectionFlag] = useState(false)
    const [MenuOrder, setMenuOrder] = useState(0)
    const [StockStatus, setStockStatus] = useState("In_Stock")
    const [Stocknumber, setStocknumber] = useState(1)

    const [MetaTitle, setMetaTitle] = useState("")
    const [MetaKeyword, setMetaKeyword] = useState("")
    const [MetaDescription, setMetaDescription] = useState("")

    const [SubCategoryWarn, setSubCategoryWarn] = useState(false)
    const [SubSectionWarn, setSubSectionWarn] = useState(false)
    const [editproductCategory, seteditproductCategoryl] = useState([])
    const [editproductSubSection, seteditproductSubSectionl] = useState([])
    const [editproductSubCategory, seteditproductSubCategoryl] = useState([])

    const [selectedImage, setSelectedImage] = useState(null);
    const [ImgId, setImgId] = useState("-1");


    const [CategoryId, setCategoryId] = useState('-1')
    const [SubCategoryId, setSubCategoryId] = useState('-1')
    const [SubSectionId, setSubSectionId] = useState('-1')
    const [VariantData, setVariantData] = useState([])
    const [searchResultData, setsearchResultData] = useState({})
    const [showsearch, setshowsearch] = useState(false);
    const [SearchWord, setSearchWord] = useState("")
    const [selectedCrossItems, setSelectedCrossItems] = useState([]);
    const [selectedUpItems, setSelectedUpItems] = useState([]);


    const options = Object?.keys(searchResultData)?.length > 0  && searchResultData?.ProductId?.length > 0 ? searchResultData.ProductId.map((productId, index) => ({
        value: productId,
        label: `${productId} : ${searchResultData.ProductName[index]}`
    })) : [] ;

    let defaultOption;

    useEffect(()=> {
         defaultOption = options.filter(product => selectedCrossItems.some(item => item === product.value));
    },[options])


    const handleCrossChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map(item => item.value);
        setSelectedCrossItems(selectedValues);
    };

    const handleUpChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map(item => item.value);
        setSelectedUpItems(selectedValues);
    };


    const [open, setOpen] = useState(false);

    const hide = () => {
        setOpen(false);
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };




    function PublishProduct(Is_Published) {
        if (ImgId !== "-1" && CategoryId !== "-1" && SubCategoryId !== "-1" && VariantData.length !== 0) {
            var Url = window.API_PREFIX + 'products/set_edit_product'
            console.log(VariantData)
            fetch(Url, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(
                    {
                        // "ProductId": PID,
                        "Token": localStorage.getItem("Token"),
                        "SKU": SKU,
                        "Tax": Tax,
                        "Product_Name": ProductName,
                        "Product_Brand": ProductBrand,
                        "ShortDescription": ShortDes,
                        "LongDescription": LongDes,
                        // "Original_Price": RegularPrice,
                        // "Discount_Price": SalePrice,
                        "Is_Published": Is_Published,
                        // "StartDate_EndDate": SalePriceDateStart + "_" + SalePriceDateEnd,
                        "Menu_Order": MenuOrder,
                        "Sub_Category": SubCategoryId,
                        "Category": CategoryId,
                        // "Sub_Section": SubSectionId,
                        // "Product_Stock": Stocknumber,
                        // "StockStatus": StockStatus,
                        // "Weight": Weight,
                        // "Height": height,
                        // "Length": Length,
                        // "Width": width,
                        "Upsell":selectedUpItems,
                        "Crosssell": selectedCrossItems,
                        "Product_Img": ImgId,
                        "ProductMultiImg": MultiImgId,
                        // "IsOffer": IsOffer,
                        // "BrandName": ProductBrand,
                        // "ParmaLink": parmalink,
                        // "MetaDescription": MetaDescription,
                        // "MetaKeyword": MetaKeyword,
                        // "MetaTitle": MetaTitle,
                        Varient: VariantData,
                        Varient_Id: PID



                    }),
            }).then(resp => resp.json()).then(data => {
                console.log(data)
                if (data['status'] == "1") {
                    navigate("/administration/allproduct")
                }
            })
        }
    }

    useEffect(() => {
        var Url = window.API_PREFIX + 'products/get_category'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                { "Token": localStorage.getItem("Token") }),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] == "1") {
                seteditproductCategoryl([...data["AllCategory"]])
                setSelectedOptioneditproductCategory(data["AllCategory"][0]["CategoryName"])
            }
        })
    }, [])

    const [selectedOptioneditproductCategory, setSelectedOptioneditproductCategory] = useState();


    const [selectedOptioneditproductSubCategory, setSelectedOptioneditproductSubCategory] = useState();
    const [selectedOptioneditproductSubSection, setSelectedOptioneditproductSubSection] = useState();





    useEffect(() => {
        var Url = window.API_PREFIX + 'products/edit_product'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                { "Token": localStorage.getItem("Token"), "Varient_Id": PID }),
        }).then(resp => resp.json()).then(data => {
            console.log("data edit",data)
            if (data['status'] == "1")
                var response = data["ProductData"]
            {

                setSKU(response["SKU"])
                setProductName(response["Product_Name"])
                setShortDes(response["Short_Description"])
                setLongDes(response["Long_Description"])
                // setRegularPrice(response["Original_Price"])
                // setSalePrice(response["Discount_Price"])
                // setSalePriceDateStart(response["StartDate_EndDate"].split("_")[0])
                // setSalePriceDateEnd(response["StartDate_EndDate"].split("_")[1])
                // setMenuOrder(response["Menu_Order"])
                setCategoryId(response["CategoryId"])
                setSelectedOptioneditproductCategory(response["CategoryName"])
                setSubCategoryId(response["Sub_CategoryId"])
                setSelectedOptioneditproductSubCategory(response["Sub_CategoryName"])
                // setSubSectionId(response["Sub_SectionId"])
                // setSelectedOptioneditproductSubSection(response["Sub_SectionName"])
                // setStockStatus(response["StockStatus"])
                // setStocknumber(response["Product_Stock"])

                // setheight(response['Height'])
                // setWeight(response['Weight'])
                // setLenght(response['Length'])
                // setwidth(response["Width"])
                // setMetaTitle(response["MetaTitle"])
                // setMetaDescription(response["MetaDescription"])
                // setMetaKeyword(response["MetaKeyword"])
                setProductBrand(response['Brand_Name'])
                // setIsOffer(response["IsOffer"])
                setImgAddress(response['Product_Img'])
                setImgId(response['Product_ImgId'])
                setMultiImgAddress([...response['ProductMultiImgAdd']])
                setMultiImgId([...response['ProductMultiImg']])
                setparmalink(response['ParmaLink'])
                setVariantData([...response['QuantityBundle']])
                setTax(response['Tax'])
                setSelectedUpItems(response['Upsell'])
                setSelectedCrossItems(response['Crosssell'])
                // if (response["StartDate_EndDate"] !== "_") {
                //     setPriceScheduler(true)
                // }




            }

        })

        // console.log('>>>start', SalePriceDateStart)
        // console.log('>>>End', SalePriceDateEnd)
    }, PID)





    const [quantitybundle, setquantitybundle] = useState('');
    const [originalprice, setoriginalprice] = useState();
    const [sellprice, setsellprice] = useState();
    const [stock, setStock] = useState();
    const [originalpricepaisa, setoriginalpricepaisa] = useState();
    const [sellpricepaisa, setsellpricepaisa] = useState();




    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            quantitybundle: record.quantitybundle,
            originalprice: record.originalprice,
            sellprice: record.sellprice,
            stock: record.stock,
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };


    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...VariantData];
            const index = newData.findIndex((item) => key === item.key);
            var Url = window.API_PREFIX + 'products/edit_attribute'
            //   fetch(Url, {
            //       method: 'POST',
            //       headers: {
            //           'Content-type': 'application/json',
            //       },
            //       body: JSON.stringify(
            //           {
            //               "Token":localStorage.getItem("Token"),
            //               "AttributeId":newData[index].key,
            //               "Product_Id":PID,
            //               "Heading":row.Heading,
            //               "AttributeKey":row.Spec_Key,
            //               "AttributeValue":row.Spec_Value,
            //           }
            //       ),
            //   }).then(resp => resp.json()).then(Revdata => {
            //       console.log(Revdata)
            //       if (Revdata['status'] === "1") 
            //       {
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setVariantData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setVariantData(newData);
                setEditingKey('');
            }
            // }})
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const handlesetoriginalprice = (event) => {
        const rawInputValue = event.target.value;
        const cleanedInputValue = rawInputValue.replace(/^0+/, ''); // Remove leading zeros
        setoriginalprice(cleanedInputValue);
    };

    const handlesetsellprice = (event) => {
        const rawInputValue = event.target.value;
        const cleanedInputValue = rawInputValue.replace(/^0+/, ''); // Remove leading zeros
        setsellprice(cleanedInputValue);
    };


    const handleSetStock = (event) => {
        const rawInputValue = event.target.value;
        const cleanedInputValue = rawInputValue.replace(/^0+/, ''); // Remove leading zeros
        setStock(cleanedInputValue);
    };




    const content = (
        <div>
            <div>
                <span>Quantity Bundle</span>
                <Input placeholder="Quantity Bundle" value={quantitybundle} onChange={(e) => {
                    setquantitybundle(e.target.value)
                }} />
            </div>
            <div className='mt-2'>
                <span>Original Price</span>
                <div className='d-flex'>
                    <Input type='number' placeholder="Rupees" value={originalprice}
                        onChange={handlesetoriginalprice}
                    />

                </div>

            </div>
            <div className='mt-2'>
                <span>Sell Price</span>
                <div className='d-flex'>
                    <Input type='number' placeholder="Rupees" value={sellprice} onChange={handlesetsellprice} />

                </div>

            </div>

            <div className='mt-2'>
                <span>Stock</span>
                <div className='d-flex'>
                    <Input type='number' placeholder="Rupees" value={stock} onChange={handleSetStock} />

                </div>

            </div>
            <div className='d-flex justify-content-between mt-3'>
                <a onClick={hide} style={{ color: '#483a15' }}>Close</a>
                <a onClick={() => {
                    if (quantitybundle.length != 0 && originalprice != 0) {
                        let newIndex = 1
                        if (VariantData.length > 0) {
                            newIndex = VariantData[VariantData.length - 1]['key'] + 1
                        }

                        setVariantData([...VariantData, {
                            key: newIndex,
                            quantitybundle: quantitybundle,
                            BuyPrice: originalprice,
                            SalePrice: sellprice,
                            Stock: +stock,
                            IsOffer: false,
                            Showstock: false,
                        }]);

                        setquantitybundle("")
                        setoriginalprice("")
                        setsellprice("")


                        setOpen(false);
                    }

                    else {
                        alert("Can not add blank Quantity and price")
                    }


                }} style={{ color: '#483a15' }}>Ok</a>
            </div>

        </div>
    );




    const columns = [
        {
            title: 'Quantity Bundle',
            dataIndex: 'quantitybundle',
            editable: true,

            render(text) {
                return {
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Original Price',
            dataIndex: 'BuyPrice',
            editable: true,

            render(text) {
                return {
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Sell Price',
            dataIndex: 'SalePrice',
            editable: true,

            render(text) {
                return {
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Stock',
            dataIndex: 'Stock',
            editable: true,

            render(text) {
                return {
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Is Offer',
            dataIndex: "IsOffer",
            width: '10%',
            editable: false,
            render: (_, record, index) => {

                return (<input class="form-check-input" type="checkbox" name="inlineCheckOptions" value='something' checked={record['IsOffer']}
                    onClick={(e) => { 
                        var tmpAttri = VariantData
                        tmpAttri[index]['IsOffer'] = !tmpAttri[index]['IsOffer']
                        setVariantData([...tmpAttri])
                        console.log(VariantData)
                    }}
                ></input>)
            }
        },
        {
            title: 'Show stock',
            dataIndex: "Showstock",
            width: '10%',
            editable: false,
            render: (_, record, index) => {

                return (<input class="form-check-input" type="checkbox" name="inlineCheckOptions" value='something' checked={record['Showstock']}
                    onClick={(e) => {
                        var tmpAttri = VariantData
                        tmpAttri[index]['Showstock'] = !tmpAttri[index]['Showstock']
                        setVariantData([...tmpAttri])
                        console.log(VariantData)
                    }}
                ></input>)
            }
        },
        {
            title: 'Edit',
            dataIndex: 'Edit',
            render(_, record) {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => {
                                save(record.key)
                            }}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                        <EditFilled style={{ color: "#00ab41", fontSize: "18px", display: "flex", justifyContent: "center" }} />
                        {/* Edit */}
                    </Typography.Link>
                );
            }
        },
        {
            title: 'Delete',
            dataIndex: 'Delete',
            render(text, record, index) {
                return {
                    children: <div

                        onClick={() => {
                            let updatedArray = [...VariantData];

                            updatedArray.splice(index, 1);

                            setVariantData(updatedArray);

                        }}


                    ><i class="fa-solid fa-trash" style={{ color: '#e11f48', cursor: 'pointer' }}></i>


                    </div>
                }
            }
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex,
                //  === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });


    return (
        <>

            <div className='editproduct_main_division'>
                <div className='editproduct_add_new_product_title'>Edit Product</div>

                <div className='editproduct_sub_main_division'>
                    <div className='editproduct_add_new_product_division'>
                        <input type="text" class="editproductformControl" placeholder="Product Name" value={ProductName}
                            onChange={(e) => {
                                setProductName(e.target.value)
                            }}
                        ></input>
                        <input type="text" class="editproductformControl" placeholder="Product Brand Name" value={ProductBrand}
                            onChange={(e) => {
                                setProductBrand(e.target.value)
                            }} />
                        <div className="editproduct_text_editor_App">
                            <div className='editproduct_long_discription'>Product short discription</div>
                            <CKEditor
                                editor={ClassicEditor}

                                data={ShortDes}
                                onReady={editor => {

                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    // console.log( { event, editor, data } );
                                    setShortDes(data)
                                    console.log(ShortDes)
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                            />
                        </div>

                        <div className='editproduct_product_data_main_division'>
                            <div className='editproduct_product_data'>
                                <div className='editproduct_product_data_title'>Product Data</div>
                            </div>

                            <div className='editproduct_category_list_details'>
                                <div className='editproduct_category_list'>
                                    <div className={ActiveProductTab[0] ? 'active_editproduct_category' : 'editproduct_category'}
                                        onClick={() => {
                                            setActiveProductTab([true, false, false, false, false, false])
                                        }

                                        }
                                    ><i class="fa-solid fa-wrench"></i> Category</div>
                                    <div className={ActiveProductTab[6] ? 'active_addproduct_category' : 'addproduct_category'}
                                        onClick={() => {
                                            setActiveProductTab([false, false, false, false, false, false, true])
                                            console.log(VariantData)
                                        }}
                                    ><i class="fa-solid fa-bullseye"></i>Quantity</div>
                                    <div className={ActiveProductTab[7] ? 'active_addproduct_category' : 'addproduct_category'}
                                            onClick={() => {
                                                setActiveProductTab([false, false, false, false, false, false, false, true])
                                                console.log(VariantData)
                                            }}
                                        ><i class="fa-solid fa-percent"></i>Tax</div>
                                    <div className={ActiveProductTab[8] ? 'active_addproduct_category' : 'addproduct_category'}
                                            onClick={() => {
                                                setActiveProductTab([false, false, false, false, false, false, false, false, true])
                                                console.log(VariantData)
                                            }}
                                        ><i class="fa-solid fa-percent"></i>Crosssell / Upsell</div>
                                </div>

                                {ActiveProductTab[0] ? <div className='editproduct_main_category_field'>
                                    <div className='editproduct_category_text_field'>
                                        <div className='editproduct_shipping_weight_title'>Category</div>
                                        <div class="dropdown">
                                            <button class="btn editproduct_detail_save_order_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                                onClick={() => {
                                                    var Url = window.API_PREFIX + 'products/get_category'
                                                    fetch(Url, {
                                                        method: 'POST',
                                                        headers: {
                                                            'Content-type': 'application/json',
                                                        },
                                                        body: JSON.stringify(
                                                            { "Token": localStorage.getItem("Token") }),
                                                    }).then(resp => resp.json()).then(data => {
                                                        console.log(data)
                                                        if (data['status'] == "1") {
                                                            seteditproductCategoryl([...data["AllCategory"]])
                                                            setSelectedOptioneditproductCategory(data["AllCategory"][0]["CategoryName"])
                                                        }
                                                    })
                                                }}
                                            >
                                                {selectedOptioneditproductCategory}
                                            </button>
                                            <ul class="dropdown-menu">
                                                {
                                                    editproductCategory.map((item) => {
                                                        return (
                                                            <li><a className={selectedOptioneditproductCategory === item.CategoryName ? "bgColorRed dropdown-item order_detail_save_order_dropdown_menu" : "dropdown-item order_detail_save_order_dropdown_menu"}
                                                                onClick={() => {
                                                                    setSelectedOptioneditproductCategory(item.CategoryName)
                                                                    setCategoryId(item.CategoryId)


                                                                    if (CategoryId != "-1") {

                                                                        var Url = window.API_PREFIX + 'products/get_subcategory'
                                                                        fetch(Url, {
                                                                            method: 'POST',
                                                                            headers: {
                                                                                'Content-type': 'application/json',
                                                                            },
                                                                            body: JSON.stringify(
                                                                                { "Token": localStorage.getItem("Token"), "Category_Id": CategoryId }),
                                                                        }).then(resp => resp.json()).then(data => {
                                                                            console.log(data)
                                                                            if (data['status'] == "1") {
                                                                                seteditproductSubCategoryl([...data["AllSubCategory"]])
                                                                                if (data["AllSubCategory"].length != 0) {
                                                                                    setSelectedOptioneditproductSubCategory(data["AllSubCategory"][0]["SubCategoryName"])
                                                                                    setSubCategoryId(data["AllSubCategory"][0]["SubCategoryId"])
                                                                                }
                                                                                else {
                                                                                    setSubCategoryWarn(true)
                                                                                    setSelectedOptioneditproductSubCategory("")
                                                                                    seteditproductSubCategoryl([])
                                                                                }
                                                                            }
                                                                        })
                                                                    }


                                                                }
                                                                }

                                                            >{item.CategoryName}</a></li>
                                                        )
                                                    }
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        {!AddCategoryFlag ? <div className='editproduct_add_icon'
                                            onClick={() => {
                                                setAddCategoryFlag(true)
                                            }}
                                        ><i class="fa-solid fa-circle-plus"></i></div> :
                                            <><input type="text" class="form-control editproductcategoryformControl" placeholder=""

                                                onChange={(e) => {
                                                    setAddCategory(e.target.value)

                                                }}
                                            ></input>
                                                <button className='editproduct_category_add_btn'
                                                    onClick={() => {
                                                        if (AddCategory.length != 0) {
                                                            var Url = window.API_PREFIX + 'products/add_category'
                                                            fetch(Url, {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-type': 'application/json',
                                                                },
                                                                body: JSON.stringify(
                                                                    { "Token": localStorage.getItem("Token"), "CategoryName": AddCategory }),
                                                            }).then(resp => resp.json()).then(data => {
                                                                console.log(data)
                                                                if (data['status'] == "1") {
                                                                    setAddCategory("")
                                                                    setAddCategoryFlag(false)
                                                                }
                                                            })

                                                        }
                                                    }}
                                                >ADD</button>
                                                <button className='editproduct_category_add_btn'
                                                    onClick={() => {
                                                        setAddCategoryFlag(false)
                                                    }}
                                                >CLEAR</button></>}
                                    </div>








                                    <div className='editproduct_shipping_text_field'>
                                        <div className='editproduct_shipping_weight_title'>SubCategory</div>
                                        <div class="dropdown">
                                            <button class="btn editproduct_detail_save_order_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                                onClick={() => {
                                                    if (CategoryId != "-1") {

                                                        var Url = window.API_PREFIX + 'products/get_subcategory'
                                                        fetch(Url, {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-type': 'application/json',
                                                            },
                                                            body: JSON.stringify(
                                                                { "Token": localStorage.getItem("Token"), "Category_Id": CategoryId }),
                                                        }).then(resp => resp.json()).then(data => {
                                                            console.log(data)
                                                            if (data['status'] == "1") {
                                                                seteditproductSubCategoryl([...data["AllSubCategory"]])
                                                                setSelectedOptioneditproductSubCategory(data["AllSubCategory"][0]["SubCategoryName"])
                                                                setSubCategoryId(data["AllSubCategory"][0]["SubCategoryId"])
                                                            }
                                                        })
                                                    }
                                                }}
                                            >
                                                {selectedOptioneditproductSubCategory}
                                            </button>
                                            <ul class="dropdown-menu">
                                                {
                                                    editproductSubCategory?.map((item) => {
                                                        return (
                                                            <li><a className={selectedOptioneditproductSubCategory === item.SubCategoryName ? "bgColorRed dropdown-item order_detail_save_order_dropdown_menu" : "dropdown-item order_detail_save_order_dropdown_menu"}
                                                                onClick={() => {
                                                                    setSelectedOptioneditproductSubCategory(item.SubCategoryName)
                                                                    setSubCategoryId(item.SubCategoryId)
                                                                }
                                                                }
                                                            >{item.SubCategoryName}</a></li>
                                                        )
                                                    }
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        {!AddSubCategoryFlag ? <div className='editproduct_add_icon'
                                            onClick={() => {
                                                setAddSubCategoryFlag(true)
                                            }}
                                        ><i class="fa-solid fa-circle-plus"></i></div> : <>
                                            <input type="text" class="form-control editproductcategoryformControl" placeholder=""
                                                onChange={(e) => {
                                                    setAddSubCategory(e.target.value)
                                                }}
                                            ></input>
                                            <button className='editproduct_category_add_btn'
                                                onClick={() => {
                                                    if (AddSubCategory.length != 0) {
                                                        var Url = window.API_PREFIX + 'products/add_subcategory'
                                                        fetch(Url, {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-type': 'application/json',
                                                            },
                                                            body: JSON.stringify(
                                                                { "Token": localStorage.getItem("Token"), "CategoryId": CategoryId, "SubCategoryName": AddSubCategory }),
                                                        }).then(resp => resp.json()).then(data => {
                                                            console.log(data)
                                                            if (data['status'] == "1") {
                                                                setAddSubCategory("")
                                                                setAddSubCategoryFlag(false)
                                                                setSubCategoryWarn(false)

                                                            }
                                                        })

                                                    }
                                                }}
                                            >ADD</button>
                                            <button className='editproduct_category_add_btn'
                                                onClick={() => {
                                                    setAddSubCategoryFlag(false)
                                                }}>CLEAR</button></>}
                                    </div>
                                </div> : null}

                                {CategoryId === -1 ? <div>Select Category First</div> : null}
                                {SubCategoryWarn ? <>No SubCategory found,Add SubCategory</> : null}
                                {SubSectionWarn ? <>No SubSectionfound,Add SubSection</> : null}



                                {ActiveProductTab[1] ? <div className='editproduct_main_general_field'>
                                    <div className='editproduct_price_tag'>
                                        <div className='editproduct_general_field' >
                                            <label class="editproduct-form-label">Regular price (₹)</label>
                                            <label class="editproduct-form-label">Sale price (₹)</label>
                                            {PriceScheduler ? <label class="editproduct-form-label">Sale price dates</label> : null}
                                        </div>

                                        <div className='editproduct_general_field1'>
                                            <input type="number" class="editproductregularpriceformControl" placeholder="" value={RegularPrice}
                                                onChange={(e) => {
                                                    setRegularPrice(e.target.value)
                                                    console.log(RegularPrice)
                                                }}></input>
                                            <input type="number" class="editproductregularpriceformControl" placeholder="" value={SalePrice}
                                                onChange={(e) => {
                                                    setSalePrice(e.target.value)
                                                    console.log(SalePrice)
                                                }}
                                            ></input>


                                            <div className='editproduct_inventory_manage_stock'>Is Offer active</div>
                                            <input class="form-check-input" type="checkbox" name="inlineCheckOptions" defaultChecked={IsOffer}
                                                onClick={(e) => {
                                                    console.log(IsOffer)
                                                    setIsOffer(!IsOffer)

                                                }}
                                            ></input>
                                            <label class="editproduct-inventory-form-check-label">Enable is offer active for discount price</label>

                                            {PriceScheduler ? <>
                                                <div class="">
                                                    <input type="date" class="editproductregularpriceformControl" placeholder="DD/MM/YYYY" value={SalePriceDateStart}
                                                        onChange={(e) => {
                                                            setSalePriceDateStart(e.target.value)
                                                            console.log(SalePriceDateStart)
                                                        }}
                                                    ></input>
                                                </div>

                                                <div class="">
                                                    <input type="date" class="editproductregularpriceformControl" placeholder="From DD/MM/YYYY" value={SalePriceDateEnd}
                                                        onChange={(e) => {
                                                            setSalePriceDateEnd(e.target.value)
                                                            console.log(SalePriceDateEnd)
                                                        }}
                                                    ></input>
                                                </div></> : <></>}
                                        </div>

                                        <div className='editproduct_schedule_clear'>
                                            <div className='editproduct_schedule'
                                                onClick={(e) => {
                                                    setPriceScheduler(true)
                                                }}
                                            ><u>Schedule</u></div>
                                            {PriceScheduler ? <div className='editproduct_clear'
                                                onClick={(e) => {
                                                    setPriceScheduler(false)
                                                    setSalePriceDateStart("")
                                                    setSalePriceDateEnd("")
                                                }}
                                            ><u>Clear</u></div> : null}
                                        </div>
                                    </div>
                                </div> : null
                                }

                                {ActiveProductTab[2] ? <div className='editproduct_main_inventory_field'>
                                    <div className='editproduct_inventory_text_field'>
                                        <div className='editproduct_inventory_sku_title'
                                        >SKU</div>
                                        <input type="text" class="editproductinventoryformControl" placeholder="" value={SKU}
                                            onChange={(e) => {
                                                setSKU(e.target.value)
                                                console.log(SKU)
                                            }}></input>


                                        <div><i class="fa-solid fa-circle-question"></i></div>
                                    </div>

                                    <div className='editproduct_inventory_text_field'>
                                        <div className='editproduct_inventory_sku_title'
                                        >Menu Order</div>
                                        <input type="number" class="editproductinventoryformControl" placeholder="" value={MenuOrder}
                                            onChange={(e) => {
                                                setMenuOrder(e.target.value)

                                            }}></input>


                                        <div><i class="fa-solid fa-circle-question"></i></div>
                                    </div>

                                    <div className='editproduct_inventory_stock_status_field'>
                                        <div className='editproduct_inventory_stock_status'>Stock status</div>
                                        <select class="editproducttaxformControl" aria-label=".form-select-sm example">
                                            <option selected>In stock</option>
                                            <option value="1">Out of stock</option>
                                            <option value="2">On backorder</option>
                                        </select>
                                    </div>

                                </div> : null}

                                {ActiveProductTab[3] ? <div className='addprduct_main_shipping_field'>
                                    <div className='addproduct_shipping_text_field'>
                                        <div className='addproduct_shipping_weight_title'>Meta Title</div>
                                        <input type="text" class="addproductshippingformControl" placeholder="" value={MetaTitle}
                                            onChange={(e) => {
                                                setMetaTitle(e.target.value)
                                                console.log(MetaTitle)
                                            }}></input>
                                        <div><i class="fa-solid fa-circle-question"></i></div>
                                    </div>
                                    <div className='addproduct_shipping_text_field'>
                                        <div className='addproduct_shipping_weight_title'>Meta Keyword</div>
                                        <input type="text" class="addproductshippingformControl" placeholder="" value={MetaKeyword}
                                            onChange={(e) => {
                                                setMetaKeyword(e.target.value)
                                                console.log(MetaKeyword)
                                            }}></input>
                                        <div><i class="fa-solid fa-circle-question"></i></div>
                                    </div>
                                    <div className='addproduct_shipping_text_field'>
                                        <div className='addproduct_shipping_weight_title'>Meta Description</div>
                                        <input type="text" class="addproductshippingformControl" placeholder="" value={MetaDescription}
                                            onChange={(e) => {
                                                setMetaDescription(e.target.value)
                                                console.log(MetaDescription)
                                            }}></input>
                                        <div><i class="fa-solid fa-circle-question"></i></div>
                                    </div>


                                </div> : null}



                                {ActiveProductTab[4] ? <div className='editproduct_main_shipping_field'>
                                    <div className='editproduct_shipping_text_field'>
                                        <div className='editproduct_shipping_weight_title'>Weight (kg)</div>
                                        <input type="number" class="editproductshippingformControl" placeholder="" value={Weight}
                                            onChange={(e) => {
                                                setWeight(e.target.value)
                                                console.log(Weight)
                                            }}></input>
                                        <div><i class="fa-solid fa-circle-question"></i></div>
                                    </div>

                                    <div className='editproduct_shipping_dimension_text_field'>
                                        <div className='editproduct_shipping_dimension_title'>Dimensions (cm)</div>
                                        <input type="number" class="editproductshippingdimensionformControl" placeholder="Length" value={Length}
                                            onChange={(e) => {
                                                setLenght(e.target.value)
                                                console.log(Length)
                                            }}></input>
                                        <input type="number" class="editproductshippingdimensionformControl" placeholder="width" value={width}
                                            onChange={(e) => {
                                                setwidth(e.target.value)
                                                console.log(width)
                                            }}></input>
                                        <input type="number" class="editproductshippingdimensionformControl" placeholder="height" value={height}
                                            onChange={(e) => {
                                                setheight(e.target.value)
                                                console.log(height)
                                            }}></input>
                                        <div><i class="fa-solid fa-circle-question"></i></div>
                                    </div>

                                </div> : null}




                                {ActiveProductTab[5] ? <div className='editproduct_main_linked_product_field'>
                                    <div className='editproduct_linked_product_text_field'>
                                        <div className='editproduct_linked_product_upsell_title'>Upsales</div>
                                        <input type="text" class="editproductlinkedproductformControl" placeholder="Search for a product.."
                                            onChange={(e) => {
                                                setUpsales(e.target.value)
                                                console.log(Upsales)
                                            }}
                                        ></input>
                                        <div><i class="fa-solid fa-circle-question"></i></div>
                                    </div>

                                    <div className='editproduct_shipping_text_field'>
                                        <div className='editproduct_shipping_cross_sells_title'>Cross-sells</div>
                                        <input type="text" class="editproductlinkedproductformControl" placeholder="Search for a product.."
                                            onChange={(e) => {
                                                setcrosssales(e.target.value)
                                                console.log(crosssales)
                                            }}></input>
                                        <div><i class="fa-solid fa-circle-question"></i></div>
                                    </div>
                                </div> : null}

                                {ActiveProductTab[6] ? <div className='addprduct_main_linked_product_field'>
                                    <Popover
                                        content={content}
                                        // title="ssss"
                                        trigger="click"
                                        open={open}
                                        onOpenChange={handleOpenChange}
                                    >
                                        <Button style={{ background: '#483a15', borderColor: '#483a15' }} type="primary">New variant</Button>
                                    </Popover>

                                    <div >
                                        <Form form={form} component={false}>
                                            <Table className='mt-3'
                                                rowClassName="editable-row"
                                                columns={mergedColumns}
                                                dataSource={VariantData}
                                                components={{
                                                    body: {
                                                        cell: EditableCell,
                                                    },
                                                }}
                                                size="middle" />
                                        </Form>
                                    </div>
                                </div> : null}


                                {ActiveProductTab[7] ? <div className='d-flex tax_main_div '>

                                    <div class="dropdown">
                                        <button class="btn dropdown-toggle addproduct_detail_save_order_dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            GST%
                                        </button>
                                        <ul class="dropdown-menu mx-2">
                                            <li><a class="dropdown-item" href="#" onClick={() => {
                                                setTax(5)
                                            }}>5%</a></li>
                                            <li><a class="dropdown-item" href="#"
                                                onClick={() => {
                                                    setTax(12)
                                                }}>12%</a></li>
                                            <li><a class="dropdown-item" href="#"
                                                onClick={() => {
                                                    setTax(18)
                                                }}>18%</a></li>
                                            <li><a class="dropdown-item" href="#"
                                                onClick={() => {
                                                    setTax(28)
                                                }}>28%</a></li>

                                        </ul>
                                        {Tax}%
                                    </div>



                                </div>: null}

                                {ActiveProductTab[8] ?
                                        <div style={{ width: "250px" }}>
                                           
                                           <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            defaultValue={defaultOption}
                                            isMulti
                                            options={options}
                                            onInputChange={(inputValue, { action }) => {
                                                if (action === 'input-change') {
                                                    setSearchWord(inputValue);
                                        
                                                    if (inputValue.length > 0) {
                                                        const Url = window.API_PREFIX + 'products/search';
                                                        fetch(Url, {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-type': 'application/json',
                                                            },
                                                            body: JSON.stringify({ "SearchProducts": inputValue }),
                                                        })
                                                        .then(resp => resp.json())
                                                        .then(data => {
                                                            if (data['status'] === "1") {
                                                                setsearchResultData({ ...data["SearchResult"] });
                                                                if (data['SearchResult']['ProductName'] !== 0 || data['SearchResult']['SubCategoryName'] !== 0 || data['SearchResult']['CategoryName'] !== 0 || data['SearchResult']['SubSectionName'] !== 0) {
                                                                    setshowsearch(true);
                                                                } else {
                                                                    setshowsearch(false);
                                                                }
                                                            }
                                                        })
                                                        .catch(error => {
                                                            console.error('Error searching products:', error);
                                                        });
                                                    } else {
                                                        setsearchResultData({});
                                                        setshowsearch(false);
                                                    }
                                                }
                                            }}
                                            onChange={handleCrossChange}
                                            placeholder={'search cross sell'}
                                            />

                                            <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            // defaultValue={[colourOptions[4], colourOptions[5]]}
                                            isMulti
                                            options={options}
                                            onInputChange={(inputValue, { action }) => {
                                                if (action === 'input-change') {
                                                    setSearchWord(inputValue);
                                        
                                                    if (inputValue.length > 0) {
                                                        const Url = window.API_PREFIX + 'products/search';
                                                        fetch(Url, {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-type': 'application/json',
                                                            },
                                                            body: JSON.stringify({ "SearchProducts": inputValue }),
                                                        })
                                                        .then(resp => resp.json())
                                                        .then(data => {
                                                            if (data['status'] === "1") {
                                                                setsearchResultData({ ...data["SearchResult"] });
                                                                if (data['SearchResult']['ProductName'] !== 0 || data['SearchResult']['SubCategoryName'] !== 0 || data['SearchResult']['CategoryName'] !== 0 || data['SearchResult']['SubSectionName'] !== 0) {
                                                                    setshowsearch(true);
                                                                } else {
                                                                    setshowsearch(false);
                                                                }
                                                            }
                                                        })
                                                        .catch(error => {
                                                            console.error('Error searching products:', error);
                                                        });
                                                    } else {
                                                        setsearchResultData({});
                                                        setshowsearch(false);
                                                    }
                                                }
                                            }}
                                            onChange={handleUpChange}
                                            placeholder={'search upsell'}
                                            />
                                        </div>

                                        : null}
                            </div>
                        </div>

    
                    </div>
                    {/* <CKEditor
                                editor={ClassicEditor}
                                data={LongDes}
                                onReady={editor => {
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    // console.log( { event, editor, data } );
                                    setLongDes(data)

                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                            /> */}
                    
                    <div className='editproduct_main_Social_division'>
                        <div className='editproduct_main_product_image'>
                            <div className='editproduct_publish_data'>
                                <div className='editproduct_publish_title'>Product Image</div>

                            </div>

                            <div className='editproduct_set_product_image'>

                                <Popup
                                    modal

                                    contentStyle={ImagePopStyle}
                                    open={ImgSelectPop}
                                    closeOnDocumentClick
                                    onClose={() => {
                                        setImgSelectPop(false)

                                    }}

                                >


                                    <div>
                                        <div className='grid-container'>
                                            {AllImgData?.map((eachImg, ImgIndex) => {

                                                return (<div className='editproduct_image_photo_division'>
                                                    <div>
                                                        <div className='editproduct_image_gallery_division'>
                                                            <img className='editproduct_galleryimg' src={eachImg['ImgAddress']}
                                                                onClick={() => {
                                                                    setImgAddress(eachImg['ImgAddress'])
                                                                    setImgId(eachImg['ImgId'])
                                                                    setImgSelectPop(false)
                                                                }}
                                                            />
                                                            <div className='editproduct_close_division'
                                                                onClick={() => {
                                                                    var Url = window.API_PREFIX + 'products/delete_productImg'
                                                                    fetch(Url, {
                                                                        method: 'POST',
                                                                        headers: {
                                                                            'Content-type': 'application/json',
                                                                        },
                                                                        body: JSON.stringify(
                                                                            { "Token": localStorage.getItem("Token"), "ImgId": eachImg["ImgId"] }),
                                                                    }).then(resp => resp.json()).then(data => {
                                                                        console.log(data)
                                                                        if (data['status'] == "1") {
                                                                            var tmp = AllImgData
                                                                            tmp.splice(ImgIndex, 1)
                                                                            console.log("Delete", tmp)
                                                                            setAllImgData([...tmp])

                                                                        }
                                                                    })
                                                                }}
                                                            ><i class="fa-solid fa-xmark"></i></div>
                                                        </div>
                                                        <div className='editproduct_image_name'>{eachImg['Name']}</div>
                                                    </div>
                                                </div>)
                                            })}

                                        </div>
                                    </div>
                                </Popup>
                                {/* <u
                                    onClick={() => {

                                        if (!ImgSelectPop) {
                                            var Url = window.API_PREFIX + 'products/get_productImg'
                                            fetch(Url, {
                                                method: 'POST',
                                                headers: {
                                                    'Content-type': 'application/json',
                                                },
                                                body: JSON.stringify(
                                                    { "Token": localStorage.getItem("Token") }),
                                            }).then(resp => resp.json()).then(data => {
                                                console.log(data)
                                                if (data['status'] == "1") {
                                                    setAllImgData([...data["AllImgData"]])
                                                }
                                            })
                                        }
                                        setImgSelectPop(true)

                                    }}
                                >Set product image </u> */}

                                {ImgAddress != "" ? <img className='editproduct_galleryimg1' src={ImgAddress} /> : null}
                                <input
                                    className='editproduct_input_file'
                                    style={{ "cursor": "pointer" }}
                                    type="file"
                                    name="myImage"
                                    onChange={(event) => {

                                        setSelectedImage(event.target.files[0]);

                                        var uploadData = new FormData();
                                        uploadData.append("Token", localStorage.getItem("Token"));
                                        // uploadData.append("FirstName", FirstName);
                                        // uploadData.append("LastName", LastName);
                                        // uploadData.append("Email", Email);
                                        // uploadData.append("PhoneNumber", MobileNumber);
                                        uploadData.append("ProductImg", event.target.files[0]);


                                        var url = window.API_PREFIX + "products/add_productImg";


                                        fetch(url, {
                                            method: 'POST',
                                            body: uploadData
                                        }).then(resp => resp.json()).then(data => {

                                            if (data['status'] == "1") {
                                                setImgId(data["ImgId"])
                                                console.log(data["ImgId"])
                                                setImgAddress(data['ImgAddress'])
                                                // window.location.reload()
                                                // setresponseData({...data["detail"]})

                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>









                        <div className='editproduct_main_product_gallery'>
                            <div className='editproduct_publish_data'>
                                <div className='editproduct_publish_title'>Product gallery</div>


                            </div>



                            <Popup
                                modal

                                contentStyle={ImagePopStyle}
                                open={MImgSelectPop}
                                closeOnDocumentClick
                                onClose={() => {
                                    setMImgSelectPop(false)

                                }}

                            >


                                <div>
                                    <div className='grid-container'>
                                        {AllMImgData?.map((eachImg, ImgIndex) => {

                                            return (<div className='editproduct_image_photo_division'>
                                                <div>
                                                    <div className='editproduct_image_gallery_division'>
                                                        <img className='editproduct_galleryimg' src={eachImg['ImgAddress']}
                                                            onClick={() => {
                                                                var tmpImgAddress = MultiImgAddress
                                                                tmpImgAddress.push(eachImg['ImgAddress'])
                                                                setMultiImgAddress([...tmpImgAddress])
                                                                var tmpImgId = MultiImgId
                                                                tmpImgId.push(eachImg['ImgId'])
                                                                setMultiImgId([...tmpImgId])
                                                                setMImgSelectPop(false)
                                                            }}
                                                        />
                                                        <div className='editproduct_close_division'
                                                            onClick={() => {
                                                                var Url = window.API_PREFIX + 'products/delete_productImg'
                                                                fetch(Url, {
                                                                    method: 'POST',
                                                                    headers: {
                                                                        'Content-type': 'application/json',
                                                                    },
                                                                    body: JSON.stringify(
                                                                        { "Token": localStorage.getItem("Token"), "ImgId": eachImg["ImgId"] }),
                                                                }).then(resp => resp.json()).then(data => {
                                                                    console.log(data)
                                                                    if (data['status'] == "1") {
                                                                        var tmp = AllMImgData
                                                                        tmp.splice(ImgIndex, 1)
                                                                        console.log("Delete", tmp)
                                                                        setAllMImgData([...tmp])
                                                                        setMultiImgAddress([])
                                                                        setMultiImgId([])

                                                                    }
                                                                })
                                                            }}
                                                        ><i class="fa-solid fa-xmark"></i></div>
                                                    </div>
                                                    <div className='editproduct_image_name'>{eachImg['Name']}</div>
                                                </div>
                                            </div>)
                                        })}

                                    </div>
                                </div>
                            </Popup>
                            {/* <div className='editproduct_set_product_gallery'
                                onClick={() => {

                                    if (!MImgSelectPop) {
                                        var Url = window.API_PREFIX + 'products/get_productImg'
                                        fetch(Url, {
                                            method: 'POST',
                                            headers: {
                                                'Content-type': 'application/json',
                                            },
                                            body: JSON.stringify(
                                                { "Token": localStorage.getItem("Token") }),
                                        }).then(resp => resp.json()).then(data => {
                                            console.log(data)
                                            if (data['status'] == "1") {
                                                setAllMImgData([...data["AllImgData"]])
                                            }
                                        })
                                    }
                                    setMImgSelectPop(true)

                                }}

                            ><u>Set product gallery images</u>
                            
                            
                        </div> */}

                            <div className='edit_multi_product_galleryimg_grid'>
                                <div className='editproduct_galleryimg'>

                                    {MultiImgAddress.length != 0 ? MultiImgAddress.map((eachImage, ImgIndex) => {
                                        return (
                                            <img alt='hh' className='edit_multi_product_galleryimg' src={eachImage}
                                                onClick={() => {
                                                    var tmpImgAddress = MultiImgAddress
                                                    tmpImgAddress.splice(ImgIndex, 1)
                                                    setMultiImgAddress([...tmpImgAddress])
                                                    var tmpImgId = MultiImgId
                                                    tmpImgId.splice(ImgIndex, 1)
                                                    setMultiImgId([...tmpImgId])
                                                }}

                                            />)

                                    }) : null}

                                </div>
                            </div>
                            <input
                                className='editproduct_input_file'
                                style={{ "cursor": "pointer", "marginLeft": "10px" }}
                                type="file"
                                accept='image/png/jpeg'

                                name="myImage"
                                multiple
                                onChange={(event) => {

                                    setSelectedImage(event.target.files);

                                    const chosenFiles = Array.prototype.slice.call(event.target.files)
                                    var uploadData = new FormData();
                                    uploadData.append("Token", localStorage.getItem("Token"));
                                    var imgList = []
                                    for (var i = 0; i < event.target.files.length; i++) {
                                        uploadData.append("MultiProductImg" + i, event.target.files[i]);

                                        imgList.push(event.target.files[i])
                                    }



                                    var url = window.API_PREFIX + "products/add_multiproductImg";


                                    fetch(url, {
                                        method: 'POST',
                                        body: uploadData
                                    }).then(resp => resp.json()).then(data => {

                                        if (data['status'] == "1") {
                                            console.log(data['ImgId'])
                                            console.log(data['ImgAddress'])
                                            // setMultiImgId([...data["ImgId"]])
                                            var tmp = MultiImgId
                                            tmp = [...tmp, ...data['ImgId']]
                                            console.log(tmp)

                                            setMultiImgId([...tmp])

                                            var tmp2 = MultiImgAddress
                                            tmp2 = [...tmp2, ...data['ImgAddress']]

                                            setMultiImgAddress([...tmp2])

                                            // setMultiImgAddress([...data['ImgAddress']])
                                            // window.location.reload()
                                            // setresponseData({...data["detail"]})

                                        }
                                    })
                                }}
                            />
                        </div>



                        <div className='editproduct_publish_details_field'>
                            <div className='editproduct_publish_field_button'>
                                <button type="button" class="editproduct-btn-save-draft"
                                    onClick={() => PublishProduct(false)}

                                >Save Draft</button>
                            </div>

                            <div className='editproduct_move_button'>

                                <button type="button" class="editproduct-btn-publish"
                                    onClick={() => PublishProduct(true)}

                                >Publish</button>

                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </>
    )
}

export default EditProduct;