// import logo from './logo.svg';
import './App.css';
import React from 'react'
import { useEffect } from 'react';
// import '../src/components/User/UserComponent/Base.css';
// import '../src/components/Admin/AdminComponent/AdminBase.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
// import REACTGA from 'react-ga'
// import { Crisp } from "crisp-sdk-web";

import 'reactjs-popup/dist/index.css';

import Userlayout from './components/User/Userlayout';
import Adminlayout from './components/Admin/Adminlayout';
import ATest from './components/Admin/AdminComponent/ATest';
import VerticalProductCards from './components/User/UserComponent/VerticalProductCards/VerticalProductCards'
import Productpage from './components/User/UserComponent/ProductPage/Productpage'
import MyWishlist from './components/User/UserComponent/WishList/MyWishlist';
import Address from './components/User/UserComponent/UserAddress/Address'
import AddressBreadcrums from './components/User/UserComponent/UserAddress/AddressBreadcrums'
import UserProfile from './components/User/UserComponent/UserProfile/UserProfile'
import 'antd/dist/antd.css'
import Allproducts from './components/Test/Allproducts';
import AddProduct from './components/Admin/AdminComponent/AddProduct/AddProduct'
import EditProduct from './components/Admin/AdminComponent/EditProduct/EditProduct'
import AllProduct from './components/Admin/AdminComponent/AllProduct/Allproducts'
import AllUser from './components/Admin/AdminComponent/AllUser/Alluser'
import OrderDetailone from './components/Admin/AdminComponent/Order/OrderDetailone';
import Order from './components/Admin/AdminComponent/Order/Order';
import KeyWord from './components/Admin/AdminComponent/Analysis/KeyWord';
import Checkout from './components/User/UserComponent/Checkout/Checkout';
import MyOrder from './components/User/UserComponent/UserOrder/MyOrder'
import HomeLayout from './components/User/HomeLayout'
import Specification from './components/Admin/AdminComponent/AddSpec/Specification';
import Slider1 from './components/Admin/AdminComponent/AdminHomePage/Slider1'
import Slider2 from './components/Admin/AdminComponent/AdminHomePage/Slider2'
import SliderMobile from './components/Admin/AdminComponent/AdminHomePage/SliderMobile'
import CategoryImage from './components/Admin/AdminComponent/AdminHomePage/CategoryImage';
import Promocode from './components/Admin/AdminComponent/Promocode/Promocode';
import AddPromocode from './components/Admin/AdminComponent/Promocode/AddPromocode';
import EditPromocode from './components/Admin/AdminComponent/Promocode/EditPromocode';
import OrderReceived from './components/User/UserComponent/UserOrder/OrderReceived';
import OrderReceived2 from './components/User/UserComponent/UserOrder/OrderReceived2';
import ComparePage from './components/User/UserComponent/Compare/ComparePage';
import Blogs from './components/User/UserComponent/Blogs/Blogs';
import ContactUs from './components/User/Userheader/ContactUs';
import Allusercart from './components/Admin/AdminComponent/AllUser/Allusercart';
import Allusercarthistory from './components/Admin/AdminComponent/AllUser/Allusercarthistory';
import Termsandconditions from './components/Admin/AdminComponent/Termsandconditions';
import Privacypolicy from './components/Admin/AdminComponent/Privacypolicy';
import Deleteaccount from './components/Admin/AdminComponent/Deleteaccount';
import Shippingpolicy from './components/Admin/AdminComponent/Shippingpolicy';
import Returnandrefund from './components/Admin/AdminComponent/Returnandrefund';
import Edituser from './components/Admin/AdminComponent/AllUser/Edituser';
import Addnotifications from './components/Admin/AdminComponent/Addnotifications/Addnotifications';
import Dashboard from './components/Admin/AdminComponent/Dashboard/Dashboard';
import Admincontacts from './components/Admin/AdminComponent/Admincontacts/Admincontacts';
import Blogtwo from './components/User/UserComponent/Blogs/Blogtwo';
import Adminblogs from './components/Admin/AdminComponent/Adminblogs/Adminblogs';
import ScrollToTop from "./components/ScrollToTop";
import Headertwo from './components/User/Userheader/Headertwo';
import Thankupage from './components/User/UserComponent/Checkout/Thankupage';
import DeleteRequest from './components/Admin/AdminComponent/DeleteRequest/DeleteRequest';
import RefundRequest from './components/Admin/AdminComponent/RefundRequest/RefundRequest';
import Forgetpassword from './components/Admin/AdminComponent/Forgetpassword/Forgetpassword';
import Newsletter from './components/Admin/AdminComponent/Newsletter/Newsletter';

// window.API_PREFIX = process.env.REACT_APP_BACKENDURL
// window.API_PREFIX = "http://65.1.131.160:8000/"
// console.log(window.API_PREFIX)
//window.API_PREFIX = "https://apiaura.kodenauts.com/"
window.API_PREFIX = "https://api.pankajjewellers.co/"

// window.API_PREFIX = "http://127.0.0.1:8000/"
// window.API_PREFIX = "http://192.168.0.110:8000/"

window.WEBSOC = "wss://" + String(window.API_PREFIX).split("//")[1]


// const TRACKING_ID = process.env.REACT_APP_GOOGLE_TAG
// REACTGA.initialize(TRACKING_ID)
function App() {
  useEffect(() => {

    // Crisp.configure(process.env.REACT_APP_CRISPKEY);
    // REACTGA.pageview(window.location.pathname)
  }, [])
  return (

    <>
      <Routes>

        <Route path="/" element={<Userlayout />} >
          {/* <Route path="test" element={<UTest />} /> */}
          <Route path="" element={<HomeLayout />} />
          <Route path="filter/:Filterparam" element={<VerticalProductCards />} />
          <Route path="product/:PID" element={<Productpage />} />
          <Route path="product_/:PName" element={<Productpage />} />
          <Route path="wishlist" element={<MyWishlist />} />
          <Route path="Address" element={<Address />} />
          <Route path="userprofile" element={<UserProfile />} />
          <Route path="addressbreadcrums" element={<AddressBreadcrums />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="thankyou" element={<Thankupage />} />
          <Route path="myorder" element={<MyOrder />} />
          <Route path="order_received" element={<OrderReceived />} />
          <Route path="compare" element={<ComparePage />} />
          <Route path="order_received2" element={<OrderReceived2 />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="blogs/:bid" element={<Blogtwo />} />
          <Route path="terms-and-conditions" element={<Termsandconditions />} />
          <Route path="privacy-policy" element={<Privacypolicy />} />
          <Route path="delete-account" element={<Deleteaccount />} />
          <Route path="shipping-policy" element={<Shippingpolicy />} />
          <Route path="return-and-refund-policy" element={<Returnandrefund />} />
          <Route path="/contactus" element={<ContactUs />} />
        </Route>

        <Route path="/administration" element={<Adminlayout />} >
          <Route path="test1" element={<ATest />} />
          <Route path="addproduct" element={<AddProduct />} />
          <Route path="editproduct/:PID" element={<EditProduct />} />
          <Route path="specification/:PID" element={<Specification />} />
          <Route path="editproduct" element={<Navigate to="/administration/allproduct" />} />
          <Route path="specification" element={<Navigate to="/administration/allproduct" />} />
          <Route path='contact-details' element={<Admincontacts />} />
          <Route path='delete-request' element={<DeleteRequest />} />
          <Route path='refund-request' element={<RefundRequest />} />
          <Route path='newsletter' element={<Newsletter />} />

          <Route path="allUser" element={<AllUser />} />
          <Route path="edituser/:UID" element={<Edituser />} />
          <Route path='promocode' element={<Promocode />} />
          <Route path='addpromocode' element={<AddPromocode />} />
          <Route path='addblogs' element={<Adminblogs />} />
          <Route path='editpromocode/:CID' element={<AddPromocode />} />
          <Route path='editpromocode' element={<Navigate to="/administration/promocode" />} />
          <Route path="allproduct" element={<AllProduct />} />
          <Route path="allOrder" element={<Order />} />
          <Route path="keywords-Analysis" element={<KeyWord />} />
          <Route path="allOrder/users/:UID" element={<Order />} />
          <Route path="OrderDetail/:OID" element={<OrderDetailone />} />
          <Route path="slider1" element={<Slider1 />} />
          <Route path="slider2" element={<Slider2 />} />
          <Route path="slidermobile" element={<SliderMobile />} />
          <Route path="addnotifications" element={<Addnotifications />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="categoryimage" element={<CategoryImage />} />
          <Route path="allUsercart/:NameId" element={<Allusercart />} />

          <Route path="allUsercarthistory/:NameId" element={<Allusercarthistory />} />
          <Route path="allUsercart" element={<Navigate to="/administration/allUser" />} />
          <Route path="allUsercarthistory" element={<Navigate to="/administration/allUser" />} />

          keywords-Analysis


        </Route>


        <Route path='forget-password' element={<><Forgetpassword /></>} />
        <Route path="/test" element={<Allproducts />} >
          {/* <Route  path="1" element={<ATest/>} /> */}

        </Route>
      </Routes>

      <ScrollToTop />
    </>
  );
}

export default App;
