import './ComparePopup.css';
import comparepopupimg from '../../images/images (1).jpg';
import { useStateValue } from '../StateProviders';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
function ComparePopup() {
  let navigate = useNavigate()
  const [{ ComparePop, CompareVar }, dispatch] = useStateValue();
  const [compareData, setcompareData] = useState([]);
  const [empty, setempty] = useState([]);
  const [comparedetail, setcomparedetail] = useState('')


  useEffect(() => {

    var Data = {}
    if (localStorage.getItem('Token') != null) {



      Data['Token'] = localStorage.getItem('Token')

      var Url = window.API_PREFIX + 'products/viewcompare'
      fetch(Url, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(
          Data
        ),
      }).then(resp => resp.json()).then(data => {
        console.log(data)

        if (data['status'] === "1") {

          setcompareData([...data['products']])

          if (data['products'].length === 0) {
            dispatch({ type: 'CloseComparePop' })
          }
          setcomparedetail(`/filter/Category&${data['categoryname']}&${data['categoryid']}`)

        }
        var tmp = [0, 0, 0]
        for (var i = 0; i < data['products'].length; i++) {
          tmp.pop()
        }
        setempty([...tmp])
      })



    }





  }, [CompareVar]
  )
  return (
    <>
      <div>
        <div className='comaparepopup_main_division'>
          <div className='comparepopup_close_icon'
            onClick={() => {
              dispatch({ type: 'CloseComparePop' })
            }}
          ><i class="fa-solid fa-xmark"></i></div>

          <div className='comparepopup_sub_division'>
            <div className='comparepopup_first_division'>

              {compareData.map((eachCompare, CompareIndex) => {
                return (
                  <div className='comparepopup_select_img_card_division'>
                    <div className='comparepopup_img_card_division'>
                      <div className='comparepoup_card_close_btn'
                        onClick={() => {
                          var Url = window.API_PREFIX + 'products/removeonecompare'
                          fetch(Url, {
                            method: 'POST',
                            headers: {
                              'Content-type': 'application/json',
                            },
                            body: JSON.stringify(
                              {
                                Token: localStorage.getItem('Token'),
                                ProductId: String(eachCompare.ProductId)
                              }
                            ),
                          }).then(resp => resp.json()).then(data => {
                            console.log(data)
                            if (data['status'] === "1") {
                              dispatch({ type: 'UpdateCompare' })
                            }
                          })

                        }}
                      ><i class="fa-solid fa-xmark"></i></div>
                      <div className='d-flex justify-content-center w-100'>
                        <div className='comparepopup_compare_Card_second_division'><img className='comparepopup_img_mobile' src={eachCompare['ProductImg']} /></div>
                      </div>
                      <div className='comparepopup_item_title_name'>{eachCompare['Product_Name']}</div>
                      {eachCompare['isOffer'] ? <div className='comparepopup_item_price'>₹{eachCompare['DiscountPrice']}</div> : <div className='comparepopup_item_price'>₹{eachCompare['OriginalPrice']}</div>}
                    </div>
                  </div>
                )
              })}

              {empty.map(() => {
                return (
                  <div className='comparepopup_select_img_card_division'
                    onClick={() => {
                      navigate(comparedetail)
                    }}
                  ><i class="fa-solid fa-plus"></i></div>

                )
              })}


              {/* <div className='comparepopup_select_img_card_division'><i class="fa-solid fa-plus"></i></div> */}
              {/* <div className='comparepopup_select_img_card_division'><i class="fa-solid fa-plus"></i></div> */}
            </div>

            <div className='comparepopup_second_division'>
              <div>
                <div><button className='comparepopup_compare_btn'
                  onClick={() => {

                    dispatch({ type: 'CloseComparePop' })

                    navigate('/compare')
                  }}
                >Compare</button></div>
                <button className='comparepopup_clearall_btn'
                  onClick={() => {
                    var Url = window.API_PREFIX + 'products/removeallcompare'
                    fetch(Url, {
                      method: 'POST',
                      headers: {
                        'Content-type': 'application/json',
                      },
                      body: JSON.stringify(
                        {
                          Token: localStorage.getItem('Token'),

                        }
                      ),
                    }).then(resp => resp.json()).then(data => {
                      console.log(data)
                      if (data['status'] === "1") {
                        dispatch({ type: 'CloseComparePop' })

                      }
                    })





                  }}
                >Clear All</button>
              </div>
            </div>
          </div>
        </div>


        {/* card code */}


        {/* <div className='comparepopup_img_card_division'>
                    <div className='comparepoup_card_close_btn'><i class="fa-solid fa-xmark"></i></div>
                    <div className='comparepopup_compare_Card_second_division'><img className='comparepopup_img_mobile' src={comparepopupimg} /></div>
                    <div className='comparepopup_item_title_name'>Refurbished OnePlus 7 (6GB RAM, 128GB, Blue)</div>
                    <div className='comparepopup_item_price'>₹18899</div>
                </div> */}
      </div>
    </>
  )
}

export default ComparePopup;