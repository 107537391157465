import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { format } from 'date-fns'
import './OrderReceived.css';
import {
    Stepper,
    Step,
    useStepper,
    StepNumber,
    StepTitle,
    StepStatus,
    StepDescription,
} from "react-progress-stepper";


function OrderReceived2() {
    // const {state} = useLocation();
    let navigate = useNavigate();
    // var today = new Date(state['Date']);
    var today = new Date();

    // Getting full month name (e.g. "June")
    var month = today.toLocaleString('default', { month: 'long' });


    var orderDate = format(today, 'dd,yyyy');


    useEffect(() => {

    }, [])
    return (
        <>
            <div>
                <div className='order_received_main_division'>
                    <div className='order_received_main_title'>Order received</div>
                    <div className='order_recevied_go_to_home'
                        onClick={() => navigate('/')}
                    >GO TO HOMEPAGE</div>
                    <div className='order_recevied_order_msg'>Thank you. Your order has
                        been received.</div>

                    <div className='order_recevied_sub_main_division'>
                        <div className='order_recevied_order_detail_division'>
                            <div className='order_recevied_order_number_title'>ORDER NUMBER :</div>
                            <div className='order_recevied_order_number'>--OrderId--</div>
                        </div>

                        <div className='order_recevied_order_detail_division'>
                            <div className='order_recevied_order_number_title'>DATE :</div>
                            <div className='order_recevied_order_number'>{month} , {orderDate}</div>
                        </div>

                        <div className='order_recevied_order_detail_division'>
                            <div className='order_recevied_order_number_title'>EMAIL :</div>
                            <div className='order_recevied_order_number'>--UserEmail--</div>
                        </div>
                        <div className='order_recevied_order_detail_division'>
                            <div className='order_recevied_order_number_title'>NUMBER :</div>
                            <div className='order_recevied_order_number'>--UserNumber--</div>
                        </div>
                        <div className='order_recevied_order_detail_division'>
                            <div className='order_recevied_order_number_title'>TOTAL :</div>
                            <div className='order_recevied_order_price'>₹--Amount--</div>
                        </div>

                        <div className='order_recevied_order_detail_division'>
                            <div className='order_recevied_order_number_title'>PAYMENT METHOD :</div>
                            <div className='order_recevied_order_number'>--PaymentType--</div>
                        </div>
                    </div>
                    {/* 
                <div className='order_recevied_order_msg'>Pay with cash upon delivery.</div>
                <div className='order_recevied_order_details_title'>Order details</div> */}

                    <div className='order_recevied_order_details_main_division'>
                        <div className='ordre_recevied_product_details_info d-flex w-100'>
                            <div className='order_recevied_product_title'>PRODUCT</div>
                            <div className='order_recevied_product_total'>TOTAL</div>
                        </div>


                        <div className='ordre_recevied_product_details_info d-flex w-100'>
                            <div className='order_recevied_product_info'>
                                <div className='order_recevied_product_name'>--ProductName--</div>
                            </div>
                            <div className='order_recevied_product_price'>₹--Amount--</div>
                        </div>
                        {/* <div className='ordre_recevied_product_details_info d-flex w-100'>
                        <div className='order_recevied_price_method'>SUBTOTAL :</div>
                        <div className='order_recevied_product_price'>₹3,398.00</div>
                        </div> */}
                        <div className='ordre_recevied_product_details_info d-flex w-100'>
                            <div className='order_recevied_price_method'>PAYMENT METHOD :</div>
                            <div className='order_recevied_product_delivery'>--PaymentType--</div>
                        </div>

                        <div className='ordre_recevied_product_details_info d-flex w-100'>
                            <div className='order_recevied_price_method'>TOTAL :</div>
                            <div className='order_recevied_product_price'>₹--Amount--</div>
                        </div>
                    </div>

                    <div className='order_recevied_order_details_title'>Billing address</div>
                    <div className='order_recevied_billing_details'>--BillingName--</div>
                    <div className='order_recevied_billing_details_address'>--Flat_House_No--, --Street_Flat_name--</div>
                    <div className='order_recevied_billing_details_address'>--Landmark--</div>
                    <div className='order_recevied_billing_details_address'>--City_Town-----Pincode--</div>
                    <div className='order_recevied_billing_details_address'>--State--</div>
                    <div className='order_recevied_billing_details'>--BillingNumber--</div>

                    <div className='d-flex justify-content-center' style={{ marginBottom: "160px" }}>
                        <Stepper step={0}>
                            <Step>
                                <StepNumber />
                                <StepTitle>Order Placed</StepTitle>
                                <StepStatus />
                                {/* <StepDescription>Description</StepDescription> */}
                            </Step>
                            <Step>
                                <StepNumber />
                                <StepTitle>Shipped</StepTitle>
                                <StepStatus />
                                {/* <StepDescription>Description</StepDescription> */}
                            </Step>
                            <Step>
                                <StepNumber />
                                <StepTitle>Out For delivery</StepTitle>
                                <StepStatus />
                                {/* <StepDescription>Description</StepDescription> */}
                            </Step>
                            <Step>
                                <StepNumber />
                                <StepTitle>Delivered</StepTitle>
                                <StepStatus />
                                {/* <StepDescription>Description</StepDescription> */}
                            </Step>
                        </Stepper>
                    </div>

                    {/* <button className='order_received_main_title'
                onClick={()=>navigate('/')}
                >GO TO HOMEPAGE</button>
                      */}

                </div>
            </div>
        </>
    )
}

export default OrderReceived2;