import React, { useEffect, useState, useRef } from 'react';
import './Productpage.css'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useStateValue } from '../../../StateProviders';
import { default as Carousel1 } from "react-elastic-carousel";
import 'antd/dist/antd.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { RWebShare } from "react-web-share";
import toast, { Toaster } from 'react-hot-toast';


import bestdesign from '../../../../images/bestdesign.png';
import certfied from '../../../../images/certified.png';
import bishallmark from '../../../../images/BIS-Hallmark.png';
import easyexchange from '../../../../images/easy exchnage.png';
import lifetimeexchange from '../../../../images/lifetime exchange.png';
import shipping from '../../../../images/shipping.png';



function Productpage() {

	const Info = [
		{
			img: bestdesign,
			title: 'Best',
			title2: 'Design'
		},
		{
			img: certfied,
			title: 'Certified',
			title2: 'Diamonds'
		},
		{
			img: bishallmark,
			title: 'BIS',
			title2: 'Hallmark'
		},
		{
			img: easyexchange,
			title: 'Easy',
			title2: 'Exchange'
		},
		{
			img: lifetimeexchange,
			title: 'Lifetime',
			title2: 'Exchange'
		},
		{
			img: shipping,
			title: 'Insured',
			title2: 'Shipping'
		},

	]

	const params = useParams();
	const { PID, PName } = params;


	let navigate = useNavigate()

	const [pid, setpid] = useState()
	const [productfound, setproductfound] = useState(false)
	const [currentImgIndex, setCurrentImgIndex] = useState(0);
	const [currentURL, setCurrentURL] = useState("");
	const [{ basket, ShowLoginPop }, dispatch] = useStateValue();
	const [mainImg, setmainImg] = useState("");
	const [PRating, setPRating] = useState(0);
	const [selectedBundle, setSelectedBundle] = useState({});

	let imageArray = [];
	const [productzoomimg, setproductzoomimg] = useState({

		smallImage: {
			alt: 'Phasellus laoreet',
			isFluidWidth: true,
			src: "https://upload.wikimedia.org/wikipedia/commons/1/14/Product_sample_icon_picture.png",
			zIndex: "1",
		},
		largeImage: {
			src: 'https://upload.wikimedia.org/wikipedia/commons/1/14/Product_sample_icon_picture.png',
			width: 1800,
			background: 'white',
			height: 1800,
			zIndex: 2,
		},
		enlargedImageContainerStyle: { backgroundColor: 'white', marginLeft: '150px' },
	})



	const [ProductData, setProductData] = useState({})

	const productDetails = async () => {
		if (PID !== undefined) {
			var Url = await window.API_PREFIX + 'products/product_page'
			fetch(Url, {
				method: 'POST',
				headers: {
					'Content-type': 'application/json',
				},
				body: JSON.stringify(
					{ "Token": localStorage.getItem("Token"), "Product_Id": PID, "VarientId": PID }),
			}).then(resp => resp.json()).then(data => {
				console.log(data)
				if (data['status'] === "1") {
					setproductfound(true)

					setProductData({ ...data["SearchResult"] })
					const bundle = data["SearchResult"].QuanityBundle.find((bundle) => bundle.ProductId === Number(PID));

					setSelectedBundle(bundle)
					setCurrentImgIndex(0);

					setCurrentURL(data['SearchResult']["Image"][0])

					var tmpZoomParam = productzoomimg
					console.log(tmpZoomParam['smallImage']['src'])
					tmpZoomParam['smallImage']['src'] = data['SearchResult']["Image"][0]
					tmpZoomParam['largeImage']['src'] = data['SearchResult']["Image"][0]

					setproductzoomimg({ ...tmpZoomParam })
					setmainImg(data['SearchResult']["Image"][0])
					setPRating(data['SearchResult']['rating'])
					console.log(data["SearchResult"]['ProductId'])
					setpid(data["SearchResult"]['ProductId'])


					var url = window.API_PREFIX + "products/view_upsell";
					fetch(url, {
						method: 'POST',
						headers: {
							'Content-type': 'application/json',
						},
						body: JSON.stringify(
							{
								"Token": localStorage.getItem('Token'),
								"VariantId": data['SearchResult']['VarientId']

							}

						)
					}).then(resp => resp.json()).then(data => {

						console.log("data latest", data);

						if (data['status'] === "1") {
							setlatestdata([...data['products']])
						}
					})






				}
			}
			)
		}
		else if (PName !== undefined) {


			var Url = window.API_PREFIX + 'products/product_page_Permalink'
			fetch(Url, {
				method: 'POST',
				headers: {
					'Content-type': 'application/json',
				},
				body: JSON.stringify(
					{ "Token": localStorage.getItem("Token"), "ParmaLink": PName }),
			}).then(resp => resp.json()).then(data => {
				console.log(data)
				if (data['status'] === "1") {
					setproductfound(true)
					setProductData({ ...data["SearchResult"] })
					setCurrentImgIndex(0);

					setCurrentURL(data['SearchResult']["Image"][0])

					var tmpZoomParam = productzoomimg
					console.log(tmpZoomParam['smallImage']['src'])
					tmpZoomParam['smallImage']['src'] = data['SearchResult']["Image"][0]
					tmpZoomParam['largeImage']['src'] = data['SearchResult']["Image"][0]

					setproductzoomimg({ ...tmpZoomParam })
					setmainImg(data['SearchResult']["Image"][0])
					setPRating(data['SearchResult']['rating'])
					setpid(data["SearchResult"]['ProductId'])

					var url = window.API_PREFIX + "products/view_upsell";
					fetch(url, {
						method: 'POST',
						headers: {
							'Content-type': 'application/json',
						},
						body: JSON.stringify(
							{
								"Token": localStorage.getItem('Token'),
								"VariantId": data['SearchResult']['VarientId']

							}

						)
					}).then(resp => resp.json()).then(data => {

						console.log("data latest", data);

						if (data['status'] === "1") {
							setlatestdata([...data['products']])
						}
					})
				}
				if (data['status'] == "-2") {
					setproductfound(false)
				}

			}
			)
		}
	}


	useEffect(() => {
		productDetails()
	}, [PID, PName])



	useEffect(() => {
		setCurrentURL(imageArray[0]?.image)
	}, [])

	const handleBundleChange = (event) => {
		const bundle = ProductData.QuanityBundle.find((bundle) => bundle.ProductId === Number(event.target.value));

		navigate(`/product/` + event.target.value)
		setSelectedBundle(bundle);

	};

	const handleAddCart = async (ProductData) => {

		if (ShowLoginPop) {
			if (ProductData.IsCart) {
				navigate("/checkout")
			} else {
				var Url = await window.API_PREFIX + 'order/update_cart'
				fetch(Url, {
					method: 'POST',
					headers: {
						'Content-type': 'application/json',
					},
					body: JSON.stringify(
						{ "Token": localStorage.getItem("Token"), "ProductId": pid, "Quantity": "1" }),
				}).then(resp => resp.json()).then(data => {
					if (data['status'] === "1") {
						dispatch({
							type: 'ADD_TO_BASKET',
							item: ProductData
						})
						navigate("/checkout")
					}
					else {
						toast.error('Quantity is not available')
					}
				}
				)
			}

		} else {
			dispatch(
				{ type: "Logout" }
			)
			dispatch(
				{ type: "OpenLogin" }
			)
		}

	}



	const breakPoints = [
		{ width: 1, itemsToShow: 1 },
		{ width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
		{ width: 768, itemsToShow: 3 },
		{ width: 1150, itemsToShow: 4, itemsToScroll: 2 },
		{ width: 1450, itemsToShow: 5 },
		{ width: 1750, itemsToShow: 6 },
	];


	const [latestdata, setlatestdata] = useState([])
	const [offer, setoffer] = useState(false)


	const [thumbsSwipers, setThumbsSwipers] = useState(null);

	const prevRef = useRef(null);
	const nextRef = useRef(null);



	const CustomArrow = ({ type, onClick, isEdge }) => {
		const pointer = type === 'PREV' ? <i class="fa-solid fa-chevron-left fa-2x"></i> : <i class="fa-solid fa-chevron-right fa-2x"></i>;
		return (
			<div
				style={{ display: 'flex', alignItems: 'center' }}
				onClick={onClick}
				disabled={isEdge}
				className="custom-arrow"
			>
				{pointer}
			</div>
		);
	};


	return (
		<>


			<div className='container '>
				{productfound ?

					<div className='block_main'>

						{/* <div className='d-flex mb-2'>
							<div className='breadcrumbs_product_divition'
								onClick={() => {
									navigate(`/filter/Category&${ProductData['CategoryName']}&${ProductData['CategoryId']}`)
								}}
							>{ProductData['CategoryName']}</div>
							<div className='breadcrumbs_product_arrow_icone'><i class="fa-solid fa-chevron-right"></i></div>
							<div className='breadcrumbs_product_path_details'>{ProductData["SubCategoryName"]}</div>
						</div> */}


						<div className='left_product_image'>
							<div className='d-flex'>
								<div ref={prevRef} className="swiper_pervious"><i class="fa-solid fa-angle-left fa-3x"></i></div>
								<div ref={nextRef} className="swiper_next"><i class="fa-solid fa-angle-right fa-3x"></i></div>
							</div>

							<Swiper
								navigation={{
									prevEl: prevRef.current,
									nextEl: nextRef.current,
								}}
								onInit={(swiper) => {
									swiper.params.navigation.prevEl = prevRef.current;
									swiper.params.navigation.nextEl = nextRef.current;
									swiper.navigation.init();
									swiper.navigation.update();
								}}
								style={{
									'--swiper-navigation-color': '#fff',
									'--swiper-pagination-color': '#fff',
								}}
								loop={true}
								spaceBetween={10}
								// navigation={true}
								thumbs={{ swiper: thumbsSwipers }}
								modules={[FreeMode, Navigation, Thumbs]}
								className="mySwipermain"
							>
								{ProductData && ProductData?.Image?.map((imageUrl, index) => (
									<SwiperSlide key={index}>
										<img src={imageUrl} alt={`Image ${index}`} />
									</SwiperSlide>
								))}
							</Swiper>





							<Swiper
								onSwiper={setThumbsSwipers}
								loop={true}
								spaceBetween={10}
								slidesPerView={4}
								freeMode={true}
								watchSlidesProgress={true}
								modules={[FreeMode, Navigation, Thumbs]}
								className="mySwipermulti"
							>
								{ProductData && ProductData?.Image?.map((imageUrl, index) => (
									<SwiperSlide key={index}>
										<img src={imageUrl} alt={`Image ${index}`} />
									</SwiperSlide>
								))}
							</Swiper>




						</div>

						<div className='detail_product '>


							<div className='product_page_product_name'>
								<div>
									<div className='product_name_pp'>{ProductData.Product_Name}</div>
									<div style={{ color: '#4F3267' }}>By <span style={{ color: 'black', fontWeight: '600' }}><b>Pankaj Jewellers</b></span></div>
								</div>
								<div className='fav_icon_product_page'>


									<i className={ProductData.IsWishlisted ? "fa-solid fa-heart fa-xl me-2" : "fa-regular fa-heart fa-xl  me-2"} style={ProductData.IsWishlisted ? { color: '#4F3267' } : { color: 'null' }}
										onClick={() => {

											if (ShowLoginPop) {

												var Url = ""
												if (ProductData["IsWishlisted"]) { Url = window.API_PREFIX + 'products/delete_wishlist' }
												else { Url = window.API_PREFIX + 'products/add_wishlist' }

												fetch(Url, {
													method: 'POST',
													headers: {
														'Content-type': 'application/json',
													},
													body: JSON.stringify(
														{ "Token": localStorage.getItem("Token"), "ProductId": ProductData.ProductId }),
												}).then(resp => resp.json()).then(data => {

													if (data['status'] === "1" || data['status'] === "-1") {
														var Tmp = ProductData
														Tmp['IsWishlisted'] = !Tmp['IsWishlisted']
														setProductData(Tmp)
														if (Tmp['IsWishlisted']) {
															toast.success('Successfully Added')
															dispatch({
																type: 'ADD_TO_WISHLIST',
																item: ProductData
															});
														} else {
															toast.success('Successfully Removed')
															dispatch({
																type: 'REMOVE_FROM_WISHLIST',
																id: ProductData.ProductId
															});
														}
													}
													else if (data['status'] == "0") {
														dispatch(
															{ type: "Logout" }
														)
														dispatch(
															{ type: "OpenLogin" }
														)
													}
												}

												)
											}
										}}

									></i>




									<RWebShare
										data={{
											text: "Check out this page!",
											url: window.location.href,
											title: "My Web Page"
										}}
										onClick={() => console.log("shared successfully!")}
									>
										<i class="fa-solid fa-share-nodes fa-xl  share_product_page"></i>
									</RWebShare>
								</div>
							</div>


							<div className='row'>
								<div className='col'>
									<div className='detail_'>
										<span className='text-decoration-line-through me-2 ' style={{ color: '#878787', fontSize: '1.25em' }}>₹{selectedBundle.BuyPrice}</span>
										<span style={{ fontSize: '1.56em' }}>₹{selectedBundle.SalePrice}</span>
									</div>
									<div style={{ fontSize: '15px', fontWeight: '500' }}>(Incl.of all taxes)</div>
								</div>
								<div className='col'>
									{
										ProductData['isOffer'] === true ?
											<div className=''>
												<span>You Save</span><br />
												<span style={{ color: '#4F3267', fontWeight: '600' }}>₹{(ProductData.BuyPrice - ProductData.SalePrice)}</span>
											</div>
											: null
									}
								</div>
								<div className='col'>
									{
										ProductData['isOffer'] === true ?
											<div className='' style={{ textAlign: 'center', border: '1px solid #4F3267', color: '#4F3267', padding: '5px 10px', fontWeight: '600' }}>
												{(((ProductData.BuyPrice - ProductData.SalePrice) / ProductData.BuyPrice) * 100).toFixed()}% OFF</div>
											: null
									}
								</div>
							</div>







							<div class=" mt-3 select_variant">
								<select class="select_text" value={ProductData.ProductId} onChange={handleBundleChange}>
									{ProductData?.QuanityBundle?.map((bundle) => (
										<option key={bundle.ProductId} value={bundle.ProductId}>
											{bundle.QuanityBundle} - {bundle.SalePrice}
										</option>
									))}
								</select>
							</div>

							<div className='cart_button_product_page'>
								<button type="button" class="btn  cart_product_inside" onClick={() => handleAddCart(ProductData)}>{!ProductData.IsCart ? "Add to cart" : "Go to cart"}</button>
							</div>

							<div className='row'>
								{
									Info.map((data) => (
										<div className='col col-6 col-sm-4'>
											<div className='d-flex align-items-center'>
												<img src={data.img} className='best_design'></img>
												<div className='ms-3'>{data.title} <br />{data.title2}</div>
											</div>
										</div>
									))
								}
							</div>


							<div class="accordion accordion-flush" id="accordionExample">
								<div class="accordion-item">
									<h2 class="accordion-header description_product_div" id="headingOne">
										<button class="accordion-button product_acc_des" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
											DESCRIPTION
										</button>
									</h2>
									<div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
										<div class="accordion-body">
											<span style={{ fontSize: '17px' }}><div dangerouslySetInnerHTML={{ __html: ProductData.Description }} /></span>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header description_product_div" id="headingTwo">
										<button class="accordion-button collapsed product_acc_des" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
											Reviews
										</button>
									</h2>
									<div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
										<div class="accordion-body">

											<div>
												<h6>Peril patel</h6>
												<Rating name="read-only" value={3} readOnly />
												<p> Product is Awesome
												</p>
											</div>
										</div>
									</div>
								</div>

							</div>



						</div>
					</div>



					:


					null
				}

				<div className='heading_featured_product'>
					{latestdata.length === 0 ? <h1>FEATURED PRODUCT</h1> : null}
				</div>


				{latestdata.length > 0 && (<div>
					<Carousel1 breakPoints={breakPoints} pagination={false} renderArrow={CustomArrow}>
						{latestdata.map((eachProduct, ProductIndex) => {
							if (eachProduct['IsOffer'] === true) {
								var offer = Math.floor(((eachProduct.BuyPrice - eachProduct.SalePrice) / eachProduct.BuyPrice) * 100)
							}
							return (

								<>


									<div className='homepage_card_main' onClick={() => {
										navigate(`/product/${eachProduct.ProductId}`)
									}}>
										<div className='homepage_card_top'>
											<img src={eachProduct.ProductImg} className='image_size' alt="Product Image"></img>
											{eachProduct['IsOffer'] ? <div className='left'>{offer}%OFF</div> : null}

											<div className='ratings_part'>
												{/* <span>4.6 <i className="fa-solid fa-star" style={{ color: 'pink' }}></i> 78 </span> */}
											</div>
										</div>
										<div className='homepage_price_of_product'>
											<div className='homepage_price_both_discounted_and_regular'>
												<div className='discounted_price'>
													{eachProduct['IsOffer'] ? <span >₹{eachProduct['SalePrice']}</span> : <span>₹{eachProduct['BuyPrice']}</span>}
												</div>
												<div className='regular_price'>
													{eachProduct['IsOffer'] ? <span>₹{eachProduct['BuyPrice']}</span> : null}
												</div>
											</div>
											<div className='homepage_name_reference_of_product'>
												<span>{eachProduct['Name']}</span>
											</div>
											<div className='homepage_place_order_to_cart'>
												<button type='button' onClick={() => handleAddCart(eachProduct)}>
													Add to Cart</button>
											</div>
										</div>
									</div>


									{/* <div className='cardd_mainn' onClick={() => {
										navigate(`/product/${eachProduct.ProductId}`)
									}}>

										<div className='d-flex justify-content-center'>
											<div className='card_top' >
												<img src={eachProduct.ProductImg} className='image_size' alt="Product Image"></img>
												{eachProduct.IsOffer ? <div className='le'>{offer}% Off</div> : null}
											</div>
										</div>

										<div className='price_of_product_vertical_product' style={{ textAlign: 'left' }}>
											<span style={{ fontSize: '16px', fontWeight: '400' }}>{eachProduct['Name']}</span>
											{eachProduct.IsOffer ? <div className='price_both_discounted_and_regular_vertical_page'>
												<div className='discounted_price'>
													<span style={{ fontSize: '20px' }} >₹{eachProduct['SalePrice']}</span>
												</div>

												<span className='mx-2 text-decoration-line-through' style={{ fontSize: '10px', color: '#878787' }}>₹{eachProduct['BuyPrice']}</span>

											</div> :
												<div className='price_both_discounted_and_regular_vertical_page'>
													<span className='mx-2 text-decoration-line-through' style={{ fontSize: '10px', color: '#878787' }} >₹{eachProduct['BuyPrice']}</span>
												</div>}
											<div className='name_reference_of_product_vertical_page' onClick={() => {
												navigate(`/product_/` + eachProduct.ParmaLink)

											}}>

											</div>



											<button type="button" className='add_to_cart_btn btn'
												onClick={()=>{
													handleAddCart(eachProduct)
												}}
											>{eachProduct.AllVarient[0].IsCart ? "Go to Cart" : "Add to Cart"}</button>


										</div>
									</div> */}
								</>



							)

						})}




					</Carousel1>
				</div>)}
			</div>
			<Toaster />

		</>
	)

}
export default Productpage;
