import { useEffect, useState } from 'react';
import './Address.css';
import { useNavigate, useLocation } from 'react-router-dom'
import { useStateValue } from '../../../StateProviders';


function Address() {

    let navigate = useNavigate();
    const { state } = useLocation();

    const [{ ShowLoginPop }, dispatch] = useStateValue()
    const [FullName, setFullName] = useState("")
    const [MobileNumber, setMobileNumber] = useState("")
    const [PinCode, setPinCode] = useState("")
    const [CountryState, setCountryState] = useState("")
    const [City, setCity] = useState("")
    const [StreetFlat, setStreetFlat] = useState("")
    const [LandmarkAddress, setLandmarkAddress] = useState("")
    const [FlatNo, setFlatNo] = useState("")
    const [AddressNickName, setAddressNickName] = useState("")
    const [IsDefault, setIsDefault] = useState(false)
    const [AddressType, setAddressType] = useState(1)
    const [AddressId, setAddressId] = useState(-1)
    const [ReqField, setReqField] = useState([false, false, false, false, false, false, false])



    useEffect(() => {
        if (state !== null) {
            setFullName(state['Name'])
            setMobileNumber(state["Number"])
            setCountryState(state["State"])
            setCity(state["City_Town"])
            setLandmarkAddress(state["Landmark"])
            setStreetFlat(state["StreetFlat"])
            setFlatNo(state["Flat_No"])
            setPinCode(state["Pincode"])
            setAddressNickName(state["NickName"])
            setIsDefault(state["IsDefault"])
            setAddressId(state["Id"])

            state["Type"] == "Home" ? setAddressType(1) : state["Type"] == "Work" ? setAddressType(2) : setAddressType(3)
        }
        // else{
        //     dispatch({type:"Logout"})
        //     navigate('/')
        // }


    }, [])

    return (
        <div className='container mt-2'>
            <div className='address_breadcrums'>
                <div className='address_path'>My Account</div>
                <div className='address_path_icon'><i class="fa-solid fa-chevron-right"></i></div>
                <div className='address_path_details'
                    onClick={() => { navigate("/addressbreadcrums") }}
                >My Addresses</div>
                <div className='address_path_icon'><i class="fa-solid fa-chevron-right"></i></div>
                <div className='address_path_manage_details'>Manage Address</div>
            </div>

            <div className='address_extra_division'>
                <div className='address_second_extra_division'>
                    <div className='address_page_form'>
                        {AddressId === -1 ? <div className='address_page_title'
                        >Add Shipping Address</div> : <div className='address_page_title'
                        >Edit Address</div>}


                        <form class="row g-3 address_form_main_division">
                            <div class="col-md-6 address_form_row" >
                                <label class="address-form-label">Contact details</label>
                                <input type="mail" class="form-control addressformControl" placeholder="Enter First Name" value={FullName}
                                    onChange={(e) => {
                                        setFullName(e.target.value)

                                    }}
                                ></input>
                                {ReqField[0] ? <div className='address_error'>Full Name is required</div> : null}

                            </div>

                            <div class="col-md-6 address_form_row">
                                <label class="address-form-label">Mobile Number *</label>
                                <div class="address-add-right">
                                    <span class="address-input-group-addon">+ 91</span>
                                    <input type="mail" placeholder="Enter your mobile number" class="form-control addressnumberformControl " name="addAddressMobileNo" maxlength="10" value={MobileNumber}
                                        /* <input type="tel" class="addressformControl " placeholder=" Enter your Mobile Number"  */
                                        onChange={(e) => {
                                            setMobileNumber(e.target.value)

                                        }}
                                    ></input>

                                    {ReqField[1] ? <div className='address_error'>Mobile No is required</div> : null}

                                </div>
                            </div>

                            <div class="col-md-6 address_form_row">
                                <label class="address-form-label">Nick Name</label>
                                <input type="text" class="form-control addressformControl" placeholder="Enter Nick Name" value={AddressNickName}
                                    onChange={(e) => {
                                        setAddressNickName(e.target.value)

                                    }}
                                ></input>
                                {ReqField[2] ? <div className='address_error'>Nick Name is required</div> : null}


                            </div>

                            <div class="col-md-6 address_form_row">
                                <label class="address-form-label">Pin Code</label>
                                <input type="text" class="form-control addressformControl" placeholder="Pincode" maxlength="10" value={PinCode}
                                    onChange={(e) => {
                                        setPinCode(e.target.value)

                                        if (e.target.value.length == 6) {
                                            var Url = `https://api.postalpincode.in/pincode/${e.target.value}`

                                            fetch(Url, {
                                                // mode: 'no-cors',
                                                method: 'GET',
                                                headers: {
                                                    // "Content-Type": "application/json",
                                                    // "Access-Control-Allow-Origin": process.env.REACT_APP_API_URL,
                                                    // "Access-Control-Request-Headers": 'Content-Type, Authorization'

                                                }
                                                // body: JSON.stringify({ "Token": localStorage.getItem("Token") }),
                                            }).then(resp => resp.json()
                                            ).then(data => {

                                                if (data[0]['Status'] === "Success") {


                                                    setCity(data[0]["PostOffice"][0]["District"])
                                                    setCountryState(data[0]["PostOffice"][0]["State"])

                                                }
                                            })
                                        }
                                        else {

                                            setCity('')
                                            setCountryState("")

                                        }
                                    }}
                                ></input>
                                {ReqField[3] ? <div className='address_error'>Pin code field is required</div> : null}

                            </div>

                            <div className='address_form_row'>
                                <label class="address-form-label">Address Details</label>
                                <input type="text" class="form-control addressformControl" placeholder="Flat no." value={FlatNo}
                                    onChange={(e) => {
                                        setFlatNo(e.target.value)
                                    }}
                                ></input>
                                {ReqField[4] ? <div className='address_error'>Field is required</div> : null}


                                <input type="text" class="form-control addressformControl" placeholder="Building Name/Society" value={StreetFlat}
                                    onChange={(e) => {
                                        setStreetFlat(e.target.value)
                                    }}
                                ></input>
                                {ReqField[5] ? <div className='address_error'>Field is required</div> : null}

                            </div>

                            <div class="address_field">
                                <input type="text" class="form-control addressformControl" placeholder="Landmark/Locality/Area" value={LandmarkAddress}
                                    onChange={(e) => {
                                        setLandmarkAddress(e.target.value)

                                    }}
                                ></input>
                                {ReqField[6] ? <div className='address_error'>Field is required</div> : null}

                            </div>

                            <div class="col-md-6 address_form_row">
                                <label class="address-form-label">State</label>
                                <input type="text" class="form-control addressformControl" placeholder="State" value={CountryState} disabled

                                ></input>
                            </div>

                            <div class="col-md-6 address_form_row">
                                <label class="address-form-label">City</label>
                                <input type="text" class="form-control addressformControl" placeholder="City" disabled value={City}

                                ></input>
                            </div>


                            <div className='address_type'>Save Address As</div>

                            <div className='address_radio_button'>

                                <div class="form-check address_radio_button_form_check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inineRadioOptions" value="option1" checked={AddressType == 1 ? true : false} onClick={() => { setAddressType(1) }}
                                    ></input>
                                    <label class="form-check-label">Home</label>
                                </div>
                                <div class="form-check address_radio_button_form_check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" value="option2" checked={AddressType == 2 ? true : false} onClick={() => { setAddressType(2) }}></input>
                                    <label class="form-check-label">Work</label>
                                </div>
                                <div class="form-check address_radio_button_form_check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" value="option3" checked={AddressType == 3 ? true : false} onClick={() => { setAddressType(3) }}></input>
                                    <label class="form-check-label">Other</label>
                                </div>
                            </div>

                            <div className='address_checkbox_button mt-4'>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" name="inlineCheckOptions" value="option3" checked={IsDefault}
                                        onClick={() => {
                                            setIsDefault(!IsDefault)
                                        }
                                        }
                                    ></input>
                                    <label class="address-form-check-label ">Billing address same as the shipping address</label>
                                </div>
                            </div>

                            <div className='address-button'>
                                <button type="button" class="address-btn-save"
                                    onClick={() => {
                                        var tmpReqField = ReqField
                                        if (FullName.length === 0) tmpReqField[0] = true
                                        else tmpReqField[0] = false
                                        if (MobileNumber.length === 0) tmpReqField[1] = true
                                        else tmpReqField[1] = false
                                        if (AddressNickName.length === 0) tmpReqField[2] = true
                                        else tmpReqField[2] = false
                                        if (PinCode.length === 0) tmpReqField[3] = true
                                        else tmpReqField[3] = false
                                        if (FlatNo.length === 0) tmpReqField[4] = true
                                        else tmpReqField[4] = false
                                        if (StreetFlat.length === 0) tmpReqField[5] = true
                                        else tmpReqField[5] = false
                                        if (LandmarkAddress.length === 0) tmpReqField[6] = true
                                        else tmpReqField[6] = false

                                        setReqField([...tmpReqField])
                                        const isAllFalse = (currentValue) => currentValue == false;

                                        if (ReqField.every(isAllFalse)) {
                                            var TmpAddressType = ""
                                            if (AddressType === 1) TmpAddressType = "Home"
                                            if (AddressType === 2) TmpAddressType = "Work"
                                            if (AddressType === 3) TmpAddressType = "Other"

                                            if (AddressId !== -1) {
                                                var Url = window.API_PREFIX + 'user/edit_address'
                                                fetch(Url, {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-type': 'application/json',
                                                    },
                                                    body: JSON.stringify({
                                                        "Token": localStorage.getItem("Token"),
                                                        "Id": AddressId,
                                                        "Type": TmpAddressType,
                                                        "Name": FullName,
                                                        "Number": MobileNumber,
                                                        "Flat_No": FlatNo,
                                                        "StreetFlat": StreetFlat,
                                                        "Landmark": LandmarkAddress,
                                                        "City_Town": City,
                                                        "State": CountryState,
                                                        "Pincode": PinCode,
                                                        "IsDefault": IsDefault,
                                                        "NickName": AddressNickName,
                                                    }),
                                                }).then(resp => resp.json()).then(data => {


                                                    if (data['status'] == "1") {
                                                        navigate("/addressbreadcrums")

                                                    }
                                                }
                                                )
                                            }
                                            else {
                                                var Url = window.API_PREFIX + 'user/add_address'
                                                fetch(Url, {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-type': 'application/json',
                                                    },
                                                    body: JSON.stringify({
                                                        "Token": localStorage.getItem("Token"),
                                                        "Type": TmpAddressType,
                                                        "Name": FullName,
                                                        "Number": MobileNumber,
                                                        "Flat_No": FlatNo,
                                                        "StreetFlat": StreetFlat,
                                                        "Landmark": LandmarkAddress,
                                                        "City_Town": City,
                                                        "State": CountryState,
                                                        "Pincode": PinCode,
                                                        "IsDefault": IsDefault,
                                                        "Nickname": AddressNickName,
                                                    }),
                                                }).then(resp => resp.json()).then(data => {


                                                    if (data['status'] == "1") {
                                                        navigate("/addressbreadcrums")
                                                    }
                                                    else if (data['status'] == "1") {
                                                        navigate("/addressbreadcrums")
                                                    }
                                                }
                                                )
                                            }
                                        }

                                    }}>SAVE ADDRESS</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Address;