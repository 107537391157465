import React, { useEffect, useState } from 'react';
import Carousel from 'react-elastic-carousel';
import './Blogs.css'

import side_image_1 from '../../../../images/Desktop_gold-min.png'
import { Navigate, useNavigate } from 'react-router-dom';

function Blogs() {

    let Navigate = useNavigate();
    const postDate = new Date();
    const formattedDate = postDate.toLocaleDateString();
    const [BlogData, setBlogData] = useState([]);



    useEffect(() => {
        var Url = window.API_PREFIX + 'admin/get_blog'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                { "Token": localStorage.getItem("Token") }),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                setBlogData([...data["AllBlog"]])
            }
        })
    }, [])


    return (
        <div className='container'>

            <Carousel itemsToShow={1} pagination={false}>
                <div className='carousal_main_div'>
                    <img src={side_image_1} className='image_div'></img>
                    <div className='div_2'>
                        <div className='blog_main_title'>BEAUTIFUL NATURE </div>
                        <div className='d-flex' style={{ color: 'white' }} >
                            <div className='blog_main_title2'>Posted by patel</div>
                            &nbsp;|&nbsp;
                            <div className='blog_main_title2'>{formattedDate}</div>
                            &nbsp;|&nbsp;
                            <div className='blog_main_title2 ms-1' >Nature</div>
                            &nbsp;|&nbsp;
                            <div className='d-flex'>
                                <span>4.6 <i className="fa-solid fa-star" style={{ color: 'pink' }}></i> 78 </span>
                            </div>
                        </div>

                    </div>
                </div>

            </Carousel>

            <div className='row mt-5'>
                {BlogData.map((eachBlog) => {
                    return (<div className='col col-12 col-sm-6 col-md-6'>
                        <img src={eachBlog['blog_cover']} className='image_div'></img>
                        <div style={{ fontSize: '20px' }}>{eachBlog['Title']} </div>
                        <div>{eachBlog['Created']}</div>
                        <p className='paragraph_blog'>

                            <div dangerouslySetInnerHTML={{ __html: eachBlog['Description'] }} />
                        </p>
                    </div>)
                })

                }

            </div>

            <div className='col-12 col-sm-8  col-md-4 blog_div_2 mt-2'>
                <div className='blog_div_3'>
                    <div className='d-flex me-2'>
                        <img src={side_image_1} className='image_div_2'></img>
                        <div style={{ marginLeft: '10px' }}>
                            <div style={{ fontSize: '15px' }}>BEAUTIFUL NATURE </div>
                            <div className='d-flex' >
                                <div >Posted by patel</div>
                                &nbsp;|&nbsp;
                                <div >{formattedDate}</div>
                                &nbsp;|&nbsp;
                                <div className=' ms-1' >Nature</div>
                                &nbsp;|&nbsp;
                                <div className='d-flex'>
                                    <span>4.6 <i className="fa-solid fa-star" style={{ color: 'pink' }}></i> 78 </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    )
}

export default Blogs