
import { useNavigate, Outlet } from "react-router-dom"
import { useStateValue } from "../StateProviders";
import Header from "../User/Userheader/Header"
import ArihantFooter from "../User/Userheader/ArihantFooter"
import ComparePopup from "./ComparePopup"
import { useEffect } from "react";
import Topbar from "./Userheader/Topbar";
import Headertwo from "./Userheader/Headertwo";



export default function Userlayout() {
  const [{ basket }, dispatch] = useStateValue();

  const [{ ShowLoginPop, ComparePop }, dispatch2] = useStateValue();
  const [{ CloseLogin }, dispatch3] = useStateValue();

  let navigate = useNavigate()



  useEffect(() => {

    // var Url = window.API_PREFIX + 'user/check_Login'
    // fetch(Url, {
    //   method: 'POST',
    //   headers: {
    //     'Content-type': 'application/json',
    //   },
    //   body: JSON.stringify(
    //     {
    //       "Token": localStorage.getItem('Token'),
    //     }
    //   ),
    // }).then(resp => resp.json()).then(data => {

    //   if (data['status'] === "1") {
    //     dispatch({ type: 'Login' })

    //   }
    //   else {
    //     dispatch({ type: 'Logut' })
    //     // navigate('/')
    //     // window.location.reload()
    //   }
    // })
  }, [])

  return (
    // <
    // // onClick={(e)=>{

    // // dispatch({
    // //   type:'CLEAR_BASKET',
    // // })

    // // }} 
    // >
    <>
    {/* <Topbar/> */}
      <Header />
      {/* <Headertwo/> */}
      {/* //  {
    //  basket[basket.length-1]!==undefined?basket[basket.length-1]["count"]:basket
    //  } */}

      <Outlet />
      {ComparePop ? <ComparePopup /> : null}
      <ArihantFooter />
    </>


  );
}

