import { useEffect, useState } from 'react'
import { useStateValue } from '../../../StateProviders';
import './Order.css'
import { Table } from 'antd';
import 'antd/dist/antd.css';
import { EyeOutlined, DeleteFilled } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Empty } from 'antd';
import Loader from '../../Loader';
import toast, { Toaster } from 'react-hot-toast';
import TrashOrder from './TrashOrder';

function Order() {
    const params = useParams()
    const { UID } = params
    console.log(UID)


    let navigate = useNavigate()
    const [{ ShowLoginPop }, dispatch] = useStateValue()
    const [modalVisible, setModalVisible] = useState(false);
    const [isRestored, setIsRestored] = useState(false);

    const [bulkAction, setBulkAction] = useState([
        {
            dropdownname: "Bulk Action"
        },
        {
            dropdownname: "Move to trash"
        },
        {
            dropdownname: "Change status to processing"
        },
        {
            dropdownname: "Change status to on-hold"
        },
        {
            dropdownname: "Change status to completed"
        },
        {
            dropdownname: "PDF Invoice"
        },
        {
            dropdownname: "PDF Packing slip"
        },
    ])


    const [selectedOptionBulk, setSelectedOptionBulk] = useState(bulkAction[0].dropdownname);


    function formatIndianNumber(num) {
        const numStr = num.toString();
        const parts = [];
        let counter = 0;

        // Handle the last 3 digits
        if (numStr.length > 3) {
            parts.unshift(numStr.slice(-3));
            counter = 1;
        } else {
            parts.unshift(numStr);
        }

        // Handle the rest of the digits
        for (let i = numStr.length - 3; i > 0; i -= 2) {
            parts.unshift(numStr.slice(Math.max(0, i - 2), i));
            counter++;
        }

        return parts.join(',');
    }

    const columns = [
        {
            title: 'Order',
            dataIndex: 'OrderGroupId',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
                    },
                    children: <div style={{ fontWeight: '600' }}>{text}</div>
                }
            }
        },
        {

            title: 'View',
            dataIndex: 'edit',
            render: (text, record) => <EyeOutlined style={{ color: "#e11f48", cursor: "pointer", fontSize: "18px", display: "flex", justifyContent: "center" }}
                onClick={() => {
                    navigate('/administration/OrderDetail/' + record.OrderGroupId)
                }}
            />

        },
        {
            title: 'Product Name',
            dataIndex: 'Product_Name',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div style={{ textAlign: 'center', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '250px' }}>{text}</div>
                }
            }
        },
        {
            title: 'UserName',
            dataIndex: 'UserName',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Date_Time',
            dataIndex: 'Date_Time',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Total',
            dataIndex: 'TotalAmount',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>{formatIndianNumber(text)}</div>

                }
            }
        },
        {
            title: 'Delete',
            dataIndex: 'Delete',
            render: (text, record, index) => <><DeleteFilled style={{ color: "#e11f48", fontSize: "18px", display: "flex", justifyContent: "center", cursor: 'pointer' }}

                onClick={() => {
                    var Url = window.API_PREFIX + 'admin/trash_order'

                    fetch(Url, {
                        method: 'POST',
                        headers: {
                            'Content-type': 'application/json',
                        },
                        body: JSON.stringify(
                            {
                                "Token": localStorage.getItem("Token"),
                                "OrderGroupId": record.OrderGroupId,

                            }
                        ),
                    }).then(resp => resp.json()).then(Revdata => {
                        if (Revdata['status'] === "1") {
                            var tmpOrder = OrderData
                            tmpOrder.splice(index, 1)
                            setOrderData([...tmpOrder])
                            toast.success('Deleted Successfully')

                        }
                    })
                }}
            /></>
        },
        {
            title: 'Invoice',
            render(text, record) {
                return {
                    children:

                        record.Status === "Delivered" ?
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <button class="Download-button"
                                    onClick={() => {
                                        var Url = window.API_PREFIX + 'order/getpdf';
                                        fetch(Url, {
                                            method: 'POST',
                                            headers: {
                                                'Content-type': 'application/json',
                                            },
                                            body: JSON.stringify(
                                                { "Token": localStorage.getItem("Token"), "OrderGroupId": record.OrderGroupId }),
                                        }).then(resp => resp.json()).then(data => {
                                            console.log(data)
                                            if (data['status'] === "1") {
                                                const link = document.createElement('a');
                                                link.href = data['pdf'];
                                                // link.download = 'your_filename.pdf';
                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link);
                                                notify('Download Successfully.')
                                            }
                                            else {
                                                notify('Regenerate Invoice')
                                            }
                                        })
                                    }}

                                >


                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="16"
                                        width="20"
                                        viewBox="0 0 640 512"
                                    >
                                        <path
                                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z"
                                            fill="white"
                                        ></path>
                                    </svg>
                                    <span>Invoice</span>
                                </button>
                                <Toaster
                                    position="top-center"
                                    reverseOrder={false}
                                />
                            </div> : <div style={{ display: 'flex', justifyContent: 'center' }}>N/A</div>
                }

            }
        }

    ];

    const notify = (msg) => toast.success(msg);


    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5,
        pageSizeOptions: ['10', '20', '50', '100'],
        showSizeChanger: true,
    });

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    const [OrderData, setOrderData] = useState();
    function GetAllOrderData() {
        if (window.location.pathname.includes("allorder/users") && UID != null) {

            var Url = window.API_PREFIX + 'admin/signleUserallorder'
            setLoading(true);
            fetch(Url, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(
                    {
                        "Token": localStorage.getItem("Token"),
                        "UID": UID

                    }),

            }).then(resp => resp.json()).then(data => {
                console.log(data)
                if (data['status'] == "1") {
                    setOrderData([...data["SingleUserOrderData"]])
                    setLoading(false);

                }
                else {
                    dispatch({
                        type: "OpenALogin"
                    })
                    localStorage.clear()
                    navigate("/administration")
                }
            })
        }
        else {
            var Url = window.API_PREFIX + 'admin/allorder'
            setLoading(true);
            fetch(Url, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(
                    { "Token": localStorage.getItem("Token") }),
            }).then(resp => resp.json()).then(data => {
                console.log(data)
                if (data['status'] == "1") {
                    setOrderData([...data["SingleUserOrderData"]])
                    // console.log(window.location.pathname)
                    setLoading(false);
                }
                else {
                    dispatch({
                        type: "OpenALogin"
                    })
                    localStorage.clear()
                    navigate("/administration")
                }
            })
        }
    }
    useEffect(() => {
        // console.log(window.location.pathname)
        GetAllOrderData()
    }, [window.location.pathname, isRestored])


    const [GroupName, setGroupName] = useState('')
    // const [unreadcount,setunreadcount] = useState("")


    const [chatSocket, setChatSocket] = useState(null);
    const [webSocketInitialized, setWebSocketInitialized] = useState(false);

    useEffect(() => {

        if (!webSocketInitialized) {
            setGroupName("NewOrder")

            const socket = new WebSocket(
                window.WEBSOC + 'ws/order/'
            );
            socket.onopen = () => {
                console.log("WebSocket connection opened");
                setWebSocketInitialized(true);

            };
            socket.onmessage = function (e) {
                console.log("msg", e)


                GetAllOrderData()





                //   const data = JSON.parse(e.data);
                // setChatLog((prevChatLog) => prevChatLog + e.data + '\n');
            };

            socket.onclose = function (e) {
                console.error('Chat socket closed unexpectedly');
                setWebSocketInitialized(false);

            };
            setChatSocket(socket);
            // return () => {
            //     socket.close();
            // };

        }
    }, [GroupName])







    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);


    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    return (
        <div className='orderadmin_main_division'>

            {modalVisible &&
                <TrashOrder
                    open={modalVisible}
                    setOpen={setModalVisible}
                    isRestored={isRestored}
                    setIsRestored={setIsRestored}
                />
            }
            <div className='container-fuild'>
                <div className='row'>
                    <div className='col d-flex'>
                        <div className='orders_title'>Orders</div>
                        {window.location.pathname.includes("allorder/users") && UID != null ? <h3 className='orders_title'>for User-{UID}</h3> : null}
                        {/* <button type="button" class="btn orders_addnew_button">Add Order</button> */}
                    </div>
                </div>
            </div>
            <div className='container-fuild'>
                {/* <div className='row  my-4'>
                    <div className='col d-flex'>
                        <div class="dropdown">
                            <button class="btn orders_bulkaction_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {selectedOptionBulk}
                            </button>
                            <ul class="dropdown-menu">
                                {
                                    bulkAction.map((item) => {
                                        return (
                                            <li><a className={selectedOptionBulk === item.dropdownname ? "bgColorRed dropdown-item orders_bulkaction_dropdown_menu" : "dropdown-item orders_bulkaction_dropdown_menu"} onClick={() => setSelectedOptionBulk(item.dropdownname)}>{item.dropdownname}</a></li>
                                        )
                                    }
                                    )
                                }
                            </ul>
                        </div>
                        <button type="button" class="btn btn-outline-dark order_bulkaction_apply_button">Apply</button>
                        <input class="form-control orders_search_filter_by_register" type="search" placeholder="Filter by registered customer" aria-label="Search" />
                        <button type="button" class="btn btn-outline-dark order_filter_by_change_button">Change</button>
                    </div>
                    <div className='col d-flex justify-content-end'>
                        <input class="form-control orders_search_filter_by_register me-2" type="search" placeholder="Search" aria-label="Search" />
                        <button class="btn orders_search_button" type="submit">Search Orders</button>
                    </div>
                </div> */}

            </div>
            <div className="col order_ac_division">
                <p className='active_order_ac '
                    onClick={() => {
                        navigate('/administration/allorder')
                    }}
                >All<span>({OrderData?.length})</span></p>
                <p className='active_order_ac '
                    onClick={() => {
                        // navigate('/administration/allorder')
                        setModalVisible(true);
                    }}
                >Trash</p>
            </div>
            <div className='w-100'>

                <Table className='all_products_tables' columns={columns} dataSource={OrderData} pagination={pagination}
                    onChange={handleTableChange} locale={{
                        emptyText: loading ?
                            <Loader /> : <Empty />
                    }} />
                {/* <Table className='all_products_tables' columns={columns} dataSource={OrderData} loading={loading} /> */}
            </div>
        </div>
    )
}
export default Order;