import { Children, useEffect, useState } from 'react'
import { useStateValue } from '../../../StateProviders';
import './Order.css'
import { Button, Table } from 'antd';
import 'antd/dist/antd.css';
import {EyeOutlined } from '@ant-design/icons';
import { useNavigate,useParams } from 'react-router-dom';

function KeyWord() {
    const params = useParams()
    const {UID} = params
    console.log(UID)


    let navigate = useNavigate()
    const [{ ShowLoginPop }, dispatch] = useStateValue()





    const columns = [
        {
            title: 'Search ',
            dataIndex: 'Keyword',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
 
        {
            title: 'Count',
            dataIndex: 'Count',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
      
    ];
    const [CountData,setCountData] = useState();
    
    useEffect(()=>{
        // console.log(window.location.pathname)

            var Url = window.API_PREFIX + 'admin/viewkeyword'
            fetch(Url, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(
                    { "Token": localStorage.getItem("Token"),
        
                
                }),
                  
            }).then(resp => resp.json()).then(data => {
                // console.log(data)
                if (data['status'] == "1") {
                    setCountData([...data["keywordlist"]])
    
                }
                else{
                    dispatch({
                        type: "OpenALogin"
                    })
                    localStorage.clear()
                    navigate("/administration")
                }
            })
        
    
    },[])

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);


    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    return (
        <div className='orderadmin_main_division'>
            <div className='container-fuild'>
                <div className='row'>
                    <div className='col d-flex'>
                        <div className='orders_title'>KeyWord Analysis</div>
                  

                    </div>
                </div>
            </div>
            <div className='container-fuild'>
                {/* <div className='row  my-4'>
                    <div className='col d-flex'>
                        <div class="dropdown">
                            <button class="btn orders_bulkaction_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {selectedOptionBulk}
                            </button>
                            <ul class="dropdown-menu">
                                {
                                    bulkAction.map((item) => {
                                        return (
                                            <li><a className={selectedOptionBulk === item.dropdownname ? "bgColorRed dropdown-item orders_bulkaction_dropdown_menu" : "dropdown-item orders_bulkaction_dropdown_menu"} onClick={() => setSelectedOptionBulk(item.dropdownname)}>{item.dropdownname}</a></li>
                                        )
                                    }
                                    )
                                }
                            </ul>
                        </div>
                        <button type="button" class="btn btn-outline-dark order_bulkaction_apply_button">Apply</button>
                        <input class="form-control orders_search_filter_by_register" type="search" placeholder="Filter by registered customer" aria-label="Search" />
                        <button type="button" class="btn btn-outline-dark order_filter_by_change_button">Change</button>
                    </div>
                    <div className='col d-flex justify-content-end'>
                        <input class="form-control orders_search_filter_by_register me-2" type="search" placeholder="Search" aria-label="Search" />
                        <button class="btn orders_search_button" type="submit">Search Orders</button>
                    </div>
                </div> */}

            </div>
            <div className="col order_ac_division">
                <p className='active_order_ac '
                onClick={()=>{
                    navigate('/administration/allorder')
                }}
                >All<span>({CountData?.length})</span></p>
                {/* <p className='order_ac'>Cancelled<span>(1)</span></p> */}
            </div>
            <div
                style={{
                    marginBottom: 16,
                }}>
                <span className='all_products_data_table'
                    style={{
                        marginLeft: 8,
                    }}
                >
                    {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                </span>
            </div>
            <div className='w-100'>

                <Table className='all_products_tables' rowSelection={rowSelection} columns={columns} dataSource={CountData} />
            </div>
        </div>
    )
}
export default KeyWord;