import './Slider.css'
import { useState, useEffect } from 'react'
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import toast from 'react-hot-toast';
import 'antd/dist/antd.css';
import { Form, Input, Popconfirm, Table, Typography } from 'antd';
import { Empty } from "antd";
import Loader from "../../Loader";


const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  // const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  const inputNode = <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};




function Slider() {



  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [SliderData, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      key: '',
      SliderName: '',
      SliderImg: '',
      SliderLink: '',

      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...SliderData];
      const index = newData.findIndex((item) => key === item.key);
      var Url = window.API_PREFIX + 'order/editslider2'
      console.log(row)
      fetch(Url, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(
          {
            "Token": localStorage.getItem("Token"),
            "SliderId": newData[index].key,
            "SliderName": row.SliderName,
            "SliderLink": row.SliderLink,

          }
        ),
      }).then(resp => resp.json()).then(Revdata => {
        console.log(Revdata)
        if (Revdata['status'] === "1") {
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, { ...item, ...row });
            setData(newData);
            setEditingKey('');
          } else {
            newData.push(row);
            setData(newData);
            setEditingKey('');
          }
        }
      })
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };



  useEffect(() => {
    var Url = window.API_PREFIX + 'order/allslider2'
    setLoading(true);
    fetch(Url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(
        { "Token": localStorage.getItem("Token") }),
    }).then(resp => resp.json()).then(Revdata => {
      console.log(Revdata)
      if (Revdata['status'] === "1") {
        setData([...Revdata["SliderData"]])
        setLoading(false);

      }
    })
  }, [])


  const columns = [
    {
      title: 'slidername',
      dataIndex: 'SliderName',
      editable: true,
    },
    {
      title: 'SliderImg',
      // title: 'SliderName',
      dataIndex: 'SliderImg',
      width: '15%',
      render: (_, record) => {
        return (
          <img style={{ color: "#e11f48", fontSize: "18px", display: "flex", justifyContent: "center", width: "100%" }} src={record.SliderImg} />)
      },
      editable: false,
    },
    {
      title: 'SliderLink',
      // title: 'SliderImg',
      dataIndex: 'SliderLink',
      width: '30%',
      editable: true,
    },
    {
      // title: 'Edit',
      title: 'Edit',
      dataIndex: 'Edit',
      width: '20%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span style={{ display: "flex", justifyContent: "center" }}>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            <EditFilled style={{ color: "red", display: "flex", justifyContent: "center" }} />
          </Typography.Link>
        );
      },
    },
    {
      // title: 'Delete',
      title: 'Delete',
      dataIndex: 'Delete',
      render: (_, record, index) =>
        <DeleteFilled style={{ color: "#e11f48", fontSize: "18px", display: "flex", justifyContent: "center" }}
          onClick={() => {
            var Url = window.API_PREFIX + 'order/deleteslider2'
            fetch(Url, {
              method: 'POST',
              headers: {
                'Content-type': 'application/json',
              },
              body: JSON.stringify(
                { "Token": localStorage.getItem("Token"), "SliderId": record.key }),
            }).then(resp => resp.json()).then(Revdata => {
              console.log(Revdata)
              if (Revdata['status'] === "1") {
                var tmp = SliderData
                tmp.splice(index, 1)
                setData([...tmp])
                toast.success('Successfully Removed')
              }
            })
          }}
        />,

    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });




  return (

    <div className='slider_main_division'>
      <div className='slider_main_sub_division'>
        <div className='container-fuild'>
          <div>
            <div className='slider_title'>Slider</div>



            <div class="input-group custom-file-button" style={{ width: '16%', marginBottom: '10px' }}>
              <label class="input-group-text" for="inputGroupFile">Add Slider</label>
              <input type="file" class="form-control addproduct_inputtag" id="inputGroupFile" name="myImage"
                accept='image/png/jpeg'
                onChange={(event) => {




                  var uploadData = new FormData();
                  // uploadData.append("Token", localStorage.getItem("Token"));
                  // uploadData.append("FirstName", FirstName);
                  // uploadData.append("LastName", LastName);
                  // uploadData.append("Email", Email);
                  // uploadData.append("PhoneNumber", MobileNumber);
                  uploadData.append("SliderImg", event.target.files[0]);


                  var url = window.API_PREFIX + "order/addslider2";
                  setLoading(true);

                  fetch(url, {
                    method: 'POST',
                    body: uploadData
                  }).then(resp => resp.json()).then(data => {
                    console.log(data)
                    if (data['status'] == "1") {

                      var tmp = SliderData
                      tmp.push({
                        "key": data['SliderId'],
                        "SliderName": "",
                        "SliderImg": data['SliderImg'],
                        "SliderLink": ""
                      })
                      setData([...tmp])
                      setLoading(false);
                      toast.success("Successfully Added")
                    }
                  })
                }}
              />

              {
                loading ? <Loader /> : null
              }

            </div>

          </div>
        </div>
        <div>
          {<Form form={form} component={false}>
            <Table
              tableLayout='fixed'
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              // bordered
              dataSource={SliderData}
              columns={mergedColumns}
              rowClassName="editable-row"
              pagination={{
                onChange: cancel,
              }}
              locale={{
                emptyText: loading ? <Loader /> : <Empty />,
              }}
            />
          </Form>}
        </div>
      </div>
    </div>
  )
}
export default Slider;