import { useEffect, useState } from 'react'
import './Allproducts.css'
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import 'antd/dist/antd.css';
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import { Empty } from 'antd';
import Loader from '../../Loader';
import TrashProduct from './TrashProduct';
import toast, { Toaster } from 'react-hot-toast';

function Allproducts() {

    let navigate = useNavigate()
    const [selectcategory, setSelectategory] = useState([
        {
            dropdownname: "Select a category"
        },
        {
            dropdownname: "Moboil & Accessory"
        },
        {
            dropdownname: "Laptop"
        },

    ])
    const [filterbyproduct, setFilterbyproduct] = useState([
        {
            dropdownname: "Filter by product type"
        },
        {
            dropdownname: "Simple Product"
        },
        {
            dropdownname: "Downloadable"
        },
        {
            dropdownname: "Virtual"
        },
        {
            dropdownname: "Grouped Products"
        },
        {
            dropdownname: "External/Affiliate Product"
        },
        {
            dropdownname: "variable Products"
        }
    ])

    const [filterbystockstatus, setFilterbystockstatus] = useState([
        {
            dropdownnameo: "Filter by stock status"
        },
        {
            dropdownnameo: "In Stock"
        },
        {
            dropdownnameo: "Out Of Stock"
        },
    ]);
    const [selectedOptioncategory, setSelectedOptioncategory] = useState(selectcategory[0].dropdownname);
    const [selectedOption, setSelectedOption] = useState(filterbyproduct[0].dropdownname);
    const [selectedOptionStock, setSelectedOptionStock] = useState(filterbystockstatus[0].dropdownnameo);

    const [AllProductData, setAllProductData] = useState([]);
    const [NonSearchProductData, setNonSearchProductData] = useState([])
    const [TotalProduct, setTotalProduct] = useState("");


    const [modalVisible, setModalVisible] = useState(false);
    const [isRestored, setIsRestored] = useState(false);
    const columns = [
        {
            title: 'Productid',
            dataIndex: 'ProductGroupId',
            render(text, record, index) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
                    },
                    children: <div style={{ fontWeight: '600' }}  >{record.Index}</div>
                }
            }
        },
        {
            title: 'Name',
            dataIndex: 'Product_Name',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div
                        style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '150px' }}
                    >{text}</div>
                }
            }
        },
        {
            title: 'Categories',
            dataIndex: 'Categories',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'SubCategories',
            dataIndex: 'SubCategories',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'total quantity',
            dataIndex: 'total_quantity',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>1</div>
                }
            }
        },
        {
            title: 'Date',
            dataIndex: 'Date_Time',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div style={{ width: "max-content" }}>{text}</div>
                }
            }
        },
        {
            title: 'Edit',
            dataIndex: 'Edit',
            render: (text, record, Index) => <><EditFilled style={{ color: "#00ab41", fontSize: "18px", display: "flex", justifyContent: "center" }}

                onClick={() => { navigate("/administration/editproduct/" + record.ProductGroupId) }} /></>

        },
        {
            title: 'Delete',
            dataIndex: 'Delete',
            render: (text, record, index) => <><DeleteFilled style={{ color: "#e11f48", fontSize: "18px", display: "flex", justifyContent: "center", cursor: 'pointer' }}

                onClick={() => {
                    var Url = window.API_PREFIX + 'admin/trash_product'

                    fetch(Url, {
                        method: 'POST',
                        headers: {
                            'Content-type': 'application/json',
                        },
                        body: JSON.stringify(
                            {
                                "Token": localStorage.getItem("Token"),
                                "VariantId": record.ProductGroupId,

                            }
                        ),
                    }).then(resp => resp.json()).then(Revdata => {
                        console.log(Revdata)
                        if (Revdata['status'] === "1") {
                            var tmpProduct = AllProductData
                            tmpProduct.splice(index, 1)
                            setAllProductData([...tmpProduct])
                            toast.success('Deleted Successfully')

                        }
                    })
                }}

            /></>

        },
        {
            title: 'Duplicate',
            dataIndex: 'Duplicate',
            render: (text, record, index) =>
                <i className="fa-solid fa-copy" style={{ color: "#e11f48", fontSize: "18px", display: "flex", justifyContent: "center", cursor: "pointer" }}
                    onClick={() => {
                        var Url = window.API_PREFIX + 'products/duplicate_product'

                        fetch(Url, {
                            method: 'POST',
                            headers: {
                                'Content-type': 'application/json',
                            },
                            body: JSON.stringify(
                                {
                                    "Token": localStorage.getItem("Token"),
                                    "VariantId": record.ProductGroupId,
                                }
                            ),
                        }).then(resp => resp.json()).then(Revdata => {
                            console.log(Revdata)
                            if (Revdata['status'] === "1") {
                                var tmpProduct = AllProductData

                                tmpProduct.unshift(
                                    {
                                        Categories: record.Categories,
                                        Date_Time: record.Date_Time,
                                        ProductGroupId: String(Revdata['VariantId']),
                                        SubCategories: record.SubCategories,
                                        Product_Name: record.Product_Name,

                                    }
                                )


                                setAllProductData([...tmpProduct])

                            }
                        })
                    }}


                ></i>
        },
    ];

    function GetAllProductData() {
        var Url = window.API_PREFIX + 'admin/all_products'
        setloading(true);
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                "Token": localStorage.getItem("Token")
            }

            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                setAllProductData([...data["products"]])
                setTotalProduct(data["Total_Products"])
                setNonSearchProductData([...data['products']])
                setloading(false);

            }
        }
        )
    }


    useEffect(() => {
        GetAllProductData()
    }, [window.location.pathname, isRestored])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5,
        pageSizeOptions: ['10', '20', '50', '100'],
        showSizeChanger: true,
    });

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };


    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setloading] = useState(false);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;


    return (
        <div className='allproduct_main_division'>

            {modalVisible &&
                <TrashProduct
                    open={modalVisible}
                    setOpen={setModalVisible}
                    isRestored={isRestored}
                    setIsRestored={setIsRestored}
                />
            }
            <div className='allproduct_main_sub_division'>
                <div className='container-fuild'>
                    <div className='row'>
                        <div className='col d-flex align-items-center'>
                            <h2 className='allproducts_title'>Products</h2>

                            {/* <button type="button" class="btn allproducts_addnew_button">Import</button>
                    <button type="button" class="btn allproducts_addnew_button">Export</button> */}
                        </div>

                        <div className='col d-flex justify-content-end align-items-center'>
                            <input class=" allproducts_search_feild me-2" type="search" placeholder="Search" aria-label="Search"
                                onChange={(e) => {
                                    var newArray = NonSearchProductData.filter(function (el) {
                                        return el["Product_Name"].toLowerCase().includes(e.target.value.toLowerCase())
                                    }
                                    );
                                    var newArrayy = NonSearchProductData.filter(function (el) {
                                        return el["Index"].toLowerCase().includes(e.target.value.toLowerCase())
                                    }
                                    );

                                    setAllProductData([...newArray, ...newArrayy])
                                }}
                            />
                            {/* <button class="btn allproducts_search_button" type="submit">Search Products</button> */}
                        </div>
                    </div>
                </div>
                {/* <div className='d-flex my-4'>
            <div class="dropdown">
                <button class="btn allproducts_bulkaction_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Bulk Action
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item allproducts_bulkaction_dropdown_menu" href="#">Bulk Action</a></li>
                    <li><a class="dropdown-item allproducts_bulkaction_dropdown_menu" href="#">Edit</a></li>
                    <li><a class="dropdown-item allproducts_bulkaction_dropdown_menu" href="#">Move To Trash</a></li>
                </ul>
            </div>
            <button type="button" class="btn btn-outline-dark allproducts_bulkaction_apply_button">Apply</button>
            <div class="dropdown">
                <button class="btn allproducts_select_category dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {selectedOptioncategory}
                </button>
                <ul class="dropdown-menu">
                    {
                        selectcategory.map((item) => {
                            return (
                                <li><a className={selectedOptioncategory === item.dropdownname ? "bgColorRed dropdown-item allproducts_filterbystockstatus_dropdown_menu" : "dropdown-item allproducts_filterbystockstatus_dropdown_menu"} onClick={() => setSelectedOptioncategory(item.dropdownname)}>{item.dropdownname}</a></li>
                            )
                        }
                        )
                    }
                </ul>
            </div>
           
            <div class="dropdown">
                <button class="btn allproducts_filterbyproduct_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {selectedOption}
                </button>
                <ul class="dropdown-menu">
                    {
                        filterbyproduct.map((item) => {
                            return (
                                <li><div className={selectedOption === item.dropdownname ? "bgColorRed dropdown-item allproducts_filterbyproduct_dropdown_menu" : "dropdown-item allproducts_filterbyproduct_dropdown_menu"} onClick={() => setSelectedOption(item.dropdownname)}>{item.dropdownname}</div></li>
                            )
                        }
                        )
                    }

                </ul>
            </div>



            <div class="dropdown">
                <button class="btn allproducts_bulkaction_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                   {selectedOptionStock}
                </button>
                <ul class="dropdown-menu">
                    {
                        filterbystockstatus.map((item) => {
                            return (
                                <li><div className={selectedOptionStock === item.dropdownnameo ? "bgColorRed dropdown-item allproducts_filterbystockstatus_dropdown_menu" : "dropdown-item allproducts_filterbystockstatus_dropdown_menu"} onClick={() => setSelectedOptionStock(item.dropdownnameo)}>{item.dropdownnameo}</div></li>
                            )
                        }
                        )
                    }

                </ul>
            </div>
        </div> */}













                <div className="col allproducts_apds_division">
                    <div>
                        <p className='active_allproducts_apds '
                            onClick={() => {
                                setAllProductData([...NonSearchProductData])
                            }}
                        >All<span>({TotalProduct})</span></p>
                        <p className='active_order_ac mt-1 '
                            onClick={() => {
                                setModalVisible(true);
                            }}
                        >Trash</p>
                    </div>

                    <div className='w-50 ee'>



                        {/* <button
                            type="button"
                            class="btn"
                            className='active_allproducts_apds2'
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                                var Url = window.API_PREFIX + 'admin/create_xl_file';

                                fetch(Url, {
                                    method: 'POST',
                                    headers: {
                                        'Content-type': 'application/json',
                                    },
                                    body: JSON.stringify({
                                        Token: localStorage.getItem('Token'),
                                    }),
                                })
                                    .then((resp) => resp.blob()) // Convert the response to a Blob
                                    .then((blob) => {
                                        // Create a URL for the Blob data
                                        const url = window.URL.createObjectURL(blob);
                                        // Create a temporary link element to trigger the download
                                        const a = document.createElement('a');
                                        a.href = url;
                                        a.download = 'products.csv'; // Set the filename
                                        a.style.display = 'none'; // Hide the link
                                        document.body.appendChild(a);
                                        a.click(); // Trigger the download
                                        window.URL.revokeObjectURL(url); // Release the Blob data
                                        document.body.removeChild(a); // Remove the temporary link element
                                    })
                                    .catch((error) => {
                                        console.error('Error:', error);
                                    });
                            }}
                        >
                            Export Editable Product
                        </button>   */}
                        {/* 

                        <CSVLink
                            filename={"Productdata.csv"}
                            data={AllProductData}
                            className="btn  active_allproducts_apds2"
                        >
                             Export Editable Product
                        </CSVLink> */}


                        <button type="button" class="btn allproducts_addnew_button"
                            onClick={() => {
                                navigate('/administration/addproduct')
                            }}
                        ><i class="fa-solid fa-plus me-2"></i>Add New</button>

                        <button
                            type="button"
                            className="btn  active_allproducts_apds2"
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                                var Url = window.API_PREFIX + 'admin/create_xl_file';

                                fetch(Url, {
                                    method: 'POST',
                                    headers: {
                                        'Content-type': 'application/json',
                                    },
                                    body: JSON.stringify({
                                        Token: localStorage.getItem('Token'),
                                    }),
                                })
                                    .then((resp) => resp.blob()) // Convert the response to a Blob
                                    .then((blob) => {
                                        // Create a URL for the Blob data
                                        const url = window.URL.createObjectURL(blob);
                                        // Create a temporary link element to trigger the download
                                        const a = document.createElement('a');
                                        a.href = url;
                                        a.download = 'products.csv'; // Set the filename
                                        a.style.display = 'none'; // Hide the link
                                        document.body.appendChild(a);
                                        a.click(); // Trigger the download
                                        window.URL.revokeObjectURL(url); // Release the Blob data
                                        document.body.removeChild(a); // Remove the temporary link element
                                    })
                                    .catch((error) => {
                                        console.error('Error:', error);
                                    });
                            }}
                        >
                            <i class="fa-solid fa-file-export me-2"></i> Export Product
                        </button>



                        <div class="input-group custom-file-button">
                            <label class="input-group-text" for="inputGroupFile"><i class="fa-solid fa-file-import me-2"></i>import product</label>
                            <input type="file" class="form-control addproduct_inputtag" id="inputGroupFile" name="myImage"
                                accept='image/png/jpeg'
                                onChange={(event) => {

                                    //  setSelectedImage(event.target.files[0]);

                                    var uploadData = new FormData();
                                    uploadData.append("Token", localStorage.getItem("Token"));
                                    // uploadData.append("FirstName", FirstName);
                                    // uploadData.append("LastName", LastName);
                                    // uploadData.append("Email", Email);
                                    // uploadData.append("PhoneNumber", MobileNumber);
                                    uploadData.append("file", event.target.files[0]);


                                    var url = window.API_PREFIX + "admin/upload_file";


                                    fetch(url, {
                                        method: 'POST',
                                        body: uploadData
                                    }).then(resp => resp.json()).then(data => {

                                        if (data['status'] === "1") {

                                            window.location.reload()
                                            // setresponseData({...data["detail"]})

                                        }
                                    })
                                }} />
                        </div>




                        {/* <p className='active_allproducts_apds' */}
                        {/* <input
                            className='addproduct_inputtag'
                            style={{ "cursor": "pointer" }}
                            type="file"
                            name="myImage"
                            accept='image/png/jpeg'
                            onChange={(event) => {

                                //  setSelectedImage(event.target.files[0]);

                                var uploadData = new FormData();
                                uploadData.append("Token", localStorage.getItem("Token"));
                                // uploadData.append("FirstName", FirstName);
                                // uploadData.append("LastName", LastName);
                                // uploadData.append("Email", Email);
                                // uploadData.append("PhoneNumber", MobileNumber);
                                uploadData.append("file", event.target.files[0]);


                                var url = window.API_PREFIX + "admin/upload_file";


                                fetch(url, {
                                    method: 'POST',
                                    body: uploadData
                                }).then(resp => resp.json()).then(data => {

                                    if (data['status'] === "1") {

                                        window.location.reload()
                                        // setresponseData({...data["detail"]})

                                    }
                                })
                            }}
                        /> */}
                    </div>
                    {/* >Bulk Upload Product</p> */}
                    {/* <p className='allproducts_apds'>Published<span>(25)</span></p>
            <p className='allproducts_apds'>Draft<span>(1)</span></p>
            <p className='allproducts_apds'>sorting<span>(25)</span></p> */}
                </div>
                <div
                >
                    <span className='all_products_data_table'
                        style={{
                            marginLeft: 8,
                        }}
                    >
                        {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                    </span>
                </div>
                <div className='w-100 allproduct_table_number'>
                    <Table className='all_products_tables' rowSelection={rowSelection} columns={columns} pagination={pagination}
                        onChange={handleTableChange} dataSource={AllProductData} locale={{
                            emptyText: loading ?
                                <Loader /> : <Empty />
                        }} />
                </div>
            </div>
        </div>
    )
}
export default Allproducts;