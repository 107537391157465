import React, { useEffect, useState } from 'react'
import './Checkout.css';
import { useStateValue } from '../../../StateProviders';
import { useNavigate, useParams } from 'react-router-dom';
import ArihantLogo from '../../UserMedia/Popups/Arihant Logo.png'
import { default as Carousel1 } from "react-elastic-carousel";
import emptycart from '../../../../images/empty-cart.jpg'
import toast, { Toaster } from 'react-hot-toast';





function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

var name = ""


async function Razorpay() {
  const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

  if (!res) {
    alert('Razorpay SDK failed to load. Are you online?')
    return
  }
}


function Checkout() {


  function addDays(theDate, days) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }
  const date = new Date();
  const TwoDate = String(addDays(date, 3)).substring(0, 16)
  const __DEV__ = document.domain === 'localhost'

  const [{ ShowLoginPop, basket }, dispatch] = useStateValue();
  const params = useParams();
  const { PID } = params;
  const [ProductData, setProductData] = useState({});
  const [ButtonText, setButtonText] = useState("")
  const [AddressData, setAddressData] = useState([])
  console.log('AddressData', AddressData);
  const [BAddressId, setBAddressId] = useState("-1")
  const [SAddressId, setSAddressId] = useState("-1")
  const [order_id, setorder_id] = useState("")
  const [currentBillingAddress, setcurrentBillingAddress] = useState("")
  const [currentShippingAddress, setcurrentShippingAddress] = useState("")
  const [currentBNumber, setcurrentBNumber] = useState("")
  const [currentBName, setcurrentBName] = useState("")
  const [currentSNumber, setcurrentSNumber] = useState("")
  const [currentSName, setcurrentSName] = useState("")
  const [BillingShippingSame, setBillingShippingSame] = useState(true)

  const [UserEmail, setUserEmail] = useState("")
  const [UserNumber, setUserNumber] = useState("")
  const [CouponCode, setCouponCode] = useState("")
  const [CouponAmt, setCouponAmt] = useState(0)
  const [CouponDesc, setCouponDesc] = useState("")
  const [CouponAppliedFlag, setCouponAppliedFlag] = useState(false)

  const [ChangeAddress, setChangeAddress] = useState(false)
  const [ChangeAddresstwo, setChangeAddresstwo] = useState(true)
  const [quantity, setquantity] = useState(0)
  const [productList, setProductList] = useState([])
  const [Promocode_Id, setPromocode_Id] = useState("")

  const [isProfile,setisProfile] = useState(false)

  console.log('productList', productList);

  let navigate = useNavigate();

  const getCheckOutList = async () => {
    var Url = window.API_PREFIX + 'order/checkout'
    fetch(Url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(
        { "Token": localStorage.getItem("Token"), "Product_Id": PID }),
    }).then(resp => resp.json()).then(data => {

      if (data['status'] === "1") {
        setProductList(data['productlist'])

        console.log("data", data);
        //   data.productlist.forEach(item => {
        //     dispatch({
        //         type: 'ADD_TO_BASKET',
        //         item: item
        //     });
        // });
      }
      else {
        dispatch({ type: "Logout" })

      }
    })
  }

  useEffect(() => {

    if (!ShowLoginPop) {

      navigate("/")
    }
    else {

      getCheckOutList()
      var Url = window.API_PREFIX + 'user/address'
      fetch(Url, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(
          { "Token": localStorage.getItem("Token") }),
      }).then(resp => resp.json()).then(data => {

        if (data['status'] == "1") {
          setAddressData([...data['AddressData']])

          var EntryFlag = false
          for (let i = 0; i < data['AddressData'].length; i++) {
            if (data['AddressData'][i]['IsDefault']) {
              var eachAddress = data['AddressData'][i]

              EntryFlag = true
              setBAddressId(eachAddress['Id'])
              setcurrentBillingAddress(eachAddress["Flat_No"] + "," + eachAddress["StreetFlat"] + "," + eachAddress["Landmark"] + "," + eachAddress['City_Town'] + "," + eachAddress['State'] + "," + eachAddress['Pincode'])
              setcurrentBName(eachAddress['Name'])
              setcurrentBNumber(eachAddress['Number'])
              // if (BillingShippingSame === true) {
              setSAddressId(eachAddress['Id'])
              setcurrentShippingAddress(eachAddress["Flat_No"] + "," + eachAddress["StreetFlat"] + "," + eachAddress["Landmark"] + "," + eachAddress['City_Town'] + "," + eachAddress['State'] + "," + eachAddress['Pincode'])
              setcurrentSName(eachAddress['Name'])
              setcurrentSNumber(eachAddress['Number'])
              // }
            }
          }
          if (!EntryFlag) {
            setChangeAddress(true)
            setBillingShippingSame(false)
          }
        }
      })

      Url = window.API_PREFIX + 'user/profile';
      fetch(Url, {
          method: 'POST',
          headers: {
              'Content-type': 'application/json',
          },
          body: JSON.stringify({ "Token": localStorage.getItem("Token") }),
      })
      .then(resp => resp.json())
      .then(data => {
          if (data['status'] === "1") {
              const userDetail = data['UserDetail'];

              // Check if any required field is empty
              if (userDetail['Title']!="" & userDetail['DOb']!="" &
                  userDetail['FirstName']!="" & userDetail['LastName']!="" &
                  userDetail['Email']!="" & userDetail['Gender']!="" & userDetail['Phone_Num']!="") {
                  setisProfile(true)
                  // console.error("Some user details are missing or empty.Please complete profile first");
                  }}})



    }
  }, [ShowLoginPop])

  const addToWishListItem = async (productId, index) => {
    console.log(productId)
    var Url =
      window.API_PREFIX + "products/add_wishlist";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("Token"),
        ProductId: productId.ProductId,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (
          data["status"] === "2" ||
          data["status"] === "1"
        ) {


          dispatch({
            type: 'ADD_TO_WISHLIST',
            item: productId
          });

          var tmp = productList
          tmp[index]['isWishList'] = true
          setProductList([...tmp]);



          Url = window.API_PREFIX + 'order/update_cart'
          fetch(Url, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
            },
            body: JSON.stringify(
              { "Token": localStorage.getItem("Token"), "ProductId": productId.ProductId, "Quantity": 0 }),
          }).then(resp => resp.json()).then(data => {
            if (data['status'] === "1") {
              if (quantity === 0) {
                dispatch({
                  type: 'REMOVE_FROM_BASKET',
                  id: productId.ProductId
                });
              }
              getCheckOutList()
              if (CouponAmt !== 0) {
                setCouponAppliedFlag(false);
                setCouponDesc('');
                setCouponCode("")
                setCouponAmt(0);
                setPromocode_Id(data.Promocode_Id)
              }
            }
            else {

              // alert(data['message'])
              toast.error(data['message'])

            }
          }
          )


        }
      });




  }

  const handleRemoveItem = async (product, quantity) => {
    var Url = await window.API_PREFIX + 'order/update_cart'
    fetch(Url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(
        { "Token": localStorage.getItem("Token"), "ProductId": product.ProductId, "Quantity": quantity }),
    }).then(resp => resp.json()).then(data => {
      if (data['status'] === "1") {
        if (quantity === 0) {
          dispatch({
            type: 'REMOVE_FROM_BASKET',
            id: product.ProductId
          });
        }
        getCheckOutList()
        if (CouponAmt !== 0) {
          setCouponAppliedFlag(false);
          setCouponDesc('');
          setCouponCode("")
          setCouponAmt(0);
          setPromocode_Id(data.Promocode_Id)
        }
      }
      else {

        // alert(data['message'])
        toast.error(data['message'])

      }
    }
    )
  }

  const applyCouponCard = async () => {
    let ProductPrice = 0;
    if (ProductData["IsOffer"]) {
      ProductPrice = ProductData["SellingPrice"];
    } else {
      ProductPrice = ProductData["originalPrice"];
    }
    var Url = window.API_PREFIX + "order/CheckCoupon";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("Token"),
        CouponName: CouponCode,
        TotalAmount: productList.reduce(
          (total, product) =>
            total + product.SalePrice * product.Quantity,
          0
        ),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data["Status"] === "1") {
          setCouponAppliedFlag(true);
          setCouponDesc(data["Description"]);
          setCouponAmt(data.CoupenValue);
          setPromocode_Id(data.Promocode_Id)
        } else {
          // alert('This coupon is not available')
          toast.error("This coupon is not available")
        }
      });
  }


  const showToast = (msg) => {
    toast(msg, {
      // icon: '👏',
    });
  };


  const saveOrder = async () => {
    console.log(isProfile)

    if (!isProfile)
    {
      toast.error("Please complete profile first then proceed");

      return
    }
    
    const order = productList.map(product => ({
      Product_Id: product.ProductId.toString(),
      CustomerIp: '0.0.0.0',
      Quantity: product.Quantity,
      ShippingAddId: SAddressId,
      BillingAddId: BAddressId,
    }));


    var Url1 = window.API_PREFIX + 'order/SaveOrder'





    if (SAddressId !== "-1" && BAddressId !== "-1") {


      var JsonData = { 'Token': localStorage.getItem('Token'), IsDelivery: true, Order: order }


      if (CouponAppliedFlag) {
        JsonData['CouponApplied'] = 1
        JsonData['CouponId'] = Promocode_Id
        JsonData['DiscountAmount'] = CouponAmt
      }
      else {
        JsonData['CouponAppliedFlag'] = 0

      }

      fetch(Url1, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(JsonData),
      }).then(resp => resp.json()).then(data => {

        if (data['status'] === "1") {
          setorder_id(...data['order_id'])
          const options = {
            //    key: _DEV_ ? 'rzp_test_QoYTwM9rHNMFDE' : 'rzp_live_24bObjPtorMPgm',
            // key: 'rzp_test_VnVDwpNzsovqoU', // 'rzp_test_7qnNSp0IikaA98', 
            key: data['key'], // 'rzp_test_7qnNSp0IikaA98', 
            amount: Number(data['Price']) * 100,
            order_id: data['order_id'],
            name: data['Name'],

            description: 'Thank you for shopping',
            image: { ArihantLogo },
            handler: function (response) {


              navigate("/thankyou")
            },
            prefill: {
              name: "",
              email: UserEmail,
              phone_number: UserNumber
            }
          };
          const paymentObject = new window.Razorpay(options)
          paymentObject.open()
          dispatch({
            type: 'CLEAR_BASKET',
          })

        }
        else {
          alert("something went wrong")
        }
      })
    }
    else {
      // alert("Select Address")
      toast.error("Please select billing and shipping address")
    }



    console.log('order', order);
  }




  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 768, itemsToShow: 3 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6 },
  ];

  const [latestdata, setlatestdata] = useState([])


  const getCrossellList = () => {

    var url = window.API_PREFIX + "products/view_cross_sell";
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(
        {
          "Token": localStorage.getItem('Token'),
          "VariantId": 619

        }

      )
    }).then(resp => resp.json()).then(data => {

      console.log("data latest", data);

      if (data['status'] === "1") {
        setlatestdata([...data['products']])
      }
    })


  }


  useEffect(() => {

    getCrossellList()

  }, [])

  const handleAddCart = (eachProducts) => {


    if (!ShowLoginPop) {

      dispatch(
        { type: "Logout" }
      )
      dispatch(
        { type: "OpenLogin" }
      )
    }
    else {
      if (eachProducts.AllVarient[0].IsCart) {
        navigate(`/checkout`)
      } else {
        var Url = window.API_PREFIX + 'order/update_cart'
        fetch(Url, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(
            {
              "Token": localStorage.getItem("Token"),
              "ProductId": eachProducts.ProductId, "Quantity": 1
            }
          ),
        }).then(resp => resp.json()).then(data => {

          console.log("data", data);

          if (data['status'] === "1") {

            dispatch({
              type: 'ADD_TO_BASKET',
              item: eachProducts
            })
            getCrossellList()
            getCheckOutList()
          }
          else {
            // alert("quantity is not available")
            showToasttwo()
          }
        })
      }
    }
  }

  const showToasttwo = () => {
    toast((t) => (
      <span>
        Quantity is not available
        <button onClick={() => toast.dismiss(t.id)} className='mx-2'>
          Dismiss
        </button>
      </span>
    ));
  };


  const CustomArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === 'PREV' ? <i class="fa-solid fa-chevron-left fa-2x"></i> : <i class="fa-solid fa-chevron-right fa-2x"></i>;
    return (
        <div
        style={{display:'flex',alignItems:'center'}}
            onClick={onClick}
            disabled={isEdge}
            className="custom-arrow"
        >
            {pointer}
        </div>
    );
};



  return (
    <div className="container">
      <div className="checkout_main_division">
        <div className='mt-3 mb-3' style={{ borderBottom: '1px solid #e0e0e0' }}>
          <h4 style={{ fontSize: '1.56em', fontWeight: '600' }}>SHOPPING BAG</h4>
        </div>

        {productList.length === 0 &&
          <>
            <div className='text-center mb-3'>
              <img src={emptycart} className='emptycart_img'></img>
              <h3 >Your cart is empty.</h3>
              <h3 style={{ color: '#8863FB' }}>Let's fill it up with some amazing jewellery!</h3>
              <div
                className="btn emptycart_btn mt-2"
                onClick={() => {
                  navigate("/");
                }}
              >
                Continue Shopping
              </div>
            </div>


          </>
        }


        {/* khushbu checkout page start */}

        <div className="row">
          <div className="col col-12 col-md-8 mt-1 ">
            <div className="checkout_left_part ">
              <div className="left_second_div">
                {productList.length > 0 &&
                  productList.map((product, index) => (
                    <>
                      <div className="second_main_div">
                        <div className="button_close">
                          <button
                            type="button"
                            class="btn-close cross_button"
                            aria-label="Close"
                            onClick={() => handleRemoveItem(product, 0)}
                          ></button>
                        </div>
                        <div className="image_section">
                          <div key={index}>
                            <a href={`/product/` + product['ProductId']} style={{}}>
                              <img
                                src={product.ProductImg}
                                className="necklace_img justify-content-center"
                              ></img>
                            </a>
                          </div>
                          <div className="description_section">
                            <div className="top-container">
                              <div class="product_name_checkout">
                                {product.ProductName}
                              </div>
                              <div class="item_price">
                                ₹{product.IsOffer ? product.SalePrice : product.BuyPrice}
                              </div>

                              <div className='delete_move_to_whislist'>
                                <div className='delete_icon d-flex me-2' onClick={() => handleRemoveItem(product, 0)}>
                                  <div><i class="fa-regular fa-trash-can me-2 "></i></div>
                                  <div className='me-2'>Delete</div>
                                </div>
                                <div className='move_to_whislist d-flex ' onClick={() => addToWishListItem(product, index)}>
                                  <div><i class="fa-regular fa-heart me-2 "></i></div>
                                  <div>Move to whislist</div>
                                </div>
                              </div>
                              <div class="quantity_option d-flex align-items-center justify-content-around">
                                <div className='quantity_2'>

                                  <div className='pl_mi_div' onClick={() => {
                                    if (product.Quantity < 1) {
                                      handleRemoveItem(product, 0)
                                      getCrossellList()

                                    }
                                    else {
                                      handleRemoveItem(product, +product.Quantity - 1)
                                      getCrossellList()

                                    }
                                  }}>
                                    <i class="fa-solid fa-minus "></i>
                                  </div>
                                  <div className='m-2' style={{ fontSize: '20px' }}>{product.Quantity}</div>
                                  <div className='pl_mi_div' onClick={(() => {
                                    handleRemoveItem(product, +product.Quantity + 1)
                                    getCrossellList()

                                  })}>
                                    <i class="fa-solid fa-plus"></i>
                                  </div>

                                </div>
                                {product.IsOffer ? (
                                  <span class="save_price">
                                    you Save
                                    <div className='you-save'>₹{product.BuyPrice - product.SalePrice}</div>
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
              {productList.length > 0 &&
                <div className='proceed_to_buy'>
                  <div className='total_price_1'>
                    Total (item):
                    <span class="price_value ms-2">
                      ₹
                      {productList.reduce(
                        (total, product) =>
                          total + product.SalePrice * product.Quantity,
                        0
                      ) - CouponAmt}
                    </span>
                  </div>
                  <div className="place_order">
                    <div className="button_order" onClick={saveOrder}>
                      PROCEED TO BUY
                    </div>
                  </div>
                </div>
              }
            </div>

          </div>

          {productList.length > 0 &&
            <div className='container mt-1 col col-12 col-md-4'>
              <div className="cart_page_product_right_side_apply_code  ">
                <div className="main_apply_div_cart_page">
                  <div className="input_with_button_cart_page">
                    <input
                      type="text"
                      class="form-control  input_cart_page me-3"
                      id="exampleFormControlInput1"
                      value={CouponCode}
                      onChange={e => setCouponCode(e.target.value)}
                    ></input>
                    <button
                      onClick={applyCouponCard}
                      type="button"
                      class="btn apply_cart_button_submit_cart_page"
                    >
                      APPLY
                    </button>
                  </div>
                </div>

                <div className="order_at_cart_page">Order Summary</div>

                <div className="oder_summary_div">
                  <div className="order_summary_detail">
                    <div className="sub_total">
                      Subtotal
                      <span className="price_value">
                        ₹
                        {productList.reduce(
                          (total, product) =>
                            total + product.SalePrice * product.Quantity,
                          0
                        )}
                      </span>
                    </div>
                    <div className="coupon_discount">
                      Discount
                      <span class="price_value">
                        <a>{CouponAmt}</a>
                      </span>
                    </div>
                    {CouponDesc !== "" && <div className="shiping_standard">
                      Coupon Description
                      <span class="price_value">
                        <span style={{ color: "#8863fb" }}>{CouponDesc}</span>
                      </span>
                    </div>}
                    <div className="shiping_standard">
                      Delivery charges
                      <span class="price_value">
                        <span>Free</span>
                      </span>
                    </div>

                    <div className="total_price">
                      TOTAL(Incl. of all Taxes.)
                      <span class="price_value">
                        ₹
                        {productList.reduce(
                          (total, product) =>
                            total + product.SalePrice * product.Quantity,
                          0
                        ) - CouponAmt}
                      </span>
                    </div>
                    {/* <div className="you_price">
                  YOU SAVE
                  <span class="you_value">
                    ₹1499
                  </span>
                </div> */}
                  </div>
                </div>
              </div>
            </div>}
        </div>
        {/* khushbu checkout page end */}
      </div>


      {true && productList.length > 0 ? (
        <>
          {!isProfile?(<>
            <div className="address_breadcrums_add_new_button">
              <div
                className="address_breadcrums_plus_icon"
                onClick={() => navigate("/userprofile")}
              >
                Complete your profile
              </div>
            </div>
          </>)
          
          :AddressData.length !== 0 ? (
            <>
              <div className=" mt-3 row">
                <div className="checkout_delivery_address_main_division col col-12 col-sm-6 col-md-6 col-lg-6  ">
                  <div>
                    <div className="checkout_billing_address_title">
                      Billing Address :
                    </div>

                    {
                      ChangeAddress ? null : <div className="checkout_address_divison ">
                        <div className="checkout_address_title_division">
                          <p className="checkout_default_address me-3">
                            Default Address
                            <button
                              className="btn"
                              onClick={() => {
                                setChangeAddress(true);
                              }} disabled={ChangeAddress}

                            >
                              <i class="fa-regular fa-pen-to-square"></i>
                            </button>
                          </p>
                          <div className="checkout_delivery_time">
                            {currentBName}
                          </div>
                          <div className="checkout_delivery_time1">
                            {currentBNumber}
                          </div>

                          <div className="checkout_full_default_address">
                            {currentBillingAddress}
                          </div>
                        </div>
                        <div>


                        </div>
                      </div>
                    }

                    {/* </div> */}
                    {ChangeAddress ? (
                      <div className="checkout_select_address">
                        {AddressData.map((eachAddress, AddressIndex) => {
                          return (
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                value="option3"
                                onClick={() => {
                                  setBAddressId(eachAddress["Id"]);
                                  setcurrentBillingAddress(
                                    eachAddress["Flat_No"] +
                                    "," +
                                    eachAddress["StreetFlat"] +
                                    "," +
                                    eachAddress["Landmark"] +
                                    "," +
                                    eachAddress["City_Town"] +
                                    "," +
                                    eachAddress["State"] +
                                    "," +
                                    eachAddress["Pincode"]
                                  );
                                  setcurrentBName(eachAddress["Name"]);
                                  setcurrentBNumber(eachAddress["Number"]);
                                  setChangeAddress(false);

                                  if (BillingShippingSame === true) {
                                    setSAddressId(eachAddress["Id"]);
                                    setcurrentShippingAddress(
                                      eachAddress["Flat_No"] +
                                      "," +
                                      eachAddress["StreetFlat"] +
                                      "," +
                                      eachAddress["Landmark"] +
                                      "," +
                                      eachAddress["City_Town"] +
                                      "," +
                                      eachAddress["State"] +
                                      "," +
                                      eachAddress["Pincode"]
                                    );
                                    setcurrentSName(eachAddress["Name"]);
                                    setcurrentSNumber(eachAddress["Number"]);
                                  }
                                }}
                              ></input>
                              <div className="checkout_delivery_time2">
                                {eachAddress["Name"]}
                              </div>

                              <div className="checkout_delivery_time1">
                                {eachAddress["Number"]}
                              </div>

                              <div className="checkout_full_address">
                                {eachAddress["Flat_No"]},
                                {eachAddress["StreetFlat"]},
                                {eachAddress["Landmark"]},
                                {eachAddress["City_Town"]},
                                {eachAddress["State"]},
                                {eachAddress["Pincode"]}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>

                {!BillingShippingSame ? (
                  <div className="checkout_shipping_address_main_division col col-12 col-sm-6 col-md-6 col-lg-6 ">
                    <div>
                      <div className="checkout_billing_address_title">
                        Shipping Address :
                      </div>


                      {
                        ChangeAddresstwo ?

                          null :
                          <div className='checkout_address_divison '>
                            <div>
                              <p className="checkout_default_address me-3">
                                Default Address
                                <button
                                  className="btn checkout_change_address_btn"
                                  onClick={() => {
                                    setChangeAddresstwo(true);
                                  }} disabled={ChangeAddresstwo}
                                >
                                  <i class="fa-regular fa-pen-to-square"></i>
                                </button>
                              </p>
                              <div className="checkout_delivery_time">
                                {currentSName}
                              </div>
                              <div className="checkout_delivery_time1">
                                {currentSNumber}
                              </div>
                              <div className="checkout_full_default_address">
                                {currentShippingAddress}
                              </div>
                            </div>
                            <div>

                            </div>
                          </div>
                      }


                      {/* </div> */}
                      {ChangeAddresstwo ? (
                        <div className="checkout_select_address">
                          {AddressData.map((eachAddress, AddressIndex) => {
                            return (
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions1"
                                  value="option3"
                                  onClick={() => {
                                    setSAddressId(eachAddress["Id"]);
                                    setcurrentShippingAddress(
                                      eachAddress["Flat_No"] +
                                      "," +
                                      eachAddress["StreetFlat"] +
                                      "," +
                                      eachAddress["Landmark"] +
                                      "," +
                                      eachAddress["City_Town"] +
                                      "," +
                                      eachAddress["State"] +
                                      "," +
                                      eachAddress["Pincode"]
                                    );
                                    setcurrentSName(eachAddress["Name"]);
                                    setcurrentSNumber(eachAddress["Number"]);
                                    setChangeAddresstwo(false)
                                  }}
                                ></input>
                                <div className="checkout_delivery_time2">
                                  {eachAddress["Name"]}
                                </div>

                                <div className="checkout_delivery_time1">
                                  {eachAddress["Number"]}
                                </div>

                                <div className="checkout_full_address">
                                  {eachAddress["Flat_No"]},
                                  {eachAddress["StreetFlat"]},
                                  {eachAddress["Landmark"]},
                                  {eachAddress["City_Town"]},
                                  {eachAddress["State"]},
                                  {eachAddress["Pincode"]}
                                </div>
                              </div>
                            );
                          })}
                        </div>) : null}
                    </div>
                  </div>
                ) : null}
              </div>
              <div class="checkout-form-check form-check-inline mt-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="inlineCheckOptions"
                  value="option3"
                  checked={BillingShippingSame}
                  onChange={(e) => {
                    setBillingShippingSame(!BillingShippingSame);
                    if (BillingShippingSame === false) {
                      setSAddressId(BAddressId);
                      setcurrentShippingAddress(currentBillingAddress);
                      setcurrentSName(currentBName);
                      setcurrentSNumber(currentBNumber);
                    }
                  }}
                ></input>
                <label class="checkout-form-check-label">
                  Same as a billing address
                </label>
              </div>
            </>
          ) : (
            <div className="address_breadcrums_add_new_button">
              <div
                className="address_breadcrums_plus_icon"
                onClick={() => navigate("/address")}
              >
                <i class="fa-solid fa-plus"></i> ADD NEW ADDRESS
              </div>
            </div>
          )}
        </>
      ) : null}


      {productList.length > 0 && (<div>
        <Carousel1 breakPoints={breakPoints} pagination={false} renderArrow={CustomArrow}>
          {latestdata.map((eachProduct, ProductIndex) => {
            if (eachProduct['IsOffer'] === true) {
              var offer = Math.floor(((Number(eachProduct.BuyPrice) - Number(eachProduct.SalePrice)) / Number(eachProduct.BuyPrice)) * 100)
            }
            return (

              <div className='homepage_card_main' >
                <div className='homepage_card_top' onClick={() => {
                  navigate(`/product/${eachProduct.ProductId}`)
                }}>
                  <img src={eachProduct.ProductImg} className='image_size_2' alt="Product Image"></img>
                  {eachProduct['IsOffer'] ? <div className='left'>{offer}%OFF</div> : null}

                  <div className='ratings_part'>
                    {/* <span>4.6 <i className="fa-solid fa-star" style={{ color: 'pink' }}></i> 78 </span> */}
                  </div>
                </div>
                <div className='homepage_price_of_product'>
                  <div className='homepage_price_both_discounted_and_regular'>
                    <div className='discounted_price'>
                      {eachProduct['IsOffer'] ? <span >₹{eachProduct['SalePrice']}</span> : <span>₹{eachProduct['BuyPrice']}</span>}
                    </div>
                    <div className='regular_price'>
                      {eachProduct['IsOffer'] ? <span>₹{eachProduct['BuyPrice']}</span> : null}
                    </div>
                  </div>
                  <div className='homepage_name_reference_of_product'>
                    <span>{eachProduct['Name']}</span>
                  </div>
                  <div className='homepage_place_order_to_cart'>
                    <button type='button' onClick={() => handleAddCart(eachProduct)}>
                      {eachProduct.AllVarient[0].IsCart ? "Go to Cart" : "Add to Cart"}</button>
                  </div>
                </div>
              </div>
            )

          })}




        </Carousel1>
      </div>)}



      <Toaster />
    </div>
  );
}

export default Checkout;