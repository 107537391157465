import React from 'react';
import { useNavigate } from 'react-router-dom';

function Shippingpolicy() {

  let navigate = useNavigate();

  return (
    <div className='container pb-3' style={{ fontSize: '18px',textAlign:'justify' }}>
      <div class="shopify-policy__container">

  
        {/* <i class="fa-solid fa-arrow-left fa-2xl" onClick={() => {
          navigate('/')
        }}></i> */}
        <div class="shopify-policy__title mt-3">
          <h1>Shipping policy</h1>
        </div>

        <div class="shopify-policy__body">
          <div class="rte">

            <p><strong>Free Shipping</strong><span>&nbsp;</span>is included in all orders without any minimums.</p>
            <p>All COD (Cash on Delivery) orders will go through a verification process. Only verified address orders will be shipped.</p>
            <p><strong>Order Verification Time:</strong><span>&nbsp;</span>1 day</p>
            <p><strong>Processing Time:</strong><span>&nbsp;</span>1 day</p>
            <p><strong>Shipping Transit Time:</strong>&nbsp;5-7 days</p>
            <p>We ship&nbsp;via surface shipping partners and generally speaking, you should get your order within a week after order verification.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Shippingpolicy