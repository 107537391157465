import { elementAcceptingRef } from '@mui/utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AddressBreadcrums.css';

function AddressBreadcrums() {

    let navigate = useNavigate()
    const [AddressData, setAddressData] = useState([])




    useEffect(() => {
        var Url = window.API_PREFIX + 'user/address'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                { "Token": localStorage.getItem("Token") }),
        }).then(resp => resp.json()).then(data => {

            if (data['status'] == "1") {
                setAddressData([...data["AddressData"]])


            }
        }
        )
    }, [])

    return (
        <div className='container mt-2'>
            <div className='address_breadcrums_page'>
                <div className='address_breadcrums_path'>My Account</div>
                <div className='address_breadcrums_path_icon'><i class="fa-solid fa-chevron-right"></i></div>
                <div className='address_breadcrums_path_details'>My Addresses</div>
            </div>

            <div className='address_breadcrums_extra_division'>
                <div className='address_breadcrums_page_form'>
                    <div className='address_breadcrums_page_title'>My Addresses</div>

                </div>

            </div>
            <div className='address_breadcrums_add_new_button mb-2'>
                <div className='address_breadcrums_plus_icon'
                    onClick={() => navigate("/address")}> <i class="fa-solid fa-plus me-2 "></i> ADD NEW </div>
            </div>

            <div className='container'>
                <div className='row' style={{ justifyContent: 'space-between' }}>
                    {AddressData.map((eachAddress, AddressIndex) => {

                        return (
                            <div className=' addressbox_main_div col col-12 col-md-5 '>
                                <div className='address_breadcrums_save_address_main_division  '>

                                    {eachAddress["IsDefault"] ? <div className='address_breadcrums_default_address'>
                                        <p className='d-flex justify-content-start address_breadcrums_default'>DEFAULT ADDRESS</p>
                                    </div> : null}

                                    {/* <div className='address_breadcrums_nick_address'>{eachAddress["AddressNickName"]}</div> */}
                                    <div className='address_breadcrums_user_details'>
                                        <div className='address_breadcrums_name'>{eachAddress["Name"]}</div>
                                        <div className='address_breadcrums_mobile_number'>+91 {eachAddress["Number"]}</div>
                                    </div>
                                    <div className='address_breadcrums_full_address'>{eachAddress["Flat_No"]}  {eachAddress["StreetFlat"]} <br></br>{eachAddress["Landmark"]} {eachAddress["City_Town"]}<br></br> {eachAddress["State"]}-{eachAddress["Pincode"]}</div>
                                    <div class="address_breadcrums-form-check form-check-inline mt-3">
                                        <input class="form-check-input" type="radio" name="inlineCheckOptions" value="option3"
                                            onClick={(e) => {
                                                var Url = window.API_PREFIX + 'user/change_defaultaddress'
                                                fetch(Url, {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-type': 'application/json',
                                                    },
                                                    body: JSON.stringify({ "Token": localStorage.getItem("Token"), 'Id': eachAddress['Id'] }),
                                                }).then(resp => resp.json()).then(data => {

                                                    if (data['status'] == "1") {
                                                        var tmp_addressData = AddressData
                                                        for (var i = 0; i < tmp_addressData.length; i++) { tmp_addressData[i]["IsDefault"] = false }
                                                        tmp_addressData[AddressIndex]["IsDefault"] = true
                                                        setAddressData([...tmp_addressData])




                                                    }
                                                }
                                                )
                                            }}></input>
                                        <label class="address-breadcrums-form-check-label">Set as default </label>
                                        <div className='address_breadcrums_address_type'>Address Type:</div>
                                        <div className='address_breadcrums_type_home'>{eachAddress["Type"]}</div>
                                    </div>


                                </div>
                                <div className='address_breadcrums_button '>

                                    <div>
                                        <button type="button" class="address_breadcrums_update" onClick={() => {
                                            navigate("/address", { state: eachAddress })

                                        }}>EDIT</button>
                                    </div>
                                    <div>  <button type="button" class="address_breadcrums_delete"
                                        onClick={() => {
                                            var Url = window.API_PREFIX + 'user/delete_address'
                                            fetch(Url, {
                                                method: 'POST',
                                                headers: {
                                                    'Content-type': 'application/json',
                                                },
                                                body: JSON.stringify({
                                                    "Token": localStorage.getItem("Token"),
                                                    "Id": eachAddress["Id"]
                                                }),
                                            }).then(resp => resp.json()).then(data => {

                                                if (data['status'] == "1") {
                                                    var tmp_addressData = AddressData
                                                    tmp_addressData.splice(AddressIndex, 1)
                                                    setAddressData([...tmp_addressData])

                                                }
                                            }
                                            )
                                        }}
                                    >DELETE</button></div>

                                </div>
                            </div>
                        )
                    })}
                </div>




            </div>

        </div>
    )
}

export default AddressBreadcrums;