import './ComparePage.css';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Comaparepageimg from '../../UserMedia/iphonepro.jpeg';
import { useStateValue } from '../../../StateProviders';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


function Comparepage() {
	let navigate = useNavigate()

	const [compareProdataData, setcompareProdataData] = useState([])
	const [AttributeData, setAttributeData] = useState([])
	const [ReviewData, setReviewData] = useState([])
	useEffect(() => {
		var Url = window.API_PREFIX + 'products/viewcomparedetail'
		fetch(Url, {
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
			},
			body: JSON.stringify(
				{
					Token: localStorage.getItem('Token'),

				}
			),
		}).then(resp => resp.json()).then(data => {
			console.log(data)
			if (data['status'] === "1") {
				setAttributeData([...data['data']])
				setReviewData([...data['review']])
			}
		})



		var Url = window.API_PREFIX + 'products/viewcompare'
		fetch(Url, {
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
			},
			body: JSON.stringify({
				Token: localStorage.getItem('Token')
			}
			),
		}).then(resp => resp.json()).then(data => {
			console.log(data)

			if (data['status'] === "1") {
				setcompareProdataData([...data['products']])
			}
		})

	}, [])
	return (
		<div className='container'>
			<div className='comparepage_main_division'>
				<div className="comparepage_App">


					{compareProdataData?.map((eachcompareproduct) => {
						return (
							<div class="comparepage_product_card">
								<div class="comparepage_card">

									{/* <div className='comparepage_card_wishlist'>
								<i class="comparepage_close_icon fa-solid fa-xmark"></i>
							</div> */}

									<div class="comparepage_imgBx">
										<img src={eachcompareproduct['ProductImg']}></img>
									</div>

									<div class="comparepage_contentBx">
										<div class="d-block comparepage_card_productname ">{eachcompareproduct['Product_Name']}
											{/* <span class="comparepage_storage">(16 GB, 256 GB)</span> */}
										</div>
										<div className='comparepage_product_id'>Product Id: {eachcompareproduct['ProductId']}</div>
										{eachcompareproduct['isOffer'] ? <div class="size">
											<div class="comparepage_offer_main_price">₹ {eachcompareproduct['DiscountPrice']}</div>
											<div class="comparepage_card_origanl_price"><del>{eachcompareproduct['OriginalPrice']}</del></div>
										</div> :
											<div class="size">
												<div class="comparepage_offer_main_price">₹ {eachcompareproduct['OriginalPrice']}</div>
											</div>}

										<div className='comparepage_offer_division'>
											<div className='comparepage_offer_tag'>{(((eachcompareproduct['OriginalPrice'] - eachcompareproduct['DiscountPrice']) / eachcompareproduct['OriginalPrice']) * 100).toFixed(0)}% OFF</div>
										</div>

										<div class="comparepage_review_division">
											<Stack spacing={1}>
												<Rating
													name="half-rating-read"
													defaultValue={eachcompareproduct['Rating']}
													precision={0.5}
													readOnly
												/>
											</Stack>
										</div>
									</div>

								</div>
							</div>
						)
					})}





				</div>

				<div className='comparepage_accordian_main_division'>
					<div className='comparepage_product_details_title'>Product Details</div>

					{/* product_specifications_division_start */}
					<div className='comparepage_specifications_division'>
						<Accordion className='comparepage_specifications'>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon className='arihantProductPageExpandIcon' />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<div className='comparepage_specifications_feild'>key features</div>
							</AccordionSummary>

							{AttributeData.map((eachAttribute) => {
								return (
									<AccordionDetails>
										<div className='comparepage_specifications_detail'>{eachAttribute['Heading']}</div>

										{eachAttribute['Attributedata'].map((eachAttributeKey) => {
											return (
												<div className='comparepage_sub_main_division'>
													<div className='comparepage_details_title'>{eachAttributeKey['AttributeKey']}</div>

													{eachAttributeKey['AttributeValue'].map((eachAttributeValue, AttributeValueIndex) => {

														return (
															AttributeValueIndex === eachAttributeKey['AttributeValue'].length - 1 ? <div className='comparepage_details_withoutborder'>{eachAttributeValue}</div> : <div className='comparepage_details'>{eachAttributeValue}</div>

														)
													})}

												</div>
											)
										})}





									</AccordionDetails>
								)
							})}

						</Accordion>
					</div>
					{/* product_specifications_division_end */}


					{/* overview_detail_division_start */}
					{/* <div className='comparepage_overview_division'>
						<Accordion className='comparepage_product_overview'>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon className='arihantProductPageExpandIcon' />}
								aria-controls="panel2a-content"
								id="panel2a-header"
							>
								<div className='comparepage_overview_feild'>mobile category</div>
							</AccordionSummary>
							<AccordionDetails>
								<div className='comparepage_specifications_detail'>MOBILE TYPES</div>

								<div className='comparepage_sub_main_division'>
									<div className='comparepage_first_sub_division'>
										<div className='comparepage_details'>iOS Smartphone</div>
									</div>

									<div className='comparepage_second_sub_division'>
										<div className='comparepage_details'>iOS Smartphone</div>
									</div>
								</div>

								<div className='comparepage_specifications_detail'>MOBILE FORM FACTOR</div>

								<div className='comparepage_sub_main_division'>
									<div className='comparepage_first_sub_division'>
										<div className='comparepage_details'>iOS Smartphone</div>
									</div>

									<div className='comparepage_second_sub_division'>
										<div className='comparepage_details'>iOS Smartphone</div>
									</div>
								</div>
							</AccordionDetails>
						</Accordion>
					</div> */}
					{/* overview_detail_division_end */}


					{/* review_detail_division_start */}
					<div className='comparepage_accordian_review_division'>
						<Accordion className='comparepage_product_review'>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon className='arihantProductPageExpandIcon' />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<div className='comparepage_review_feild'>all reviews rating</div>
							</AccordionSummary>
							<AccordionDetails>

								<div className='comparepage_specifications_detail'>CUTOMER RATINGS</div>

								<div className='comparepage_main_main_review_page_division'>

									{ReviewData?.map((eachProreview) => {
										return (<div className='comparepage_sub_main_review_division'>
											{eachProreview.length === 0 ? <div className='comarepage_review_user_name'>No Reviews</div> : <>
												{eachProreview?.map((eachReview) => {
													return (

														<div className='comparepage_first_main_division'>
															<div className='comarepage_review_user_name'>{eachReview['UserName']}</div>
															<div className='comparepage_rating_reviews_division'>
																<div class="comparepage_review_division">
																	<Stack spacing={1}>
																		<Rating
																			name="half-rating-read"
																			defaultValue={eachReview['Star']}
																			precision={0.5}
																			readOnly
																		/>
																	</Stack>
																</div>
																<div className='comparepage_customer_rating'></div>
															</div>
															<div className='comarepage_review_user_name'>{eachReview['Description']}</div>
														</div>




													)
												})
												}
											</>}
										</div>)

									})}






								</div>



							</AccordionDetails>
						</Accordion>
					</div>
					{/* review_detail_division_end */}
				</div>
			</div>
		</div>
	)
}

export default Comparepage;